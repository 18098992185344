import {
  SearchButton,
  SearchButtonText,
} from "../shared/styled-css/common-css";

export interface CommonButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  label: string;
  icon?: React.ReactNode;
}

export default function CommonButton({
  onClick,
  label,
  icon,
}: CommonButtonProps) {
  return (
    <SearchButton onClick={onClick}>
      {icon}
      <SearchButtonText>{label}</SearchButtonText>
    </SearchButton>
  );
}
