import { Box, Button, Card, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import background from "../../assets/image/background.webp";
import logo from "../../assets/image/logo.png";
import { popupCustomAlert } from "../../components/common/CustomToast";
import { useAutologin, useLoginUser } from "../../service/login/loginHooks";

const MODE = process.env.REACT_APP_MODE;

const Login = () => {
  const theme = useTheme();

  const autoLogin = useAutologin();

  useEffect(() => {
    autoLogin();
  }, []);

  const [loginInfo, serLoginInfo] = useState({
    userId: "",
    userPassword: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    serLoginInfo({
      ...loginInfo,
      [id]: value,
    });
  };

  const { mutate: loginUser, isPending } = useLoginUser();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loginInfo.userId || !loginInfo.userPassword) {
      popupCustomAlert("warn", "계정 정보를 모두 입력해주세요");
    } else {
      loginUser(loginInfo);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <Stack
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"100vh"}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // 검은색 투명도 조절 가능
          backdropFilter: "blur(8px)", // 흐림 효과
          zIndex: 100, // 배경 이미지 위에 요소를 쌓기 위해 필요
        }}
      >
        <Card sx={{ padding: "60px 20px" }}>
          <Box component="form" onSubmit={handleSubmit} style={{ width: 300 }}>
            <Stack gap={2}>
              <Stack alignItems={"center"} justifyContent={"center"} sx={{ mb: "40px" }} gap={3}>
                <Box component="img" src={logo} alt={"logo"} height={30} />
                <Typography fontSize={22} color="text.primary" textAlign={"center"}>
                  LOGIN
                </Typography>
              </Stack>
              {/* <Typography variant="h3" sx={{ textAlign: "center", mb: 10 }}>
              LOGIN
            </Typography> */}
              <TextField
                id="userId"
                label={"아이디"}
                value={loginInfo.userId}
                onChange={handleInputChange}
                placeholder="아이디를 입력해주세요"
                size="medium"
              />
              <TextField
                id="userPassword"
                label={"비밀번호"}
                type="password"
                value={loginInfo.userPassword}
                onChange={handleInputChange}
                placeholder="비밀번호를 입력해주세요"
                size="medium"
              />
              <Button
                variant="contained"
                type="submit"
                size={"large"}
                sx={{ mt: 10, height: "50px" }}
                disabled={isPending}
              >
                <Typography letterSpacing={2} fontWeight={"bold"}>
                  로그인
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Card>
      </Stack>
    </Box>
  );
};

export default Login;
