import { useMutation, useQuery } from "@tanstack/react-query";
import { MenuAPI } from "./menuAxios";
import {
  createMenuRequestDto,
  createMenuResponseDto,
  deleteMenuRequestDto,
  deleteMenuResponseDto,
  getMenuRequestDto,
  getMenuResponseDto,
  getMenuToRoleRequestDto,
  getMenuToRoleResponseDto,
  getMyMenuResponseDto,
  updateMenuRequestDto,
  updateMenuResponseDto,
  updateMenuToRoleRequestDto,
  updateMenuToRoleResponseDto,
} from "./types";
import { HttpErrorResponseType, isAxiosError } from "../../types/AxiosType";
import { QUERY_KEY } from "../../constants/constant";

export const useGetMenu = (data: getMenuRequestDto | undefined) => {
  return useQuery<getMenuResponseDto[], Error, getMenuResponseDto[]>({
    queryKey: [QUERY_KEY.menu],
    queryFn: () => MenuAPI.getMenu(data),
  });
};

export const useGetMenuToRole = (
  onSubmit: (data: getMenuToRoleResponseDto[]) => void
) => {
  return useMutation<
    getMenuToRoleResponseDto[],
    Error,
    getMenuToRoleRequestDto
  >({
    mutationFn: (data: getMenuToRoleRequestDto) => MenuAPI.getMenuToRole(data),
    onSuccess: (data) => {
      onSubmit(data);
    },
  });
};

export const useCreateMenu = (
  onSubmit: () => void,
  onError: (message: string) => void
) => {
  return useMutation<createMenuResponseDto, Error, createMenuRequestDto>({
    mutationFn: (data: createMenuRequestDto) => MenuAPI.createMenu(data),
    onSuccess: (data) => {
      onSubmit();
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        onError(error.response?.data.error.message || "");
      } else {
        onError("알 수 없는 오류");
      }
    },
  });
};

export const useUpdateMenu = (
  onSubmit: () => void,
  onError: (message: string) => void
) => {
  return useMutation<updateMenuResponseDto, Error, updateMenuRequestDto>({
    mutationFn: (data: updateMenuRequestDto) => MenuAPI.updateMenu(data),
    onSuccess: (data) => {
      onSubmit();
    },
  });
};

export const useUpdateMenuToRole = (
  onSubmit: (data: updateMenuResponseDto) => void
) => {
  return useMutation<
    updateMenuToRoleResponseDto,
    Error,
    updateMenuToRoleRequestDto
  >({
    mutationFn: (data: updateMenuToRoleRequestDto) =>
      MenuAPI.updateMenuToRole(data),
    onSuccess: (data) => {
      onSubmit(data);
    },
  });
};

export const useDeleteMenu = (onSucess: () => void) => {
  return useMutation<deleteMenuResponseDto, Error, deleteMenuRequestDto>({
    mutationFn: (data: deleteMenuRequestDto) => MenuAPI.deleteMenu(data),
    onSuccess: (data) => {
      onSucess();
    },
  });
};

export const useGetMyMenu = () => {
  return useQuery<getMyMenuResponseDto[], Error, Set<string>>({
    queryKey: [QUERY_KEY.menu, "user"],
    queryFn: () => MenuAPI.getMyMenu(),
    select: (data) => {
      const set = new Set<string>();
      data.forEach((item) => {
        set.add(item.MENU_NM);
      });
      return set;
    },
  });
};
