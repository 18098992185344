import { customAxios } from "../../config";
import { camelToSnake } from "../lib/camelToSnake";
import {
  createUserDto,
  deleteUserRequestDto,
  getUserInfoRequestDto,
  getUserInfoResponseDto,
  getUserListRequestDto,
  getUserListResponseDto,
  getUserRequestDto,
  getUserResponseDto,
  updateUserInfoDto,
  updateUserPasswordDto,
} from "./types";

export const UserAPI = {
  getRole: async (data: getUserRequestDto | undefined): Promise<getUserResponseDto[]> => {
    return await customAxios.get(`/user?condition=${data}`).then((res) => res.data);
  },
  getUserInfo: async (data: getUserInfoRequestDto): Promise<getUserInfoResponseDto> => {
    const params = camelToSnake(data);
    return await customAxios.get("/user/spec", { params }).then((res) => {
      return res.data;
    });
  },
  updateUserInfo: async (data: updateUserInfoDto): Promise<updateUserInfoDto> => {
    const body = camelToSnake(data);
    return await customAxios.put("/user", body).then(() => data);
  },
  createUser: async (data: createUserDto): Promise<createUserDto> => {
    const body = camelToSnake(data);
    return await customAxios.post("/user", body).then(() => data);
  },
  updateUserPassword: async (data: updateUserPasswordDto) => {
    const body = camelToSnake(data);
    return await customAxios.put("/user/password", body).then(() => data);
  },

  getUserList: async (data: getUserListRequestDto): Promise<getUserListResponseDto[]> => {
    const params = camelToSnake(data);
    return await customAxios.get("/user", { params }).then((res) => res.data);
  },

  deleteUser: async (data:deleteUserRequestDto) =>{
    return await customAxios.delete(`/user`,{data}).then(() => data);
  }
};

