export function generateDates(yearMonth: string) {
  // 연도와 월을 분리합니다.
  const year = parseInt(yearMonth.slice(0, 4));
  const month = parseInt(yearMonth.slice(4, 6));

  // 해당 월의 마지막 날짜를 구합니다.
  const lastDay = new Date(year, month, 0).getDate();

  // 해당 월의 모든 날짜를 생성합니다.
  const dates = [];
  for (let day = 1; day <= lastDay; day++) {
    const formattedDay = day.toString().padStart(2, "0");
    dates.push(`${yearMonth}${formattedDay}`);
  }
  return dates;
}

export function generateMonths(year: string) {
  const months = [];
  for (let month = 1; month <= 12; month++) {
    const formattedMonth = month.toString().padStart(2, "0");
    months.push(`${year}${formattedMonth}`);
  }
  return months;
}