export function snakeToCamel(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamel(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result: any, key: string) => {
      let camelKey = key
        .toLowerCase()
        .replace(/([-_]\w)/g, (matches) => matches[1].toUpperCase());

      let value = obj[key];

      if (!value && typeof value === "object") {
        value = snakeToCamel(value);
      } else if (
        [
          "createdAt",
          "updatedAt",
          "deletedAt",
          "lastLoginAt",
          "adStartDate",
          "date",
        ].includes(camelKey)
      ) {
        result[camelKey] = new Date(value);
      } else {
        if (
          [
            "userId",
            "RoleId",
            "customerId",
            "searchAdsId",
            "bannerAdsId",
            "trialOptinId",
            "adContentId",
            "ExpenditureId",
          ].includes(camelKey)
        ) {
          result["id"] = value;
        }

        result[camelKey] = value;
      }
      return result;
    }, {});
  }
  return obj;
}
