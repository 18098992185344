import ClearIcon from "@mui/icons-material/Clear";
import {
  Autocomplete,
  Box,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import {
  PopupCloseButton,
  TableCol1,
  TableCol2,
  TableContainer,
  TableMultiInfoCol1,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { CallType } from "../../../types/CallType";
import { errorHandler } from "../../../utils/apiUtil";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { popupCustomAlert } from "../../../components/common/CustomToast";

interface Props {
  open: CallType;
  onSubmit: () => void;
  onCancel: () => void;
}

type BusinessUserType = {
  USER_ID: string;
  USER_NAME: string;
};

export default function SalesManChangePopup({
  open,
  onSubmit,
  onCancel,
}: Props) {
  const [title] = useState("영업 담당자 변경");
  const [origin, setOrigin] = useState<BusinessUserType>();
  const [target, setTarget] = useState<BusinessUserType[]>([]);
  const [selectedUser, setSelectedUser] = useState<BusinessUserType | null>(
    null
  ); // 선택된 영업사원

  /**
   * API 호출
   */
  const getBusinessUser = async () => {
    try {
      const response = await customAxios.get("/user/dept", {
        params: {
          DEPT_ID: "C007B", // 영업팀
        },
      });

      setTarget(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (open) {
      setOrigin({
        USER_ID: open.SALESPERSON_ID,
        USER_NAME: open.SALESPERSON_NM,
      });

      getBusinessUser();
    }
  }, [open]);

  /**
   * 컴포넌트 제어
   */

  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (selectedUser) {
      try {
        await customAxios.put("/call/change/salesman", {
          CALL_ID: open.CALL_ID,
          SALESPERSON_ID: selectedUser.USER_ID,
        });

        onSubmit();
      } catch (e: unknown) {
        errorHandler(e);
      }
    } else {
      popupCustomAlert("info", "변경 담당자를 선택해주세요")
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={1}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Box alignContent={"center"}>
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
          </Box>
          <Box>
            <PopupCloseButton onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#BDBDBD" }} />
            </PopupCloseButton>
          </Box>
        </Stack>
        <TableContainer>
          <TableRow>
            <TableCol1>현재 담당자</TableCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <OutlinedInput
                id="outlined-creator"
                value={open.SALESPERSON_ID}
                size="small"
                readOnly
              />
              {origin !== undefined && (
                <Autocomplete
                  options={[origin]}
                  getOptionLabel={(option) => option.USER_NAME}
                  isOptionEqualToValue={(option, value) =>
                    option.USER_ID === value.USER_ID
                  }
                  defaultValue={origin}
                  value={origin}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                  readOnly
                />
              )}
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableMultiInfoCol1>
              변경 담당자
              <Tooltip title={"영업팀"} placement="top">
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "11px",
                    color: "#1976d2",
                    ml: 1,
                  }}
                />
              </Tooltip>
            </TableMultiInfoCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <OutlinedInput
                id="outlined-creator"
                value={selectedUser !== null ? selectedUser?.USER_ID : ""}
                size="small"
                readOnly
              />
              <Autocomplete
                options={target.filter(
                  (v) => v.USER_ID !== open.SALESPERSON_ID
                )}
                getOptionLabel={(option) => option.USER_NAME}
                isOptionEqualToValue={(option, value) =>
                  option.USER_ID === value.USER_ID
                }
                onChange={(event, value) => setSelectedUser(value)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
              />
            </TableCol2>
          </TableRow>
        </TableContainer>

        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ flex: 1 }}>
            변경
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}
