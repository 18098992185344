export const formatDateYYYYMMDD = (input: string): string => {
  if(typeof input !== 'string') 
    return "";
  return (
    input.substring(0, 4) +
    "-" +
    input.substring(4, 6) +
    "-" +
    input.substring(6, 8)
  );
};

export const formatDate = (input: string | Date, format?: boolean): string => {
  let date = undefined;
  if (typeof input === "string") {
    date = new Date(input);
  } else {
    date = input;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  if (format) {
    return `${year}-${month}-${day}`;
  }

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function formatDateToMysql(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

/**
 * 한국 날짜 반환
 * @returns string
 */
export const getCurrentKorDate = () => {
  const curr = new Date();
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + KR_TIME_DIFF);

  const year = kr_curr.getFullYear();
  let month = kr_curr.getMonth() + 1;
  let day = kr_curr.getDate();

  let sMonth = month.toString();
  let sDay = day.toString();

  if (month < 10) {
    sMonth = "0" + month;
  }
  if (day < 10) {
    sDay = "0" + day;
  }

  return `${year}${sMonth}${sDay}`;
};

export const getCurrentKorMonth = () => {
  const curr = new Date();
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + KR_TIME_DIFF);

  const year = kr_curr.getFullYear();
  let month = kr_curr.getMonth() + 1;

  let sMonth = month.toString();

  if (month < 10) {
    sMonth = "0" + month;
  }

  return `${year}${sMonth}`;
};
