import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import {
  TableCol1,
  TableCol2,
  TableContainer,
  TableMultiInfoCol1,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { getDetailCode } from "../../../service/common/api";
import { CodeDetailSimpleType, CodeDetailType } from "../../../types/CodeType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import { formatDateToMysql } from "../../../utils/dateUtil";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

interface Props {
  open: EntGetType;
  type: "image" | "image+video";
  onSubmit: () => void;
  onCancel: () => void;
}

type DivideUserType = {
  USER_ID: string;
  USER_NAME: string;
};

export default function LiveDividePopup({
  open,
  type,
  onSubmit,
  onCancel,
}: Props) {
  const [title, setTitle] = useState("");
  const [marketer, setMarketer] = useState<DivideUserType | null>(null);
  const [designer, setDesigner] = useState<DivideUserType | null>(null);
  const [creator, setCreator] = useState<DivideUserType | null>(null);
  const [account, setAccount] = useState<CodeDetailSimpleType | null>(null);
  const [marketerOption, setMarketerOption] = useState<DivideUserType[]>([]);
  const [designerOption, setDesignerOption] = useState<DivideUserType[]>([]);
  const [creatorOption, setCreatorOption] = useState<DivideUserType[]>([]);
  const [accountOptions, setAccountOptions] = useState<CodeDetailSimpleType[]>(
    []
  );

  const fetchCodeDetails = async (
    codeId: string,
    setter: (data: CodeDetailType[]) => void
  ) => {
    try {
      const response = await getDetailCode(codeId);
      setter(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };
  /**
   * API 호출
   */

  const fetchGetDepts = async (
    deptId: string,
    setter: (data: DivideUserType[]) => void
  ) => {
    try {
      const response = await customAxios.get("/user/dept/withEntCount", {
        params: {
          DEPT_ID: deptId,
        },
      });
      setter(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (open) {
      let title = "라이브 업무 분배";
      setTitle(title);

      if (open.TYPE === "domestic") {
        if (type === "image") {
          // 마케팅 DA (필수), 콘텐츠 디자인
          fetchGetDepts("C007C", setMarketerOption);
          fetchGetDepts("C007E", setDesignerOption);
        } else if (type === "image+video") {
          //  마케팅 DA (필수), 콘텐츠 디자인 콘텐츠 영상팀
          fetchGetDepts("C007C", setMarketerOption);
          fetchGetDepts("C007E", setDesignerOption);
          fetchGetDepts("C007F", setCreatorOption);
        }
      } else if (open.TYPE === "overseas") {
        // 마케팅 DA, 콘텐츠디자인
        if (type === "image") {
          // 마케팅 DA (필수), 콘텐츠 디자인
          fetchGetDepts("C007C", setMarketerOption);
          fetchGetDepts("C007E", setDesignerOption);
        } else if (type === "image+video") {
          //  마케팅 DA (필수), 콘텐츠 디자인 콘텐츠 영상팀
          fetchGetDepts("C007C", setMarketerOption);
          fetchGetDepts("C007E", setDesignerOption);
          fetchGetDepts("C007F", setCreatorOption);
        }
      } else if (open.TYPE === "search") {
        // 마케팅 SA
        fetchGetDepts("C007D", setMarketerOption);
      }

      if (isEmpty(open.MARKETER_ID) === false) {
        setMarketer({
          USER_ID: open.MARKETER_ID,
          USER_NAME: open.MARKETER_NM,
        });
      }
      if (isEmpty(open.DESIGNER_ID) === false) {
        setDesigner({
          USER_ID: open.DESIGNER_ID,
          USER_NAME: open.DESIGNER_NM,
        });
      }
      if (isEmpty(open.CREATOR_ID) === false) {
        setCreator({
          USER_ID: open.CREATOR_ID,
          USER_NAME: open.CREATOR_NM,
        });
      }
      if (isEmpty(open.TEAM_ACCOUNT) === false) {
        setAccount({
          CD_DTL_ID: open.TEAM_ACCOUNT,
          CD_DTL_NM: open.TEAM_ACCOUNT,
        });
      }

      fetchCodeDetails("C022", setAccountOptions);
    }
  }, [open]);

  const isProgressLiveStart = (): boolean => {
    if (open.LIVE !== "divide") {
      return false;
    }

    return true;
  };

  /**
   * 컴포넌트 제어
   */
  const handleSubmit = async () => {
    if (marketer?.USER_ID === undefined || marketer?.USER_ID === "") {
      popupCustomAlert("error", "마케터 설정은 필수입니다.");
      return;
    }

    if (open.TYPE === "domestic") {
      if (
        type === "image+video" &&
        (creator?.USER_ID === undefined || creator.USER_ID === "")
      ) {
        popupCustomAlert("error", "영상 담당자 설정은 필수입니다.");
        return;
      }
    }

    if (account?.CD_DTL_NM === undefined || account.CD_DTL_NM === "") {
      popupCustomAlert("error", "팀 계정을 선택해주세요");
      return;
    }

    if (confirm(`라이브 시작하시겠습니까?`)) {
      try {
        const payload = {
          ENT_ID: open.ENT_ID,
          LIVE: "start",
          LIVE_START_AT: formatDateToMysql(new Date()),
          MARKETER_ID: marketer?.USER_ID || "",
          DESIGNER_ID: designer?.USER_ID || "",
          CREATOR_ID: creator?.USER_ID || "",
          TEAM_ACCOUNT: account?.CD_DTL_NM || "",
        };

        await customAxios.put("/ent", payload);
        onSubmit();
      } catch (e: unknown) {
        errorHandler(e);
      }
    }
  };

  const handleDivide = async () => {
    try {
      const payload = {
        ENT_ID: open.ENT_ID,
        MARKETER_ID: marketer?.USER_ID || "",
        DESIGNER_ID: designer?.USER_ID || "",
        CREATOR_ID: creator?.USER_ID || "",
        LIVE: "divide",
        LIVE_DIVIDE_AT: formatDateToMysql(new Date()),
        TEAM_ACCOUNT: account?.CD_DTL_NM || "",
      };
      await customAxios.post("/ent/divide", payload);
      onSubmit();
      popupCustomAlert("success", "담당자를 분배했습니다.");
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleClose = () => {
    onCancel();
  };

  const getTooltipTitle = (
    task: "marketer" | "designer" | "creator"
  ): string => {
    if (open.TYPE === "domestic") {
      if (type === "image") {
        if (task === "marketer") return "마케팅 DA";
        if (task === "designer") return "콘텐츠 디자인";
      } else if (type === "image+video") {
        if (task === "marketer") return "마케팅 DA";
        if (task === "designer") return "콘텐츠 디자인";
        if (task === "creator") return "콘텐츠 영상팀";
      }
    } else if (open.TYPE === "overseas") {
      if (task === "marketer") return "마케팅 DA";
      if (task === "designer") return "콘텐츠 디자인";
      if (task === "creator") return "콘텐츠 영상팀";
    } else if (open.TYPE === "search") {
      if (task === "marketer") return "마케팅 SA";
    }
    return "";
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={2}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Box alignContent={"center"}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "17px",
                fontFamily: "malgun",
              }}
            >
              {title}
            </span>
          </Box>
          <Box>
            <Button variant="text" onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#DCDCDC" }} />
            </Button>
          </Box>
        </Stack>
        <TableContainer>
          <div>
            <TableRow>
              <TableMultiInfoCol1>
                마케터
                <Tooltip title={getTooltipTitle("marketer")} placement="top">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "11px",
                      color: "#1976d2",
                      ml: 1,
                    }}
                  />
                </Tooltip>
              </TableMultiInfoCol1>
              <TableCol2
                style={{ display: "flex", flexDirection: "row", gap: 3 }}
              >
                <OutlinedInput
                  id="outlined-marketer"
                  value={marketer !== null ? marketer?.USER_ID : ""}
                  size="small"
                  readOnly
                />
                <Autocomplete
                  options={marketerOption}
                  getOptionLabel={(option) => option.USER_NAME}
                  isOptionEqualToValue={(option, value) =>
                    option.USER_ID === value.USER_ID
                  }
                  value={marketer}
                  onChange={(event, value) => setMarketer(value)}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                />
              </TableCol2>
            </TableRow>
          </div>
          <div style={{ display: open.TYPE === "search" ? "none" : "block" }}>
            <TableRow>
              <TableMultiInfoCol1>
                디자이너
                <Tooltip title={getTooltipTitle("designer")} placement="top">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "11px",
                      color: "#1976d2",
                      ml: 1,
                    }}
                  />
                </Tooltip>
              </TableMultiInfoCol1>
              <TableCol2
                style={{ display: "flex", flexDirection: "row", gap: 3 }}
              >
                <OutlinedInput
                  id="outlined-designer"
                  value={designer !== null ? designer?.USER_ID : ""}
                  size="small"
                  readOnly
                />
                <Autocomplete
                  options={designerOption}
                  getOptionLabel={(option) => option.USER_NAME}
                  isOptionEqualToValue={(option, value) =>
                    option.USER_ID === value.USER_ID
                  }
                  value={designer}
                  onChange={(event, value) => setDesigner(value)}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                />
              </TableCol2>
            </TableRow>
          </div>
          <div
            style={{
              display:
                (open.TYPE === "domestic" || open.TYPE === "overseas") &&
                type === "image+video"
                  ? "block"
                  : "none",
            }}
          >
            <TableRow>
              <TableMultiInfoCol1>
                영상
                <Tooltip title={getTooltipTitle("creator")} placement="top">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "11px",
                      color: "#1976d2",
                      ml: 1,
                    }}
                  />
                </Tooltip>
              </TableMultiInfoCol1>
              <TableCol2
                style={{ display: "flex", flexDirection: "row", gap: 3 }}
              >
                <OutlinedInput
                  id="outlined-creator"
                  value={creator !== null ? creator?.USER_ID : ""}
                  size="small"
                  readOnly
                />
                <Autocomplete
                  options={creatorOption}
                  getOptionLabel={(option) => option.USER_NAME}
                  isOptionEqualToValue={(option, value) =>
                    option.USER_ID === value.USER_ID
                  }
                  value={creator}
                  onChange={(event, value) => setCreator(value)}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                />
              </TableCol2>
            </TableRow>
          </div>
          <TableRow>
            <TableCol1>팀 계정</TableCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <Autocomplete
                options={accountOptions}
                getOptionLabel={(option) => option.CD_DTL_NM}
                isOptionEqualToValue={(option, value) =>
                  option.CD_DTL_NM === value.CD_DTL_NM
                }
                value={account}
                onChange={(event, value) => setAccount(value)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
              />
            </TableCol2>
          </TableRow>
        </TableContainer>

        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="contained" onClick={handleDivide} sx={{ flex: 1 }}>
            분배
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
            disabled={!isProgressLiveStart()}
            sx={{ flex: 1 }}
          >
            라이브 시작
          </Button>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
        </div>
        <Alert
          variant="standard"
          severity="info"
          icon={<LightbulbOutlinedIcon fontSize="small" color="secondary" />}
          sx={{
            fontFamily: "malgun",
            fontSize: 13,
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>분배를 진행한 후, 라이브 시작을 할 수 있습니다</span>
        </Alert>
      </Stack>
    </Dialog>
  );
}
