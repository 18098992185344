import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { useUpdateDetailCode } from "../../../service/code/codeHooks";
import { updateDetailCodeRequestDto } from "../../../service/code/types";
import { isEmpty } from "../../../utils/isEmpty";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  updateDetailCodeRequestDto: updateDetailCodeRequestDto;
}

const EditDetailCodeModal = ({ isOpen, onSubmit, onClose, updateDetailCodeRequestDto }: Props) => {
  const [mid, setMId] = useState<string>("");
  const [originId, setOriginId] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [use, setUse] = useState<string>("");
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");

  const onError = (message: string) => {
    setAlertMsg(message);
    setIsAlert(true);
  };

  const { mutate: updateDetailCode } = useUpdateDetailCode(onSubmit, onError);

  useEffect(() => {
    if (isOpen === true) {
      setMId(updateDetailCodeRequestDto.CD_ID);
      setOriginId(updateDetailCodeRequestDto.CD_DTL_ID);
      setId(updateDetailCodeRequestDto.CD_DTL_ID);
      setName(updateDetailCodeRequestDto.CD_DTL_NM);
      setDesc(updateDetailCodeRequestDto.DESCRIPTION);
      setUse(updateDetailCodeRequestDto.USE_YN);
      setIsAlert(false);
    }
  }, [isOpen]);

  const onClickSave = () => {
    if (isEmpty(name)) {
      setAlertMsg("코드 이름은 필수 값입니다.");
      setIsAlert(true);
      return;
    }
    updateDetailCode({
      CD_ID: mid.trim(),
      ORIGIN_CD_DTL_ID: originId.trim(),
      CD_DTL_ID: id.trim(),
      CD_DTL_NM: name.trim(),
      DESCRIPTION: desc.trim(),
      USE_YN: use.trim(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlert}
        onClose={() => setIsAlert(false)}
        key={"validationAlert"}
        autoHideDuration={5000}
      >
        <Alert onClose={() => setIsAlert(false)} severity="error" variant="filled" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <Stack>
        <DialogTitle>
          <Typography variant="h4">코드 수정</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>코드 ID</Typography>
              </Box>
              <Button size="large" disabled>
                <Typography sx={{ color: "black" }}>{mid}</Typography>
              </Button>
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>상세코드 ID</Typography>
              </Box>
              <TextField required={true} value={id} onChange={(event) => setId(event.target.value)} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>상세코드 이름</Typography>
              </Box>
              <TextField required={true} value={name} onChange={(event) => setName(event.target.value)} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>설명</Typography>
              </Box>
              <TextField required={true} value={desc} onChange={(event) => setDesc(event.target.value)} />
            </Stack>

            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>사용 여부</Typography>
              </Box>
              <RadioGroup row value={use} onChange={(event) => setUse(event.target.value)}>
                <FormControlLabel value="Y" control={<Radio />} label="Y" />
                <FormControlLabel value="N" control={<Radio />} label="N" />
              </RadioGroup>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onClickSave}>
            수정
          </Button>
          <Button variant="contained" onClick={onClose} autoFocus>
            취소
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
export default EditDetailCodeModal;
