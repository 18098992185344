import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { customAxios } from "../../../config";
import UserUpdatePopup from "../../../pages/common/UserUpdatePopup";
import { errorHandler } from "../../../utils/apiUtil";
import { UpdateUserPwdModalButton } from "./modal/update-password-modal/UpdateUserPwdModalButton";

interface UserMenuProps {
  userId: string;
  userName: string;
  onSuccess: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ userId, userName, onSuccess }) => {
  const [openModal, setOpenModel] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalSubmit = () => {
    setOpenModel("");
    onSuccess();
    handleClose();
  };

  const handleDelete = async () => {
    if (confirm(`정말 ${userName}님의 정보를 삭제하시겠습니까?`)) {
      try {
        await customAxios.delete("/user", {
          data: {
            USER_ID: userId,
          },
        });
        onSuccess();
        handleClose();
      } catch (e: unknown) {
        errorHandler(e);
      }
    }
  };

  return (
    <div>
      {openModal !== "" && (
        <UserUpdatePopup
          open={openModal}
          onSubmit={handleModalSubmit}
          onCancel={() => setOpenModel("")}
        />
      )}
      <IconButton onClick={handleClick} size="small">
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={() => setOpenModel(userId)}>
          정보 수정
        </MenuItem>
        <UpdateUserPwdModalButton userId={userId} userName={userName} />
        <MenuItem onClick={handleDelete} sx={{ color: "red" }}>
          유저삭제
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
