import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Box, Button, SelectChangeEvent, Stack, Tab } from "@mui/material";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  CellKeyboardEvent,
  CellKeyDownArgs,
  ColumnOrColumnGroup,
  PasteEvent,
  RenderHeaderCellProps,
  textEditor,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CommonButton from "../../../components/common/CommonButton";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import {
  COLOR_LIST,
  ColorSelect,
} from "../../../components/shared/cell-renderers/ColorSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectForm from "../../../components/shared/input-field/SelectForm";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { getCurrentDate } from "../../../components/shared/lib/getCurrentDate";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { CellButton } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { colorOptions } from "../../../constants/constant";
import { getDetailCode } from "../../../service/common/api";
import { BlackGetType } from "../../../types/BlackType";
import { CallTabType, CallType } from "../../../types/CallType";
import { CodeDetailType } from "../../../types/CodeType";
import { SortType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import { handleCopy, onColumnResize } from "../../../utils/commonUtil";
import { formatDateYYYYMMDD } from "../../../utils/dateUtil";
import { excelDownLoader } from "../../../utils/excelDownLoader";
import { getJwtState } from "../../../utils/jwtState";
import SheetChangePopup from "./SheetChangePopup";

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}
interface Filter extends CallType {}
const FilterContext = createContext<Filter | undefined>(undefined);
const SortContext = createContext<SortType | undefined>(undefined);
const FilterToggleContext = createContext<boolean>(false);
const DEFAULT_HEADER_HEIGHT = 35;

const initFilter: Filter = {
  CALL_ID: 0,
  CALL_DIV_CD: "",
  RECENT_CONTACT_DATE: "",
  SALESPERSON_ID: "",
  SALESPERSON_NM: "",
  CREATE_DATE: "",
  KEYWORD: "",
  CONTACT_SOURCE: "",
  BUSINESS_NAME: "",
  ENT_CONTACT_NAME: "",
  PRODUCT_URL: "",
  PHONE_NUMBER: "",
  NOTES: "",
  EMAIL: "",
  IS_KAKAO_CONTACT: false,
  IS_MAIL_CONTACT: false,
  COLOR: "",
  CREATED_ID: "",
  CREATED_NM: "",
  CREATED_AT: new Date(0),
  UPDATED_ID: "",
  UPDATED_NM: "",
  UPDATED_AT: new Date(0),
  DELETED_ID: undefined,
  DELETED_NM: undefined,
  DELETED_AT: undefined,
};

const calculateDifferenceCount = (initFilter: Filter, filters: Filter) => {
  let differenceCount = 0;

  for (const key in initFilter) {
    const columnKey = key as keyof Filter;
    if (columnKey !== "CREATE_DATE" && columnKey !== "RECENT_CONTACT_DATE") {
      continue;
    }

    if (initFilter[key as keyof Filter] !== filters[key as keyof Filter]) {
      differenceCount++;
      // console.log(
      //   initFilter[key as keyof Filter],
      //   filters[key as keyof Filter]
      // );
    }
  }

  return differenceCount;
};

const CallTable = () => {
  const gridRef = useRef<any>(null);
  const storedData = localStorage.getItem("call_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [filterToggle, setFilterToggle] = useState(false);

  const [filters, setFilters] = useState<Filter>(() => initFilter);
  const [openSheetChangeModal, setOpenSheetChangeModal] = useState<CallType[]>(
    []
  );

  const handleCellSelected = (position: any) => {
    if (gridRef.current) {
      gridRef.current.selectCell(position, true);
    }
  };

  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );
  const [tab, setTab] = useState<string>("C024A");
  const [tabs, setTabs] = useState<CallTabType[]>([]);
  const [color, setColor] = useState<string>("전체");
  const { userId } = getJwtState();
  const selectedRowId = useRef<number>();
  const selectedColumnKey = useRef<string>();

  const [rows, setRows] = useState<CallType[]>([]);
  const [filterdRows, setFilterdRows] = useState<CallType[]>([]);
  const [blackList, setBlackList] = useState<BlackGetType[]>([]);
  const [search, setSearch] = useState("");
  const [entList, setEntList] = useState<EntGetType[]>([]);

  const [sortType, setSortType] = useState<SortType>({
    columnKey: "",
    direction: "ASC",
  });

  const getTabType = async () => {
    try {
      const response = await getDetailCode("C024");
      const data: CodeDetailType[] = response.data;

      const transformedData = data.map((item) => ({
        value: item.CD_DTL_ID,
        label: item.CD_DTL_NM,
      }));
      if (transformedData.length > 0) {
        setTab(transformedData[0].value);
      }
      setTabs(transformedData);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/call", {
        params: {
          CALL_DIV_CD: tab,
          SALESPERSON_ID: userId,
          COLOR: color === '전체' ? '' : color,
          search,
        },
      });
      const data: CallType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const getEntList = async () => {
    try {
      const response = await customAxios.get("/ent/list");
      setEntList(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getBlackList = async () => {
    try {
      const response = await customAxios.get("/black/call");
      const data: BlackGetType[] = response.data;
      setBlackList(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
    setSelectedRows(new Set());
  }, [tab, color]);

  useEffect(() => {
    getTabType();
    getBlackList();
    getEntList();
  }, []);

  const differenceCount = useMemo(
    () => calculateDifferenceCount(initFilter, filters),
    [filters, filterToggle]
  );

  useEffect(() => {
    // 필터링 함수
    const applyFilters = () => {
      const updatedRows = rows.filter((row) => {
        // 필터의 각 조건을 체크하고, true인 경우에만 통과하도록 설정
        return (
          (!filters.CREATE_DATE ||
            formatDateYYYYMMDD(row.CREATE_DATE).includes(
              filters.CREATE_DATE
            )) &&
          (!filters.RECENT_CONTACT_DATE ||
            row.RECENT_CONTACT_DATE.includes(filters.RECENT_CONTACT_DATE))
        );
      });
      setFilterdRows(updatedRows);
    };

    // filters가 변경될 때마다 applyFilters 실행
    applyFilters();
  }, [filters, rows]); // filters와 rows가 변경될 때만 실행

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
      setSelectedRows(new Set());
    }
  };

  const handleDeleteSelectedRow = async () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", "선택된 데이터가 없습니다");
      return;
    }

    if (confirm(`선택된 ${size}개의 데이터를 삭제하시겠습니까?`)) {
      try {
        await customAxios.delete("/call/selected", {
          data: {
            CALLS_ID: selectedIds,
          },
        });
        setSelectedRows(new Set());
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const handleMoveSheet = () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", `선택된 데이터가 없습니다`);
      return;
    }

    setOpenSheetChangeModal(rows.filter((v) => selectedRows.has(v.CALL_ID)));
  };

  const handleSheetChangeModalSubmit = () => {
    setOpenSheetChangeModal([]);
    setSelectedRows(new Set());
    getRows();
  };

  const handleCreateRow = async () => {
    const currentDate = getCurrentDate();
    try {
      await customAxios.post("/call", {
        CALL_DIV_CD: tab,
        SALESPERSON_ID: userId,
        CREATE_DATE: currentDate,
      });
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleDeleteRow = async (row: any) => {
    const { CALL_ID } = row;
    if (confirm("정말 삭제하시겠습니까?")) {
      try {
        await customAxios.delete("/call", {
          data: {
            CALL_ID,
          },
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnKey.current = cell.column.key;
  };

  // 정렬 처리 핸들러
  const handleSortColumnsChange = (newSortColumns: SortType[]) => {
    let sortColumns = newSortColumns;
    if (!sortColumns[0]) {
      sortColumns = [
        {
          columnKey: sortType.columnKey,
          direction: "ASC",
        },
      ];
      setSortType(sortColumns[0]);
    } else if (sortType.columnKey === sortColumns[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumns[0]);
    }

    const sortedRows = [...rows].sort((a, b) => {
      return sortColumns.reduce((acc, { columnKey, direction }) => {
        if (acc !== 0) return acc; // 이전 정렬 조건의 결과를 유지

        const aValue = a[columnKey as keyof CallType];
        const bValue = b[columnKey as keyof CallType];
        const sortOrder = direction === "ASC" ? 1 : -1;

        // 값이 `null` 또는 `undefined`일 때 처리
        if (aValue == null) return 1 * sortOrder;
        if (bValue == null) return -1 * sortOrder;

        return aValue < bValue
          ? -1 * sortOrder
          : aValue > bValue
          ? 1 * sortOrder
          : 0;
      }, 0);
    });

    setRows(sortedRows);
  };

  const validateRequest = (data: CallType, key: string): boolean => {
    /**
     * 업체명 ENT_NM   BUSINESS_NAME
     * 전화번호  ENT_CONTACT_NUMBER  PHONE_NUMBER
     * 링크 P_URLS  PRODUCT_URL
     *
     * 현재 내부에서 광고 대행을 하는 중이라는 경고 알림
     */

    if (key === "BUSINESS_NAME") {
      if (
        entList.findIndex(
          (v) => v.B_NAME === data.BUSINESS_NAME && v.LIVE !== "end"
        ) > -1
      ) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다",
          "CALL_ENT" + data.BUSINESS_NAME
        );
      }
    } else if (key === "PHONE_NUMBER") {
      if (
        entList.findIndex((v) => v.ENT_CONTACT_NUMBER === data.PHONE_NUMBER) >
        -1
      ) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다 (전화번호)",
          "CALL_ENT" + data.PHONE_NUMBER
        );
      }
    } else if (key === "PRODUCT_URL") {
      if (entList.findIndex((v) => v.P_URLS === data.PRODUCT_URL) > -1) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다 (링크)",
          "CALL_ENT" + data.PRODUCT_URL
        );
      }
    }

    let target = "";
    let index = -1;
    let targetName = "";
    let toastId = "";

    if (key === "BUSINESS_NAME") {
      target = data.BUSINESS_NAME;
      index = blackList.findIndex((v) => v.B_NAME === target);
      targetName = "브랜드명";
      toastId = "black-brand" + data.BUSINESS_NAME;
    } else if (key === "PHONE_NUMBER") {
      target = data.PHONE_NUMBER;
      index = blackList.findIndex((v) => v.PHONE === target);
      targetName = "전화번호";
      toastId = "black-phone" + data.PHONE_NUMBER;
    } else if (key === "EMAIL") {
      target = data.EMAIL;
      index = blackList.findIndex((v) => v.EMAIL === target);
      targetName = "이메일";
      toastId = "black-email" + data.EMAIL;
    } else if (key === "PRODUCT_URL") {
      target = data.PRODUCT_URL;
      index = blackList.findIndex((v) => v.LINK === target);
      targetName = "링크";
      toastId = "black-link" + data.PRODUCT_URL;
    }

    if (index > -1) {
      popupCustomAlert(
        "warn",
        `블랙리스트에 등록되어 있는 정보입니다. (${targetName} : ${target})`,
        toastId
      );
      return false;
    }
    return true;
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateRow = async (newData: any, oldData: any) => {
    const rowId = selectedRowId.current || 0;
    let callInfo: CallType = newData[rowId];
    if (callInfo === undefined || selectedColumnKey.current === undefined) {
      return;
    }
    const updateColumnKey = selectedColumnKey.current;

    if (updateColumnKey === "CHK") {
      setRows((prevRows) =>
        prevRows.map((r) => (r.CALL_ID === callInfo.CALL_ID ? callInfo : r))
      );
      return;
    }

    if (validateRequest(callInfo, updateColumnKey) === false) {
      return;
    }

    try {
      await customAxios.put("/call", callInfo);
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  function handlePaste(event: PasteEvent<CallType>): CallType {
    const { sourceColumnKey, sourceRow, targetColumnKey, targetRow } = event;

    if (targetColumnKey === "COLOR" && sourceColumnKey !== "COLOR") {
      return targetRow;
    }

    const sourceValue = sourceRow[sourceColumnKey as keyof CallType];
    const callInfo = {
      ...targetRow,
      [targetColumnKey]: sourceValue,
    };

    if (validateRequest(callInfo, targetColumnKey) === true) {
      try {
        customAxios.put("/call", callInfo).then(() => getRows());
      } catch (e) {
        errorHandler(e);
      }
    }

    return targetRow;
  }

  function handleCellKeyDown(
    args: CellKeyDownArgs<CallType, any>,
    event: CellKeyboardEvent
  ) {
    const { column, rowIdx, row } = args;

    // 특정 키를 처리하는 예시
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    } else if (event.key === "Delete") {
      // 예시: 삭제 키를 눌렀을 때의 로직
      const targetColumnKey = column.key;
      if (
        [
          "RECENT_CONTACT_DATE",
          "COLOR",
          "BUSINESS_NAME",
          "CONTACT_SOURCE",
          "KEYWORD",
          "PRODUCT_URL",
          "ENT_CONTACT_NAME",
          "PHONE_NUMBER",
          "NOTES",
          "EMAIL",
        ].includes(targetColumnKey)
      ) {
        const sourceValue = row[targetColumnKey as keyof CallType];

        const callInfo = {
          ...row,
          [targetColumnKey]: targetColumnKey === "COLOR" ? "white" : "",
        };

        customAxios
          .put("/call", callInfo)
          .then(() => getRows())
          .catch((e) => errorHandler(e));
      }
    } else if (event.key === "Escape") {
      // 기본 동작을 막고 싶다면 preventDefault 호출
      event.preventDefault();
    } else {
    }
  }

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const handleSelectedRowsChange = (newSelectedRows: ReadonlySet<number>) => {
    setSelectedRows(newSelectedRows);
  };

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<CallType, any>[] = [
    {
      key: "CHK",
      name: "선택",
      headerCellClass: "text-center",
      width: getColumnWidth(widths, "CHK", 70),
      renderHeaderCell: () => {
        const isAllSelected =
          rows.length > 0 && rows.every((row) => selectedRows.has(row.CALL_ID));

        const handleSelectAllChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (event.target.checked) {
            const allRowIds = new Set(rows.map((row) => row.CALL_ID));
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows(new Set());
          }
        };

        return (
          <CustomCheckbox
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
        );
      },
      renderCell: ({ row }: any) => {
        const handleRowCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (event.target.checked) {
              updatedSelectedRows.add(row.CALL_ID);
            } else {
              updatedSelectedRows.delete(row.CALL_ID);
            }
            return updatedSelectedRows;
          });
        };

        return (
          <CustomCheckbox
            checked={selectedRows.has(row.CALL_ID)}
            onChange={handleRowCheckboxChange}
          />
        );
      },
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataCount totalCount={selectedRows.size} />
          </div>
        );
      },
    },
    {
      key: "COLOR",
      name: "색상",
      width: getColumnWidth(widths, "COLOR", 50),
      headerCellClass: "text-center grid-header-font",
      renderCell: ({ row }: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "80%",
              mt: 1,
              backgroundColor: COLOR_LIST[row.COLOR],
            }}
          />
        );
      },
      renderEditCell: ColorSelect,
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "SALESPERSON_NM",
      name: "영업사원",
      width: getColumnWidth(widths, "SALESPERSON_NM", 140),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      renderCell({ row, onRowChange }: any) {
        return (
          <span className="grid-cell-font">
            {row.SALESPERSON_NM} ({row.SALESPERSON_ID})
          </span>
        );
      },
      renderSummaryCell({ row }: any) {
        return <DataCount totalCount={filterdRows.length} text="필터" />;
      },
    },
    {
      key: "CREATE_DATE",
      name: "작성일",
      width: getColumnWidth(widths, "CREATE_DATE", 100),
      draggable: true,
      resizable: true,
      headerCellClass: "filter-cell grid-header-font",
      cellClass: "text-center grid-cell-font",
      renderCell: ({ row }: any) => {
        return (
          <div className="grid-cell-font">
            {formatDateYYYYMMDD(row.CREATE_DATE)}
          </div>
        );
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<CallType> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.CREATE_DATE}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  CREATE_DATE: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "RECENT_CONTACT_DATE",
      name: "최근 컨택일",
      headerCellClass: "filter-cell grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "RECENT_CONTACT_DATE", 150),
      renderEditCell: textEditor,
      renderHeaderCell: (p: any) => (
        <FilterRenderer<CallType> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.RECENT_CONTACT_DATE}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  RECENT_CONTACT_DATE: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "CONTACT_SOURCE",
      name: "출처",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "CONTACT_SOURCE", 100),
      renderEditCell: textEditor,
    },
    {
      key: "KEYWORD",
      name: "키워드",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "KEYWORD", 100),
      renderEditCell: textEditor,
    },

    {
      key: "BUSINESS_NAME",
      name: "브랜드명",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "BUSINESS_NAME", 100),
      renderEditCell: textEditor,
    },
    {
      key: "PRODUCT_URL",
      name: "링크",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "PRODUCT_URL", 180),
      renderEditCell: textEditor,
    },
    {
      key: "ENT_CONTACT_NAME",
      name: "업체 담당자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
      renderEditCell: textEditor,
    },
    {
      key: "PHONE_NUMBER",
      name: "전화번호",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "PHONE_NUMBER", 150),
      renderEditCell: textEditor,
    },
    {
      key: "NOTES",
      name: "통화 내용",
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "NOTES", 250),
      ...MultiRowCell(),
    },
    {
      key: "EMAIL",
      name: "이메일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "EMAIL", 150),
      renderEditCell: textEditor,
    },
    {
      key: "IS_KAKAO_CONTACT",
      name: "카톡",
      width: getColumnWidth(widths, "IS_KAKAO_CONTACT", 70),
      headerCellClass: "text-center grid-header-font",
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_KAKAO_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_KAKAO_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#ecca06"
          />
        );
      },
    },
    {
      key: "IS_MAIL_CONTACT",
      name: "메일",
      width: getColumnWidth(widths, "IS_MAIL_CONTACT", 70),
      headerCellClass: "text-center grid-header-font",
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_MAIL_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_MAIL_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#6e00d4"
          />
        );
      },
    },
    {
      key: "DELETE",
      name: "삭제",
      width: getColumnWidth(widths, "DELETE", 70),
      headerCellClass: "text-center grid-header-font",
      renderCell: ({ row }: any) => {
        return (
          <CellButton
            style={{ color: "red" }}
            onClick={() => {
              handleDeleteRow(row);
            }}
          >
            삭제
          </CellButton>
        );
      },
    },
  ];

  const handleColorChange = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const handleExcelExport = () => {
    if (filterdRows.length === 0) {
      popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
      return;
    }
    const excelData = filterdRows.map((item) => {
      return {
        영업사원ID: item.SALESPERSON_ID,
        영업사원명: item.SALESPERSON_NM,
        작성일: item.CREATE_DATE,
        최근컨택일: item.RECENT_CONTACT_DATE,
        색상: item.COLOR,
        출처: item.CONTACT_SOURCE,
        키워드: item.KEYWORD,
        브랜드명: item.BUSINESS_NAME,
        링크: item.PRODUCT_URL,
        업체담당자: item.ENT_CONTACT_NAME,
        전화번호: item.PHONE_NUMBER,
        통화내용: item.NOTES,
        이메일: item.EMAIL,
        카톡: item.IS_KAKAO_CONTACT ? "O" : "X",
        메일: item.IS_MAIL_CONTACT ? "O" : "X",
      };
    });

    excelDownLoader(excelData, "콜시트.xlsx");
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"} gap={2}>
      {openSheetChangeModal.length > 0 && (
        <SheetChangePopup
          open={openSheetChangeModal}
          tab={tab}
          tabs={tabs}
          onSubmit={handleSheetChangeModalSubmit}
          onCancel={() => setOpenSheetChangeModal([])}
        />
      )}
      <Stack gap={2} display={"flex"} flex={1}>
        <Stack direction="row" justifyContent={"space-between"} gap={3}>
          <TabContext value={tab}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab key={"C024A"} label={"콜시트"} value={"C024A"} />
              <Tab key={"C024B"} label={"가망 건"} value={"C024B"} />
              <Tab key={"C024C"} label={"분배 DB"} value={"C024C"} />
              <Tab key={"C024D"} label={"A급 DB"} value={"C024D"} />
            </TabList>
          </TabContext>
          <Stack
            flexDirection={"row"}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <SelectForm
              label="색상"
              options={colorOptions}
              size="small"
              sx={{ width: "120px" }}
              value={color}
              handleChange={handleColorChange}
            />
            <SearchInputField
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              showIcon={false}
              width={200}
            />
            <CommonButton
              onClick={() => {
                getRows();
                setSelectedRows(new Set());
              }}
              label="검색"
              icon={<SearchIcon fontSize="small" />}
            />
            <Button
              sx={{
                // backgroundColor: "white",
                letterSpacing: 2,
                fontFamily: "malgun",
                height: 40,
              }}
              color={
                filterToggle === false && differenceCount > 0
                  ? "warning"
                  : "primary"
              }
              onClick={() => setFilterToggle(!filterToggle)}
              variant={"contained"}
              startIcon={
                filterToggle ? <FilterAltIcon /> : <FilterAltOffIcon />
              }
            >
              필터
              {filterToggle === false && differenceCount > 0 && (
                <span style={{ marginLeft: 2 }}>({differenceCount})</span>
              )}
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleExcelExport}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
            >
              엑셀 다운로드
            </Button>
            <Button
              variant="contained"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleCreateRow}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
            >
              행 추가
            </Button>
            <Button
              variant="contained"
              startIcon={<DriveFileMoveIcon />}
              onClick={handleMoveSheet}
              color="success"
              sx={{ fontFamily: "malgun", height: 40, color: "white" }}
            >
              시트 이동
            </Button>
            <Button
              variant="contained"
              startIcon={<DeleteForeverOutlinedIcon />}
              onClick={handleDeleteSelectedRow}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
              color="warning"
            >
              일괄 삭제
            </Button>
          </Stack>
        </Stack>
        {rows && (
          <FilterToggleContext.Provider value={filterToggle}>
            <FilterContext.Provider value={filters}>
              <SortContext.Provider value={sortType}>
                <DataGrid
                  className="rdg-light"
                  style={{ height: `84vh` }}
                  ref={gridRef}
                  selectedRows={selectedRows}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  rowKeyGetter={(row: CallType) => row.CALL_ID}
                  headerRowHeight={filterToggle ? 68 : DEFAULT_HEADER_HEIGHT}
                  rowHeight={autoRowHeight}
                  onCellClick={handleCellSelected}
                  onSelectedCellChange={handleSelectedRow}
                  onRowsChange={handleUpdateRow}
                  sortColumns={[sortType]}
                  onSortColumnsChange={handleSortColumnsChange}
                  columns={columns}
                  rows={filterdRows}
                  bottomSummaryRows={summaryRows}
                  rowClass={(
                    row: CallType,
                    rowIdx: number
                  ): string | undefined => {
                    return row.COLOR === "" || row.COLOR === "white"
                      ? undefined
                      : `${row.COLOR}-background`;
                  }}
                  defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                  }}
                  onColumnResize={(idx, width) =>
                    onColumnResize("call_list", columns, idx, width)
                  }
                  onPaste={handlePaste}
                  onCellKeyDown={handleCellKeyDown}
                  onCopy={handleCopy}
                />
              </SortContext.Provider>
            </FilterContext.Provider>
          </FilterToggleContext.Provider>
        )}
      </Stack>
    </Stack>
  );
};
export default CallTable;

function FilterRenderer<R>({
  tabIndex,
  column,
  children,
}: RenderHeaderCellProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement;
}) {
  const filterToggle = useContext(FilterToggleContext)!;
  const filters = useContext(FilterContext)!;
  const sortType = useContext(SortContext)!;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          paddingInline: "8px",
        }}
      >
        <div
          style={{
            height: `${DEFAULT_HEADER_HEIGHT}px`,
            lineHeight: `${DEFAULT_HEADER_HEIGHT}px`,
            flex: 1,
          }}
        >
          {column.name}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {column.key === sortType.columnKey &&
            (sortType.direction === "ASC" ? (
              <ArrowDropDownIcon />
            ) : (
              <ArrowDropUpIcon />
            ))}
        </div>
      </div>
      {filterToggle && (
        <div
          style={{
            borderTop: "1px solid #dddddd",
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // height: "100%",
          }}
        >
          {/* <input/> */}
          {children({ tabIndex, filters })}
        </div>
      )}
    </div>
  );
}
