import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import {
  popupCustomAlert
} from "../../../components/common/CustomToast";
import {
  PopupCloseButton,
  TableCol1,
  TableCol2,
  TableContainer,
  TableMultiInfoCol1,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { CallTabType, CallType } from "../../../types/CallType";
import { errorHandler } from "../../../utils/apiUtil";

interface Props {
  open: CallType[];
  tab: string;
  tabs: CallTabType[];
  onSubmit: () => void;
  onCancel: () => void;
}

export default function SheetChangePopup({
  open,
  tab,
  tabs,
  onSubmit,
  onCancel,
}: Props) {
  const [title] = useState("시트 이동");
  const [origin, setOrigin] = useState<CallTabType>();
  const [target, setTarget] = useState<CallTabType[]>([]);
  const [selectedTab, setSelectedTab] = useState<CallTabType | null>(null); // 선택된 영업사원

  useEffect(() => {
    if (open) {
      setOrigin(tabs.find((v) => v.value === tab));
      setTarget(tabs.filter((v) => v.value !== tab));
    }
  }, [open]);

  /**
   * 컴포넌트 제어
   */

  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (selectedTab === null) {
      // toast(<CustomToast msg="이동할 시트를 선택해주세요" />, toastOptions);
      popupCustomAlert("warn", "이동할 시트를 선택해주세요");
      return;
    }

    try {
      await customAxios.put("/call/sheet/move", {
        row: open,
        tab: selectedTab.value,
      });
      onSubmit();
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={1}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Stack
            flexDirection={"row"}
            alignContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
            <span
              style={{
                fontFamily: "malgun",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              대상 데이터 : {open.length}건
            </span>
          </Stack>
          <Box>
            <PopupCloseButton onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#BDBDBD" }} />
            </PopupCloseButton>
          </Box>
        </Stack>
        <TableContainer>
          <TableRow>
            <TableCol1>현재 시트</TableCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              {origin !== undefined && (
                <Autocomplete
                  options={[origin]}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  defaultValue={origin}
                  value={origin}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                  readOnly
                />
              )}
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableMultiInfoCol1>이동 시트</TableMultiInfoCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <Autocomplete
                options={target}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, value) => setSelectedTab(value)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
              />
            </TableCol2>
          </TableRow>
        </TableContainer>

        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ flex: 1 }}>
            이동
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}
