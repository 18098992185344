import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Button, SelectChangeEvent, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import CustomSelectForm from "../../../components/common/CustomSelectForm";
import SelectLiveForm, {
  liveStateOptions,
} from "../../../components/common/SelectLiveForm";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import { SelectFormData } from "../../../components/shared/input-field/SelectForm";
import SelectYearField from "../../../components/shared/input-field/SelectYearField";
import { getDetailCode } from "../../../service/common/api";
import { CodeDetailType } from "../../../types/CodeType";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { errorHandler } from "../../../utils/apiUtil";
import { getJwtState } from "../../../utils/jwtState";
import SettleTable from "./SettleTable";

export default function SettlePage() {
  const { deptId } = getJwtState();
  const [selected, setSelected] = useState<TabType>("overseas");
  const [search, setSearch] = useState("");
  const tableRef = useRef<ListTableHandle>(null);
  const today = new Date();
  const [year, setYear] = useState<string>(today.getFullYear().toString()); // 오늘 년도
  const [live, setLive] = useState<string>("start");
  const [media, setMedia] = useState<string>("전체");
  const [medias, setMedias] = useState<SelectFormData[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectFormData[]>([]);

  useEffect(() => {
    getMediaCode();
    getLiveStateCode();
  }, []);

  useEffect(() => {
    onSearch();
  }, [selected, media, year, live]);

  const getLiveStateCode = () => {
    const instance: SelectFormData[] = [];
    for (let i = 0; i < liveStateOptions.length; i++) {
      const v = liveStateOptions[i].value;
      if (v === "ready" || v === "request" || v === "divide") continue;
      instance.push(liveStateOptions[i]);
    }

    setStateOptions(instance);
  };

  const getMediaCode = async () => {
    try {
      // const response = await getDetailCode("C017,C018,C019");
      const response = await getDetailCode("C018");
      const data: CodeDetailType[] = response.data;

      const instance: SelectFormData[] = [];
      instance.push({
        label: "전체",
        value: "전체",
      });

      for (let i = 0; i < data.length; i++) {
        const id = data[i].CD_ID;
        const name = data[i].CD_DTL_NM;
        // let type = "";
        // if (id === "C017") {
        //   type = "[국내] ";
        // } else if (id === "C018") {
        //   type = "[해외] ";
        // } else if (id === "C019") {
        //   type = "[검색] ";
        // }

        instance.push({
          label: name,
          value: name,
        });
      }

      setMedias(instance);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const onClickExcelDownload = () => {
    tableRef.current?.triggerExcelDownload();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleLiveChange = (e: SelectChangeEvent) => {
    setLive(e.target.value);
  };

  const handleMediaChange = async (e: SelectChangeEvent) => {
    setMedia(e.target.value);
  };
  /**
   * C007C 마케팅팀 DA
   * C007D 마케팅팀 SA
   * C007E 콘_디
   * C007F 콘_영상
   */

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {/* <Tab label={"전체"} value={""} />
                <Tab
                  label={"국내매체광고"}
                  value={"domestic"}
                  style={{
                    display:
                      deptId === "C007D" || deptId === "C007F"
                        ? "none"
                        : "block",
                  }}
                /> */}
                <Tab
                  label={"해외매체광고"}
                  value={"overseas"}
                  style={{ display: deptId === "C007D" ? "none" : "block" }}
                />
                {/* <Tab
                  label={"검색광고"}
                  value={"search"}
                  style={{
                    display: deptId === "C007C" ? "none" : "block",
                  }}
                /> */}
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                <CustomSelectForm
                  key={"selectMedia"}
                  options={medias}
                  label="매체"
                  fullWidth={false}
                  size="small"
                  value={media}
                  handleChange={handleMediaChange}
                  colors={["#000000", "#ff0000", "#0081FB", "#3aaeaa"]}
                  // colors={[
                  //   "#000000",
                  //   "#2DB400",
                  //   "#F9E000",
                  //   "#fd7f18",
                  //   "#0064FF",
                  //   "#ff0000",
                  //   "#0081FB",
                  //   "#3aaeaa",
                  //   "#6e0fbb",
                  // ]}
                  sx={{
                    width: 120,
                  }}
                />
                <SelectLiveForm
                  label="상태"
                  options={stateOptions}
                  size="small"
                  sx={{ width: "120px" }}
                  value={live}
                  handleChange={handleLiveChange}
                />
                <SelectYearField
                  sx={{ backgroundColor: "white" }}
                  label="년도"
                  fullWidth={false}
                  size="small"
                  value={year}
                  handleChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
                <SearchInputField
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  showIcon={false}
                  width={200}
                />
                <CommonButton
                  onClick={onSearch}
                  label="검색"
                  icon={<SearchIcon fontSize="small" />}
                />
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={onClickExcelDownload}
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                >
                  엑셀 다운로드
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <Stack mt={2} flex={1}>
          <SettleTable
            type={selected}
            search={search}
            year={year}
            ref={tableRef}
            live={live}
            media={media}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
