/**
 * 전역변수로 관리되는 모달을 렌더링하는 컴포넌트
 */

import { useModals } from "../../service/modal/useModal";

const Modals: React.FC = () => {
  const { modals } = useModals();

  return (
    <>
      {modals.map((modal, index) => (
        <modal.Component key={index} {...modal.props} />
      ))}
    </>
  );
};
export default Modals;
