import { Stack, Typography } from "@mui/material";
import SideNavItem from "./SideNavItem";
import styled from "styled-components";
import { NAV_ITEMS } from "../../../config/nav-config";
import { SIDENAV_WIDTH, TOPNAV_HEIGHT } from "../../../constants/constant";
import { useGetMyMenu } from "../../../service/menu/menuHooks";
import React from "react";

//사이드바 크기

const SideNav: React.FC = () => {
  const { data: menuSet } = useGetMyMenu();

  return (
    <Container>
      {!menuSet ? (
        <Typography>로딩중...</Typography>
      ) : (
        <>
          {Object.keys(NAV_ITEMS).map((key, i) => {
            return (
              <React.Fragment key={i}>
                {NAV_ITEMS[key].some((item) => menuSet.has(item.link)) && (
                  <Typography
                    key={i}
                    variant="caption"
                    sx={{ mt: 5, px: 5, py: 1, fontSize: "14px" }}
                    color="#BDBDBD"
                    fontFamily={"malgun"}
                  >
                    {key}
                  </Typography>
                )}
                {NAV_ITEMS[key].map((item) => {
                  if (menuSet.has(item.link)) {
                    return (
                      <Stack key={item.link}>
                        <SideNavItem key={item.link} item={item} />
                      </Stack>
                    );
                  } else {
                    return null;
                  }
                })}
              </React.Fragment>
            );
          })}
        </>
      )}
    </Container>
  );
};

export default SideNav;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  z-index: 100;
  /* background-color: #383838; */
  background: linear-gradient(45deg, #292929, #424242);
  position: fixed;
  min-width: ${SIDENAV_WIDTH};
  width: ${SIDENAV_WIDTH};
  margin-top: ${TOPNAV_HEIGHT};
  height: calc(100vh - ${TOPNAV_HEIGHT});
  overflow-y: auto;
  box-sizing: border-box;
`;
