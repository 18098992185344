import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CodeAPI } from "./codeAxios";
import {
  createCodeRequestDto,
  createCodeResponseDto,
  createDetailCodeRequestDto,
  createDetailCodeResponseDto,
  deleteCodeRequestDto,
  deleteCodeResponseDto,
  deleteDetailCodeRequestDto,
  deleteDetailCodeResponseDto,
  getCodeRequestDto,
  getCodeResponseDto,
  getDetailCodeRequestDto,
  getDetailCodeResponseDto,
  updateCodeRequestDto,
  updateCodeResponseDto,
  updateDetailCodeRequestDto,
  updateDetailCodeResponseDto,
} from "./types";
import { HttpErrorResponseType, isAxiosError } from "../../types/AxiosType";

const QUERY_KEY = {
  code: "code",
  detailCode: "detailCode"
};


// useGetCode는 getCode API를 호출하는 hook입니다.
export const useGetCode = (data: getCodeRequestDto | undefined) => {
  return useQuery<getCodeResponseDto[], Error, getCodeResponseDto[]>({
    queryKey: [QUERY_KEY.code],
    queryFn: () => CodeAPI.getCode(data),
  });
};


// useCreateCode는 createCode API를 호출하는 hook입니다.
export const useCreateCode = (onSubmit: () => void, onError: (message: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<createCodeResponseDto, Error, createCodeRequestDto>({
    mutationFn: (data: createCodeRequestDto) =>
      CodeAPI.createCode(data),
    onSuccess: (data) => {
      onSubmit();
      // queryClient.setQueryData(
      //   [QUERY_KEY.code],
      //   (oldData: getCodeResponseDto[] | undefined) =>
      //     oldData ? [data, ...oldData] : [data]
      // );
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        onError(error.response?.data.error.message || '');
      } else {
        onError("알 수 없는 오류");
      }
    },
  });
};


// useUpdateCode는 updateCode API를 호출하는 hook입니다.
export const useUpdateCode = (onSubmit: () => void, onError: (message: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<updateCodeResponseDto, Error, updateCodeRequestDto>({
    mutationFn: (data: updateCodeRequestDto) =>
      CodeAPI.updateCode(data),
    onSuccess: (data) => {
      onSubmit()
      // queryClient.setQueryData(
      //   [QUERY_KEY.code],
      //   (oldData: getCodeResponseDto[] | undefined) =>
      //     oldData ? [data, ...oldData] : [data]
      // );
    },
  });
}


// useDeleteCode는 deleteCode API를 호출하는 hook입니다.
export const useDeleteCode = (onSucess: () => void, onError: (message: string) => void) => {
  // const queryClient = useQueryClient();
  return useMutation<deleteCodeResponseDto, Error, deleteCodeRequestDto>({
    mutationFn: (data: deleteCodeRequestDto) =>
      CodeAPI.deleteCode(data),
    onSuccess: (data) => {
      onSucess();
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        onError(error.response?.data.error.message || '');
      } else {
        onError("알 수 없는 오류");
      }
    },
  });
}



export const useGetDetailCode = (data: getDetailCodeRequestDto) => {
  return useQuery<getDetailCodeResponseDto[], Error, getDetailCodeResponseDto[]>({
    queryKey: [QUERY_KEY.detailCode,data],
    queryFn: () => CodeAPI.getDetailCode(data),
  });
};


// useCreateCode는 createCode API를 호출하는 hook입니다.
export const useCreateDetailCode = (onSubmit: () => void, onError: (message: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<createDetailCodeResponseDto, Error, createDetailCodeRequestDto>({
    mutationFn: (data: createDetailCodeRequestDto) =>
      CodeAPI.createDetailCode(data),
    onSuccess: (data) => {
      onSubmit();
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        onError(error.response?.data.error.message || '');
      } else {
        onError("알 수 없는 오류");
      }
    },
  });
};


// useUpdateCode는 updateCode API를 호출하는 hook입니다.
export const useUpdateDetailCode = (onSubmit: () => void, onError: (message: string) => void) => {
  const queryClient = useQueryClient();
  return useMutation<updateDetailCodeResponseDto, Error, updateDetailCodeRequestDto>({
    mutationFn: (data: updateDetailCodeRequestDto) =>
      CodeAPI.updateDetailCode(data),
    onSuccess: (data) => {
      onSubmit()
    },
  });
}


// useDeleteCode는 deleteCode API를 호출하는 hook입니다.
export const useDeleteDetailCode = (onSucess: () => void) => {
  // const queryClient = useQueryClient();
  return useMutation<deleteDetailCodeResponseDto, Error, deleteDetailCodeRequestDto>({
    mutationFn: (data: deleteDetailCodeRequestDto) =>
      CodeAPI.deleteDetailCode(data),
    onSuccess: (data) => {
      onSucess();
    },
  });
}

