
export const isEmpty = (obj: any) => {
    // return !obj

    if (obj === undefined || obj === null || obj === "null" || obj === "")
        return true;
    if (obj instanceof Array) {
        if (obj.length === 0) return true;
    }
    if (JSON.stringify(obj) === "{}") return true;
    return false;
};
