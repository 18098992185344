import { RenderEditCellProps } from "react-data-grid";
import { CellSelect } from "./CellSelect";
import { useMemo } from "react";

export const COLOR_LIST: { [key: string]: string } = {
  red: "#f3cccb",
  orange: "#fde5cd",
  yellow: "#fff2cc",
  green: "#daead2",
  blue: "#cee3f3",
};

export function ColorSelect({ row, onRowChange }: RenderEditCellProps<any>) {
  const data = useMemo(() => {
    const result = [];

    for (const key in COLOR_LIST) {
      result.push({ CD_DTL_ID: COLOR_LIST[key], CD_DTL_NM: key });
    }
    return result;
  }, []);

  return (
    <>
      <CellSelect
        value={row.COLOR}
        onChange={(event) => onRowChange({ ...row, COLOR: event.target.value }, true)}
        autoFocus
      >
        <option value={"white"}>{"없음"}</option>
        {data &&
          data.map((item, i) => (
            <option key={i} value={item.CD_DTL_NM} style={{ background: item.CD_DTL_ID, fontFamily:"malgun", fontWeight:"bold" }}>
              {item.CD_DTL_NM}
            </option>
          ))}
      </CellSelect>
    </>
  );
}
