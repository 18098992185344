import { Typography } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";

const TableNoRowsOverlay = () => {
  return (
    <GridOverlay>
      <Typography variant="h6">데이터가 없습니다</Typography>
    </GridOverlay>
  );
};

export default TableNoRowsOverlay;
