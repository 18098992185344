import DownloadIcon from "@mui/icons-material/Download";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, SelectChangeEvent, Stack, Tab } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectForm from "../../../components/shared/input-field/SelectForm";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { CallButton } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { colorOptions } from "../../../constants/constant";
import { getDetailCode } from "../../../service/common/api";
import { CallTabType, CallType } from "../../../types/CallType";
import { CodeDetailType } from "../../../types/CodeType";
import { errorHandler } from "../../../utils/apiUtil";
import { handleCopy, onColumnResize } from "../../../utils/commonUtil";
import { formatDateYYYYMMDD } from "../../../utils/dateUtil";
import { excelDownLoader } from "../../../utils/excelDownLoader";
import SalesManChangePopup from "./SalesManChangePopup";
import SheetChangePopup from "./SheetChangePopup";
import "./style.css";
import CommonButton from "../../../components/common/CommonButton";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SalesManChangePeoplePopup from "./SalesManChangePeoplePopup";

export default function TotalCallList() {
  const gridRef = useRef<any>(null);

  const [tab, setTab] = useState<string>("C024A");
  const [tabs, setTabs] = useState<CallTabType[]>([]);
  const [search, setSearch] = useState("");
  const [color, setColor] = useState<string>("전체");
  const [rows, setRows] = useState<CallType[]>([]);
  const [openModal, setOpenModel] = useState<CallType>();
  const [openChangePeopleModal, setOpenChangePeopleModal] = useState<
    CallType[]
  >([]);
  const [openSheetChangeModal, setOpenSheetChangeModal] = useState<CallType[]>(
    []
  );
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태
  const selectedRowId = useRef<number>();
  const selectedColumnKey = useRef<string>();
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );

  const storedData = localStorage.getItem("total_call_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;

  const getTabType = async () => {
    try {
      const response = await getDetailCode("C024");
      const data: CodeDetailType[] = response.data;

      const transformedData = data.map((item) => ({
        value: item.CD_DTL_ID,
        label: item.CD_DTL_NM,
      }));
      if (transformedData.length > 0) {
        setTab(transformedData[0].value);
      }
      setTabs(transformedData);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnKey.current = cell.column.key;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleClickMenuItem = (row: CallType) => {
    setOpenModel(row);
  };

  const handleUpdateRow = async (newData: any, oldData: any) => {
    const rowId = selectedRowId.current || 0;
    let callInfo: CallType = newData[rowId];
    if (callInfo === undefined || selectedColumnKey.current === undefined) {
      return;
    }
    // const updateColumnKey = selectedColumnKey.current;

    // if (updateColumnKey === "CHK") {
    //   setRows((prevRows) =>
    //     prevRows.map((r) => (r.CALL_ID === callInfo.CALL_ID ? callInfo : r))
    //   );
    //   return;
    // }
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/call/total", {
        params: {
          CALL_DIV_CD: tab,
          search,
          COLOR: color === '전체' ? '' : color,
        },
      });
      const data: CallType[] = response.data;
      setRows(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getTabType();
  }, []);

  useEffect(() => {
    getRows();
    setSelectedRows(new Set());
  }, [tab, color]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
      setSelectedRows(new Set());
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleColorChange = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const handleExcelExport = () => {
    if (rows.length === 0) {
      popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
      return;
    }

    const excelData = rows.map((item) => ({
      영업사원ID: item.SALESPERSON_ID,
      영업사원명: item.SALESPERSON_NM,
      작성일: item.CREATE_DATE,
      색상: item.COLOR,
      최근컨택일: item.RECENT_CONTACT_DATE,
      출처: item.CONTACT_SOURCE,
      키워드: item.KEYWORD,
      브랜드명: item.BUSINESS_NAME,
      링크: item.PRODUCT_URL,
      업체담당자: item.ENT_CONTACT_NAME,
      전화번호: item.PHONE_NUMBER,
      통화내용: item.NOTES,
      이메일: item.EMAIL,
      카톡: item.IS_KAKAO_CONTACT ? "O" : "X",
      메일: item.IS_MAIL_CONTACT ? "O" : "X",
    }));
    excelDownLoader(excelData, "통합_콜시트.xlsx");
  };

  const handleChangeSalesPeople = () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", `선택된 데이터가 없습니다`);
      return;
    }

    setOpenChangePeopleModal(rows.filter((v) => selectedRows.has(v.CALL_ID)));
  };

  const handleMoveSheet = () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", `선택된 데이터가 없습니다`);
      return;
    }

    setOpenSheetChangeModal(rows.filter((v) => selectedRows.has(v.CALL_ID)));
  };

  const handleModalSubmit = () => {
    setOpenModel(undefined);
    getRows();
  };

  const handleSheetChangeModalSubmit = (type: "people" | "sheet") => {
    if (type === "people") {
      setOpenChangePeopleModal([]);
    } else if (type === "sheet") {
      setOpenSheetChangeModal([]);
    }

    setSelectedRows(new Set());
    getRows();
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  // 정렬 처리 핸들러
  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleSelectedRowsChange = (newSelectedRows: ReadonlySet<number>) => {
    setSelectedRows(newSelectedRows);
  };

  const columns: ColumnOrColumnGroup<CallType, any>[] = [
    {
      key: "CHK",
      name: "선택",
      headerCellClass: "text-center",
      frozen: true,
      width: getColumnWidth(widths, "CHK", 70),
      renderHeaderCell: () => {
        const isAllSelected =
          rows.length > 0 && rows.every((row) => selectedRows.has(row.CALL_ID));

        const handleSelectAllChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (event.target.checked) {
            const allRowIds = new Set(rows.map((row) => row.CALL_ID));
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows(new Set());
          }
        };

        return (
          <CustomCheckbox
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
        );
      },
      renderCell: ({ row }: any) => {
        const handleRowCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (event.target.checked) {
              updatedSelectedRows.add(row.CALL_ID);
            } else {
              updatedSelectedRows.delete(row.CALL_ID);
            }
            return updatedSelectedRows;
          });
        };

        return (
          <CustomCheckbox
            checked={selectedRows.has(row.CALL_ID)}
            onChange={handleRowCheckboxChange}
          />
        );
      },
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataCount totalCount={selectedRows.size} />
          </div>
        );
      },
    },
    {
      key: "SALESPERSON_NM",
      name: "영업사원",
      width: getColumnWidth(widths, "SALESPERSON_NM", 120),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      frozen: true,
      renderCell({ row, onRowChange }: any) {
        return (
          <CallButton
            id="basic-button"
            onClick={() => handleClickMenuItem(row)}
          >
            {row.SALESPERSON_NM} ({row.SALESPERSON_ID})
          </CallButton>
        );
      },
      editable: false,
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "CREATE_DATE",
      name: "작성일",
      width: getColumnWidth(widths, "CREATE_DATE", 100),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      frozen: true,
      editable: false,
      renderCell: ({ row }: any) => {
        return <div>{formatDateYYYYMMDD(row.CREATE_DATE)}</div>;
      },
    },
    {
      key: "RECENT_CONTACT_DATE",
      name: "최근 컨택일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      editable: false,
      width: getColumnWidth(widths, "RECENT_CONTACT_DATE", 100),
      ...MultiRowCell(),
    },
    {
      key: "CONTACT_SOURCE",
      name: "출처",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      width: getColumnWidth(widths, "CONTACT_SOURCE", 100),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "KEYWORD",
      name: "키워드",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      width: getColumnWidth(widths, "KEYWORD", 100),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "BUSINESS_NAME",
      name: "브랜드명",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "BUSINESS_NAME", 120),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "PRODUCT_URL",
      name: "링크",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "PRODUCT_URL", 150),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "ENT_CONTACT_NAME",
      name: "업체 담당자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      width: getColumnWidth(widths, "ENT_CONTACT_NAME", 150),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "PHONE_NUMBER",
      name: "전화번호",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      width: getColumnWidth(widths, "PHONE_NUMBER", 150),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "NOTES",
      name: "통화 내용",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "NOTES", 300),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "EMAIL",
      name: "이메일",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "EMAIL", 150),
      editable: false,
      ...MultiRowCell(),
    },
    {
      key: "IS_KAKAO_CONTACT",
      name: "카톡",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "IS_KAKAO_CONTACT", 70),
      editable: false,
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_KAKAO_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_KAKAO_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#ecca06"
          />
        );
      },
    },
    {
      key: "IS_MAIL_CONTACT",
      name: "메일",
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "IS_MAIL_CONTACT", 70),
      editable: false,
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_MAIL_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_MAIL_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#6e00d4"
          />
        );
      },
    },
  ];

  return (
    <Stack flex={1} display={"flex"} width={"100%"} gap={2}>
      {openModal !== undefined && (
        <SalesManChangePopup
          open={openModal}
          onSubmit={handleModalSubmit}
          onCancel={() => setOpenModel(undefined)}
        />
      )}
      {openChangePeopleModal.length > 0 && (
        <SalesManChangePeoplePopup
          open={openChangePeopleModal}
          onSubmit={() => handleSheetChangeModalSubmit("people")}
          onCancel={() => setOpenChangePeopleModal([])}
        />
      )}
      {openSheetChangeModal.length > 0 && (
        <SheetChangePopup
          open={openSheetChangeModal}
          tab={tab}
          tabs={tabs}
          onSubmit={() => handleSheetChangeModalSubmit("sheet")}
          onCancel={() => setOpenSheetChangeModal([])}
        />
      )}
      <Stack gap={2} display={"flex"} flex={1}>
        <Stack direction="row" justifyContent={"space-between"} gap={3}>
          <TabContext value={tab}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab key={"C024A"} label={"콜시트"} value={"C024A"} />
              <Tab key={"C024B"} label={"가망 건"} value={"C024B"} />
              <Tab key={"C024C"} label={"분배 DB"} value={"C024C"} />
              <Tab key={"C024D"} label={"A급 DB"} value={"C024D"} />
            </TabList>
          </TabContext>
          <Stack
            flexDirection={"row"}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            <SelectForm
              label="색상"
              options={colorOptions}
              size="small"
              sx={{ width: "120px" }}
              value={color}
              handleChange={handleColorChange}
            />
            <SearchInputField
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              showIcon={false}
              width={200}
            />
            <CommonButton
              onClick={() => {
                getRows();
                setSelectedRows(new Set());
              }}
              label="검색"
              icon={<SearchIcon fontSize="small" />}
            />
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleExcelExport}
              sx={{ fontFamily: "malgun", height: 40 }}
            >
              엑셀 다운로드
            </Button>
            <Button
              variant="contained"
              startIcon={<PeopleAltOutlinedIcon />}
              onClick={handleChangeSalesPeople}
              color="secondary"
              sx={{ fontFamily: "malgun", height: 40, color: "white" }}
            >
              담당자 변경
            </Button>
            <Button
              variant="contained"
              startIcon={<DriveFileMoveIcon />}
              onClick={handleMoveSheet}
              color="success"
              sx={{ fontFamily: "malgun", height: 40, color: "white" }}
            >
              시트 이동
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {rows.length >= 0 && (
        <DataGrid
          className="rdg-light"
          style={{ height: `84vh` }}
          ref={gridRef}
          columns={columns}
          rows={rows}
          selectedRows={selectedRows}
          onSelectedRowsChange={handleSelectedRowsChange}
          rowHeight={autoRowHeight}
          onSelectedCellChange={handleSelectedRow}
          onRowsChange={handleUpdateRow}
          bottomSummaryRows={summaryRows}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          rowClass={(row: any) => {
            return row.COLOR === "" || row.COLOR === "white"
              ? undefined
              : `${row.COLOR}-background`;
          }}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onColumnResize={(idx, width) =>
            onColumnResize("total_call_list", columns, idx, width)
          }
          onCopy={handleCopy}
        />
      )}
    </Stack>
  );
}
