import styled from "styled-components";

export const CellSelect = styled.select`
  height: 100%;
  width: 100%;

  user-select: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;