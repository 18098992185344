import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "../components/ error/ErrorBoundary";
import Login from "../pages/common/Login";
import Layout from "../pages/Layout";
import { NAV_ITEMS, SideNavItemType } from "./nav-config";
export const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: Object.keys(NAV_ITEMS)
      .reduce((result: any, key) => {
        result = [...result, ...NAV_ITEMS[key]];
        return result;
      }, [])
      .map((item: SideNavItemType) => {
        return {
          path: item.link,
          element: item.element,
        };
      }),
  },
]);
