import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  useDeleteDetailCode,
  useGetDetailCode,
} from "../../../service/code/codeHooks";
import {
  deleteDetailCodeRequestDto,
  getDetailCodeResponseDto,
  updateDetailCodeRequestDto,
} from "../../../service/code/types";
import { formatDate } from "../../../utils/dateUtil";
import { isEmpty } from "../../../utils/isEmpty";
import TableNoRowsOverlay from "../../shared/TableNoRowsOverlay";
import EditDetailCodeModal from "./EditDetailCodeModal";
import AddDetailCodeModal from "./AddDetailCodeModal";

interface Props {
  CD_ID: string;
}

const DetailCodeTable = ({ CD_ID }: Props) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [updateRequestDto, setUpdateRequestDto] =
    useState<updateDetailCodeRequestDto>();

  const {
    data: detailData,
    // isLoading,
    refetch,
    // isError,
  } = useGetDetailCode(CD_ID);
  const { mutate: deleteDetailCode } = useDeleteDetailCode(refetch);

  useEffect(() => {
    refetch();
  }, [CD_ID]);

  const handleEdit = (updateRequestDto: updateDetailCodeRequestDto) => {
    setUpdateRequestDto(updateRequestDto);
    setIsOpenEdit(true);
  };

  const handleDelete = (obj: deleteDetailCodeRequestDto) => {
    deleteDetailCode(obj);
  };

  const columns: GridColDef[] = [
    {
      field: "CD_DTL_ID",
      headerName: "상세코드 ID",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CD_DTL_NM",
      headerName: "상세코드 이름",
      width: 200,
    },
    { field: "DESCRIPTION", headerName: "설명" },
    {
      field: "USE_YN",
      headerName: "사용 여부",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CREATED_AT",
      headerName: "생성 일시",
      valueGetter: (value: string) => {
        if (isEmpty(value)) {
          return "";
        }
        return formatDate(value);
      },
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "UPDATED_AT",
      headerName: "수정 일시",
      width: 200,
      valueGetter: (value: string) => {
        if (isEmpty(value)) {
          return "";
        }
        return formatDate(value);
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleEdit(params.row)}
            style={{ marginRight: 8 }}
          >
            수정
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => handleDelete(params.row)}
          >
            삭제
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Stack>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        gap={2}
        mb={2}
        alignItems={'center'}
      >
        <Typography variant="h4">상세 코드</Typography>
        <Chip color="primary" label={CD_ID} variant="outlined" />
        <Button variant="outlined" onClick={() => setIsOpenAdd(true)}>
          상세코드 생성
        </Button>
      </Stack>
      {detailData !== undefined && (
        <DataGrid
          rows={detailData}
          getRowId={(row: getDetailCodeResponseDto) => row.CD_DTL_ID}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
          hideFooterSelectedRowCount
          slots={{
            noRowsOverlay: TableNoRowsOverlay,
          }}
        />
      )}

      <AddDetailCodeModal
        cid={CD_ID}
        isOpen={isOpenAdd}
        onSubmit={() => {
          refetch();
          setIsOpenAdd(false);
        }}
        onClose={() => setIsOpenAdd(false)}
      />
      {updateRequestDto !== undefined && (
        <EditDetailCodeModal
          isOpen={isOpenEdit}
          key={"isOpenEdit"}
          onSubmit={() => {
            refetch();
            setIsOpenEdit(false);
          }}
          onClose={() => setIsOpenEdit(false)}
          updateDetailCodeRequestDto={updateRequestDto}
        />
      )}
    </Stack>
  );
};

export default DetailCodeTable;
