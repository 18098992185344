import { customAxios } from "../../config";
import { getJwtState } from "../../utils/jwtState";

export const getMyInfo = async () => {
  const { userId } = getJwtState();
  return customAxios.get(`/user/spec`, {
    params: {
      USER_ID: userId,
    },
  });
};

export const getDetailCode = async (CD_ID :string) => {
    return customAxios.get(`/code/detail?CD_ID=${CD_ID}`);
}