import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createCallRequestDto, deleteCallDto, getCallRequestDto, getCallResponseDto, updateCallDto } from "./types";
import { QUERY_KEY } from "../../constants/constant";
import { callApi } from "./callAxios";
import { popupAlert } from "../../utils/popupAlert";

export const useGetCallList = (data: getCallRequestDto) => {
  return useQuery<getCallResponseDto[], Error>({
    queryKey: [QUERY_KEY.call, data.SALESPERSON_ID],
    queryFn: () => callApi.getCall(data),
  });
};

export const useCreateCall = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, createCallRequestDto>({
    mutationFn: (data: createCallRequestDto) => callApi.createCall(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.call] });
    },
    onError: (error) => {
      popupAlert("error", "콜시트 생성에 실패하였습니다. 잠시 후 다시 시도해주세요.");
    },
  });
};

export const useUpdateCall = () => {
  const queryClient = useQueryClient();
  return useMutation<updateCallDto, Error, updateCallDto>({
    mutationFn: (data: updateCallDto) => callApi.updateCall(data),
    onSuccess: (data: updateCallDto) => {
      // queryClient.setQueryData([QUERY_KEY.call, data.SALESPERSON_ID], (oldData: getCallResponseDto[] | undefined) => {
      //   return oldData ? oldData.map((call) => (call.CALL_ID === data.CALL_ID ? { ...call, ...data } : call)) : [];
      // });
    },
    onError: (error) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.call] });
      popupAlert("error", "콜시트 수정에 실패하였습니다. 잠시 후 다시 시도해주세요.");
      throw new Error("콜시트 수정 실패");
    },
  });
};

export const useDeleteCall = () => {
  const queryClient = useQueryClient();
  return useMutation<deleteCallDto, Error, deleteCallDto>({
    mutationFn: (data: deleteCallDto) => callApi.deleteCall(data),
    onSuccess: (data: deleteCallDto) => {
      queryClient.setQueryData([QUERY_KEY.call, data.SALESPERSON_ID], (oldData: getCallResponseDto[] | undefined) => {
        return oldData ? oldData.filter((call) => call.CALL_ID !== data.CALL_ID) : [];
      });
    },
  });
};
