import AccountInfoPage from "../pages/common/AccountInfoPage";
import Organization from "../pages/common/Organization";
import Code from "../pages/management/Code";
import Menu from "../pages/management/Menu";
import Role from "../pages/management/Role";
import BlackList from "../pages/nav1/call/BlackList";
import CallList from "../pages/nav1/call/CallList";
import TotalCallList from "../pages/nav1/call/TotalCallList";
import LiveRequestList from "../pages/nav1/live/LiveRequestList";
import TotalLiveRequestList from "../pages/nav1/live/TotalLiveRequestList";
import SalesListPage from "../pages/nav1/sales/SalesListPage";
import TotalSalesList from "../pages/nav1/sales/TotalSalesList";
import SettlePage from "../pages/nav2/tsv/SettlePage";
import TsvListPage from "../pages/nav2/tsv/TsvListPage";
import ContentImageListPage from "../pages/nav3/content/ContentImageListPage";
import ContentStatsPage from "../pages/nav3/content/ContentStatsPage";
import ContentVideoListPage from "../pages/nav3/content/ContentVideoListPage";
import ADProgressList from "../pages/nav4/ad-progress/ADProgressList";
import SalesReportPage from "../pages/nav5/SalesReportPage";

export type SideNavItemType = {
  title: string;
  element: JSX.Element;
  link: string;
};

export const NAV_ITEMS: { [key: string]: SideNavItemType[] } = {
  공통: [
    {
      title: "조직도",
      element: <Organization />,
      link: "organization",
    },
    {
      title: "계정 정보",
      element: <AccountInfoPage />,
      link: "account-info",
    },
  ],
  영업: [
    {
      title: "블랙 리스트",
      element: <BlackList />,
      link: "black-list",
    },
    {
      title: "콜시트",
      element: <CallList />,
      link: "call-list",
    },
    {
      title: "통합 콜시트",
      element: <TotalCallList />,
      link: "total-call-list",
    },
    {
      title: "대기리스트",
      element: <SalesListPage />,
      link: "sales-page",
    },
    {
      title: "통합 대기리스트",
      element: <TotalSalesList />,
      link: "total-sales-list",
    },
    {
      title: "라이브요청 리스트",
      element: <LiveRequestList />,
      link: "live-request-list",
    },
    {
      title: "통합 라이브요청 리스트",
      element: <TotalLiveRequestList />,
      link: "total-live-request-list",
    },
  ],
  취급고: [
    {
      title: "취급고 리스트",
      element: <TsvListPage />,
      link: "tsv-list",
    },
    {
      title: "정산",
      element: <SettlePage />,
      link: "settle-list",
    },
  ],
  "소재 현황": [
    {
      title: "이미지 소재 리스트",
      element: <ContentImageListPage />,
      link: "content-image-list",
    },
    {
      title: "영상 소재 리스트",
      element: <ContentVideoListPage />,
      link: "content-video-list",
    },
    {
      title: "소재 통계 페이지",
      element: <ContentStatsPage />,
      link: "content-stats-page",
    },
  ],
  보고서: [
    {
      title: "영업팀 보고서",
      element: <SalesReportPage />,
      link: "sales-report-page",
    },
  ],
  통계: [
    {
      title: "광고 진행 리스트",
      element: <ADProgressList />,
      link: "ad-progress-list",
    },
  ],
  관리자: [
    {
      title: "코드 관리",
      element: <Code />,
      link: "code",
    },
    {
      title: "메뉴 관리",
      element: <Menu />,
      link: "menu",
    },
    {
      title: "권한 관리",
      element: <Role />,
      link: "role",
    },
  ],
};
