import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { transformColor } from "../../../utils/commonUtil";

export type SelectFormData = {
  value: string;
  label: string;
};

interface SelectFormProps {
  id?: string;
  options: SelectFormData[];
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  // variant: string;
  handleChange: (event: SelectChangeEvent) => void;
}

const SelectForm: React.FC<SelectFormProps> = ({
  id = "",
  label,
  value,
  handleChange,
  options,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      sx={{ ...sx, backgroundColor: "white" }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || ""}
        variant={variant}
        onChange={handleChange}
      >
        {options.map((item, i) => (
          <MenuItem
            key={i}
            value={item.value}
            sx={{
              backgroundColor: transformColor(item.value),
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectForm;
