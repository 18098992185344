import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { updateUserPasswordDto } from "../../../../../service/user/types";


interface UpdateUserPwdModalProps {
  open: boolean;
  userId: string;
  userName: string;
  handleClose: () => void;
  handleSubmit: (data: any) => void;
}

const UpdateUserPwdModal: React.FC<UpdateUserPwdModalProps> = ({
  userId,
  open,
  userName,
  handleClose,
  handleSubmit,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userInfo, setUserInfo] = useState<updateUserPasswordDto>({
    userId,
    userPassword: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: value,
    });
  };
  const handleUpdateUserInfo = () => {
    handleSubmit(userInfo);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" fontWeight="bold">
          [{userName}] 비밀번호 변경
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ width: "300px", mb: 4 }}
        >
          <Typography variant="subtitle2">
            변경할 비밀번호를 입력해주세요.
          </Typography>
        </DialogContentText>
        <Stack gap={3} sx={{ my: 7 }}>
          <TextField
            label="비밀번호"
            id="userPassword"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
            placeholder="비밀번호를 입력해주세요."
            onChange={handleInputChange}
            value={userInfo.userPassword}
          />
        </Stack>
        <Stack direction={"row"} gap={1}>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            취소
          </Button>
          <Button
            onClick={handleUpdateUserInfo}
            autoFocus
            variant="contained"
            fullWidth
          >
            등록
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUserPwdModal;

// const boxStyle = {
//   border: "1px solid #e0e0e0",
//   height: "50px",
//   width: "30px",
//   borderRadius: "8px",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   fontSize: "1.2rem",
// };
