import CallTable from "./CallTable";
import { Container } from "../../../components/shared/styled-css/common-css";

export default function CallList() {
  return (
    <Container>
      <CallTable />
    </Container>
  );
}
