import { formatDate } from "../../utils/dateUtil";

export function camelToSnake(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((v) => camelToSnake(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce((result: any, key: string) => {
        const snakeKey = key.replace(
          /[A-Z]/g,
          (matches) => "_" + matches.toLowerCase()
        ).toUpperCase(); // 스네이크 케이스 + 대문자로 변경
        let value = obj[key];
  
        if (!value && typeof value === "object") {
          value = camelToSnake(value);
        } else if (
          ["CREATED_AT", "UPDATED_AT", "DELETED_AT","LAST_LOGIN_AT","AD_START_DATE","DATE"].includes(key)
        ) {
          result[snakeKey] = formatDate(obj[key]);
        } else {
          result[snakeKey] = obj[key];
        }
        return result;
      }, {});
    }
    return obj;
  }
   