import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponseHeaders,
} from "axios";

export interface HttpResponse<T = any, D = any> {
  data: T;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

export interface HttpError<T = any, D = any> extends Error {
  config: AxiosRequestConfig<D>;
  code?: string;
  request?: any;
  response?: HttpResponse<T, D>;
  isAxiosError: boolean;
  toJSON: () => object;
}

export interface HttpErrorResponseType {
  error: {
    message: string;
  };
}

export const isAxiosError = <HttpErrorResponseType>(
  error: unknown
): error is AxiosError<HttpErrorResponseType> => {
  return axios.isAxiosError(error);
};
