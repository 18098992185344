import { toast, ToastOptions } from "react-toastify";
type toastType = "success" | "error" | "info" | "warn";

export const toastOptions: ToastOptions = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export const popupAlert = (type: toastType, message: any) => {
  switch (type) {
    case "success":
      toast.success(message,toastOptions);
      break;
    case "error":
      toast.error(message,toastOptions);
      break;
    case "warn":
      toast.warn(message, toastOptions);
      break;
    default:
      toast.info(message, toastOptions);
      break;
  }
};