import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import { toast } from "react-toastify";
import styled from "styled-components";
import { toastOptions } from "../../utils/popupAlert";

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Message = styled.p`
  color: #000000;
  font-family: malgun;
  font-weight: 400;
  font-size: 15px;
`;

interface CustomToastProps {
  msg: string;
  type: CustomAlertType;
}

type CustomAlertType = "success" | "error" | "info" | "warn";

export const popupCustomAlert = (
  type: CustomAlertType,
  message: string,
  toastId = ""
) => {
  if (toastId === "") {
    toast(<CustomToast type={type} msg={message} />, {
      ...toastOptions,
    });
  } else {
    toast(<CustomToast type={type} msg={message} />, {
      ...toastOptions,
      toastId,
    });
  }
};

const getIcon = (type: CustomAlertType) => {
  if (type === "warn") {
    return (
      <NotificationImportantOutlinedIcon
        style={{
          color: "#f57119",
        }}
      />
    );
  } else if (type === "error") {
    return (
      <ErrorOutlineIcon
        style={{
          color: "#f84d4d",
        }}
      />
    );
  } else if (type === "success") {
    return (
      <CheckCircleOutlineOutlinedIcon
        style={{
          color: "#3f56bb",
        }}
      />
    );
  } else if (type === "info") {
    return (
      <NotificationImportantOutlinedIcon
        style={{
          color: "#626be7",
        }}
      />
    );
  }

  return (
    <CheckCircleOutlineOutlinedIcon
      style={{
        color: "#3f56bb",
      }}
    />
  );
};

export const CustomToast = ({ type, msg }: CustomToastProps) => {
  // return <ToastContainer />
  return (
    <Container>
      <IconWrap>{getIcon(type)}</IconWrap>
      <Message>{msg}</Message>
    </Container>
  );
};
