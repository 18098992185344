//불러오기
export const getJwtState = () => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN") || "";
  const refreshToken = localStorage.getItem("REFRESH_TOKEN") || "";
  const userId = localStorage.getItem("USER_ID") || "";
  const deptId = localStorage.getItem("DEPT_ID") || "";
  return { accessToken, refreshToken, userId, deptId };
};

//저장하기
export const setJwtState = (data: { accessToken: string; refreshToken: string; userId: string, deptId: string }) => {
  localStorage.setItem("ACCESS_TOKEN", data.accessToken);
  localStorage.setItem("REFRESH_TOKEN", data.refreshToken);
  localStorage.setItem("USER_ID", data.userId);
  localStorage.setItem("DEPT_ID", data.deptId);
};

export const setAccessToken = (data: string) => {
  localStorage.setItem("ACCESS_TOKEN", data);
};

export const setRefreshToken = (data: string) => {
  localStorage.setItem("REFRESH_TOKEN", data);
};

//삭제하기
export const clearJwtState = () => {
  localStorage.removeItem("ACCESS_TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");
  localStorage.removeItem("USER_ID");
  localStorage.removeItem("DEPT_ID");
};
 