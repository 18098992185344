import { customAxios } from "../../config";
import {
  createMenuRequestDto,
  createMenuResponseDto,
  deleteMenuRequestDto,
  deleteMenuResponseDto,
  getMenuRequestDto,
  getMenuResponseDto,
  getMenuToRoleRequestDto,
  getMenuToRoleResponseDto,
  updateMenuRequestDto,
  updateMenuResponseDto,
  updateMenuToRoleRequestDto,
  updateMenuToRoleResponseDto,
} from "./types";

export const MenuAPI = {
  getMenu: async (
    data: getMenuRequestDto | undefined
  ): Promise<getMenuResponseDto[]> => {
    return await customAxios
      .get(`/menu?MENU_ID=${data}`)
      .then((res) => res.data);
  },
  getMenuToRole: async (
    data: getMenuToRoleRequestDto | undefined
  ): Promise<getMenuToRoleResponseDto[]> => {
    return await customAxios
      .get(`/menu/role?ROLE_ID=${data}`)
      .then((res) => res.data);
  },
  createMenu: async (
    data: createMenuRequestDto
  ): Promise<createMenuResponseDto> => {
    const body = data;
    return await customAxios.post("/menu", body).then((res) => res.data);
  },
  updateMenu: async (
    data: updateMenuRequestDto
  ): Promise<updateMenuResponseDto> => {
    const body = data;
    return await customAxios.put("/menu", body).then((res) => res.data);
  },
  updateMenuToRole: async (
    data: updateMenuToRoleRequestDto
  ): Promise<updateMenuToRoleResponseDto> => {
    const body = data;
    return await customAxios.put("/menu/role", body).then((res) => res.data);
  },
  deleteMenu: async (
    data: deleteMenuRequestDto
  ): Promise<deleteMenuResponseDto> => {    
    return await customAxios
      .delete(`/menu`, {
        data,
      })
      .then((res) => res.data);
  },

  getMyMenu:async():Promise<getMyMenuResponseDto[]>=>{
    return await customAxios.get("/menu/user/nav-menu").then((res)=>res.data)
  }
};


export type getMyMenuResponseDto = {
  MENU_ID: string;
  MENU_NM: string;
  MENU_TITLE: string;
}