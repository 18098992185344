import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DataGrid from "react-data-grid";
import RoleToUser from "../../components/admin/role/RoleToUser";
import DataTotalCount from "../../components/common/DataTotalCount";
import { autoRowHeight } from "../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../components/shared/lib/multiRowCell";
import { customAxios } from "../../config";
import { getDetailCode } from "../../service/common/api";
import { CodeDeptType } from "../../types/CodeType";
import { UserType } from "../../types/UserType";
import { errorHandler } from "../../utils/apiUtil";

const Role = () => {
  const [search, setSearch] = useState<string>("");
  const [depts, setDepts] = useState<CodeDeptType[]>([]);
  const [dept, setDept] = useState("");
  const [rows, setRows] = useState<UserType[]>([]);
  const [row, setRow] = useState<UserType>();

  const getRows = async () => {
    try {
      const response = await customAxios.get("/user/list", {
        params: {
          search,
          DEPT_ID: dept === "전체" ? "" : dept,
        },
      });

      const data: UserType[] = response.data;
      const instance: UserType[] = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].DEPT_ID === "C007Z") continue;
        instance.push(data[i]);
      }

      setRows(instance);
      if (data.length > 0) {
        setRow(data[0]);
      } else {
        setRow(undefined);
      }
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getDepartmentCode = async () => {
    try {
      const response = await getDetailCode("C007");
      const data: CodeDeptType[] = response.data;
      const instance: CodeDeptType[] = [];
      instance.push({
        CD_DTL_ID: "전체",
        CD_DTL_NM: "전체",
      });
      for (let i = 0; i < data.length; i++) {
        if (data[i].CD_DTL_ID === "C007Z") continue;
        instance.push(data[i]);
      }
      setDepts(instance);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getDepartmentCode();
  }, []);

  useEffect(() => {
    getRows();
  }, [dept]);

  const handleSelectedRow = (cell: any) => {
    if (cell.row === undefined) return;

    if (cell.rowIdx >= 0) {
      setRow(cell.row);
    }
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, [rows]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const handleDeptChange = (e: SelectChangeEvent) => {
    setDept(e.target.value);
  };

  const columns = [
    {
      key: "DEPT_NM",
      name: "부서",
      width: 200,
      editable: false,
      ...MultiRowCell(),
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "USER_ID",
      name: "아이디",
      resizable: true,
      width: 100,
      renderCell({ row, onRowChange }: any) {
        return <div>{row.USER_ID}</div>;
      },
    },
    {
      key: "USER_NAME",
      name: "이름",
      width: 200,
      ...MultiRowCell(),
      WidthFull: true,
    },
    {
      key: "USER_CODE",
      name: "별칭",
      width: 200,
      ...MultiRowCell(),
      WidthFull: true,
    },
    {
      key: "POSITION",
      name: "직급",
      ...MultiRowCell(),
    },
  ];

  return (
    <Stack flex={1} gap={2}>
      <Stack flexDirection={"row"} gap={1}>
        <FormControl
          size={"small"}
          sx={{ backgroundColor: "white", width: 200 }}
        >
          <InputLabel id="demo-simple-select-label">{"부서"}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id={"deptSelectId"}
            value={dept}
            label={"asd"}
            variant={"outlined"}
            onChange={handleDeptChange}
          >
            {depts.map((item, i) => (
              <MenuItem key={item.CD_DTL_ID} value={item.CD_DTL_ID}>
                {item.CD_DTL_NM}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          type="search"
          size="small"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          onClick={() => {
            getRows();
          }}
        >
          조회
        </Button>
      </Stack>
      <Stack flex={1}>
        <DataGrid
          style={{ height:'30vh' }}
          className="rdg-light"
          rowHeight={autoRowHeight}
          onSelectedCellChange={handleSelectedRow}
          columns={columns}
          rows={rows}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
        />
      </Stack>
      <div
        style={{ flex: 2, gap: 10, display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            paddingTop: 15,
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              marginRight: 10,
            }}
          >
            사용자 권한 설정
          </span>
          {row !== undefined && (
            <>
              <Chip
                size={"small"}
                variant="outlined"
                color="primary"
                label={row.DEPT_NM}
                sx={{
                  letterSpacing: 1,
                }}
              />
              <Chip
                size={"small"}
                variant="outlined"
                color="primary"
                label={`${row.USER_NAME} (${row.USER_ID})`}
                sx={{
                  borderRadius: 0,
                  p: 2,
                  letterSpacing: 1,
                  borderWidth: 0,
                  color: "black",
                }}
              />
            </>
          )}
        </div>
        {row === undefined ? <>not Data...</> : <RoleToUser data={row} />}
      </div>
    </Stack>
  );
};

export default Role;
