import axios from "axios";
import { SERVER_URL } from "../../constants/constant";
import { loginUserRequestDto, loginUserResponseDto } from "./types";
import { snakeToCamel } from "../lib/snakeToCamel";
import { camelToSnake } from "../lib/camelToSnake";
import { clearJwtState, getJwtState, setAccessToken, setRefreshToken } from "../../utils/jwtState";

export const LoginAPI = {
  loginUser: async (data: loginUserRequestDto): Promise<loginUserResponseDto> => {
    const body = camelToSnake(data);
    return await axios.post(`${SERVER_URL}/auth/login`, body).then((res) => {
      return { ...snakeToCamel(res.data), userId: data.userId };
    });
  },

  refreshToken: async () => {
    const { refreshToken } = getJwtState();
    console.log(refreshToken)
    const headers = {
      "Content-Type": "application/json", // 필요한 경우 다른 헤더도 추가하세요
      Authorization: refreshToken, // 예시로 Authorization 헤더 추가
    };
    return await axios
      .post(`${SERVER_URL}/auth/refresh`,{}, { headers })
      .then((res) => {
        const { ACCESS_TOKEN, REFRESH_TOKEN } = res.data;
        setAccessToken(ACCESS_TOKEN);
        if (REFRESH_TOKEN) setRefreshToken(REFRESH_TOKEN);
      })
      .catch(() => {
        console.log("토큰 갱신 실패");
        clearJwtState();
        // window.location.href = "/login";
      });
  },
};
