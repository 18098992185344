import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/image/logo.png";
import { MIN_WIDTH, TOPNAV_HEIGHT } from "../../../constants/constant";
import { getMyInfo } from "../../../service/common/api";
import { useLogout } from "../../../service/login/loginHooks";
import { UserType } from "../../../types/UserType";
import { errorHandler } from "../../../utils/apiUtil";

const Wrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const Text = styled.span`
  font-family: malgun;
  font-size: 14px;
`;

const TopNav = () => {
  const logout = useLogout();
  const [userInfo, setUserInfo] = useState<UserType>();

  const getUserInfo = async () => {
    try {
      const response = await getMyInfo();
      const data: UserType = response.data;
      setUserInfo(data);
    } catch (e: unknown) {
      errorHandler(e);
    } finally {
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems={"center"}>
        <Box component="img" src={logo} alt={"logo"} height={30} />
        <Typography
          sx={{ ml: 3 }}
          fontSize={30}
          color="text.primary"
          fontWeight={"bold"}
        >
          CRM
        </Typography>
      </Stack>
      <Stack direction={"row"} gap={3} alignItems={"center"}>
        {!userInfo ? (
          <Typography>로딩중...</Typography>
        ) : (
          <Wrapper>
            <Text> {userInfo.USER_NAME}</Text>
            <Text> ({userInfo.USER_ID})</Text>
          </Wrapper>
        )}
        <Button
          sx={{
            color: "black",
            borderColor: "black",
            width:80,
            fontSize:14,
            letterSpacing:0,
            fontFamily: "malgun",
            "&:hover": {
              borderColor: "black",
              // backgroundColor: "black",
              backgroundImage: "linear-gradient(45deg, #424242, #2c2c2c)",
              color: "white",
              fontFamily: "malgun",
            },
          }}
          onClick={logout}
          variant="outlined"
          size="small"
        >
          로그아웃
        </Button>
      </Stack>
    </Container>
  );
};

export default TopNav;

const Container = styled.div`
  z-index: 100;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  position: fixed;
  min-width: ${MIN_WIDTH};
  height: ${TOPNAV_HEIGHT};
  box-sizing: border-box;
  border-bottom: 0.5px solid #999999;
`;
