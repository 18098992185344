import ClearIcon from "@mui/icons-material/Clear";
import { Dialog } from "@mui/material";
import { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import DataTotalCount, { DataCount } from "../../../../components/common/DataTotalCount";
import { getColumnWidth } from "../../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../../components/shared/lib/autoRowHeignt";
import { CallButton } from "../../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../../config";
import { ContentStatsItemType } from "../../../../types/ContentType";
import { errorHandler } from "../../../../utils/apiUtil";
import {
  getContentStateColor,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../../utils/commonUtil";

export type ContentSpecType = "marketer" | "designer" | "creator";
export type ContentSpecOpenType = {
  type: ContentSpecType;
  USER_ID: string;
  USER_NAME: string;
  sDate: Dayjs;
  eDate: Dayjs;
  cState: string;
};

interface ContentModalProps {
  open: ContentSpecOpenType;
  onSubmit: () => void;
}

const ContentSpecModal: React.FC<ContentModalProps> = ({ open, onSubmit }) => {
  const WIDTH_KEY_NAME = "content_spec_modal";
  const storedData = localStorage.getItem(WIDTH_KEY_NAME);
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  const [rows, setRows] = useState<ContentStatsItemType[]>([]);

  const getRows = async () => {
    try {
      const response = await customAxios.get("/content/stats/item/spec", {
        params: {
          type: open.type,
          USER_ID: open.USER_ID,
          sDate: open.sDate.format("YYYYMMDD"),
          eDate: open.eDate.format("YYYYMMDD"),
          CONTENT_STATE_CD: open.cState === "전체" ? "" : open.cState,
        },
      });

      const data: ContentStatsItemType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, [open]);

  const handleClose = () => {
    onSubmit();
  };

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const formatDateString = (date: string) => {
    if (date.length === 6) {
      return date.slice(0, 4) + "년 " + date.slice(4, 6) + "월";
    }
    return (
      date.slice(0, 4) +
      "년 " +
      date.slice(4, 6) +
      "월 " +
      date.slice(6, 8) +
      "일"
    );
  };

  const getTitle = () => {
    let title = "";

    if (open.type === "marketer") {
      title = "[마케터] ";
    } else if (open.type === "designer") {
      title = "[디자이너] ";
    } else if (open.type === "creator") {
      title = "[영상] ";
    }

    title += open.USER_NAME;

    return title;
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const columns: ColumnOrColumnGroup<ContentStatsItemType, any>[] = [
    {
      key: "SEQ",
      name: "No",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "SEQ", 70),
    },
    {
      key: "ENT_NM",
      name: "사업자명",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "ENT_NM", 70),
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "B_NAME",
      name: "브랜드명",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "B_NAME", 70),
    },
    {
      key: "MEDIA",
      name: "매체",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "MEDIA", 70),
      renderCell({ row }: any) {
        return (
          <div style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}>
            {row.MEDIA}
          </div>
        );
      },
    },
    {
      key: "CONTENT_DATE",
      name: "소재 날짜",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_DATE", 70),
      renderCell({ row, onRowChange }: any) {
        return (
          <div className="text-center grid-cell-font">
            {formatDateString(row.CONTENT_DATE)}
          </div>
        );
      },
    },
    {
      key: "CONTENT_DIV_NM",
      name: "구분",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_DIV_NM", 70),
    },
    {
      key: "CONTENT_STATE_NM",
      name: "상태",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_STATE_NM", 70),
      renderCell({ row }: { row: ContentStatsItemType }) {
        return (
          <div
            className="text-center grid-cell-font"
            style={{
              color: getContentStateColor(row.CONTENT_STATE_CD),
            }}
          >
            {row.CONTENT_STATE_NM}
          </div>
        );
      },
    },
    {
      key: "CONTENT_COUNT",
      name: "소재 개수",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      summaryCellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_COUNT", 70),
      renderSummaryCell({ row }: any) {
        return (
          <DataCount unitText="개"
            totalCount={rows.reduce((sum, item) => sum + item.CONTENT_COUNT, 0)}
          />
        );
      },
    },
    {
      key: "AD_SPACE",
      name: "지면",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "AD_SPACE", 70),
    },
    {
      key: "USP",
      name: "소구점",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "USP", 70),
    },
    {
      key: "AD_COPY",
      name: "카피",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "AD_COPY", 70),
    },
    {
      key: "MEMO",
      name: "특이사항",
      headerCellClass: "text-center grid-header-font",
      cellClass: "notes-cell",
      width: getColumnWidth(widths, "MEMO", 250),
      editable: false,
      renderCell({ row }: any) {
        return <pre className="grid-cell-font">{row.MEMO}</pre>;
      },
    },
    {
      key: "REQUEST_NM",
      name: "마케터",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "REQUEST_NM", 70),
    },
    {
      key: "MANAGER_NM",
      name: "제작자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "MANAGER_NM", 70),
    },
    {
      key: "NAS_PATH",
      name: "나스 경로",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "NAS_PATH", 70),
    },
  ];

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
    >
      <div
        id="modal-container"
        style={{
          padding: "10px 12px",
          minHeight: "605px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              fontFamily: "malgun",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontFamily: "malgun",
              }}
            >
              {getTitle()}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "malgun",
              }}
            >
              {open.sDate.format("YYYY-MM-DD")} {" ~ "}
              {open.eDate.format("YYYY-MM-DD")}
            </span>
          </div>
          <CallButton onClick={handleClose}>
            <ClearIcon sx={{ color: "#DCDCDC" }} />
          </CallButton>
        </div>
        <DataGrid
          className="rdg-light"
          style={{ flex: 1, minHeight: "300px", maxHeight: "600px" }}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          rowHeight={autoRowHeight}
          rows={rows}
          columns={columns}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            resizable: true,
            sortable: true,
          }}
          onColumnResize={(idx, width) =>
            onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
          }
          onCopy={handleCopy}
        />
      </div>
    </Dialog>
  );
};

export default ContentSpecModal;
