import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type IsLoginType = {
  isLogin: boolean;
};

type IsLoginActions = {
  setIsLogin: () => void;
  clearIsLogin: () => void;
};

const isLoginState = create(
  persist<IsLoginType & IsLoginActions>(
    (set, get) => ({
      isLogin: false,
      setIsLogin: () => {
        set({
          isLogin: true,
        });
      },
      clearIsLogin: () => {
        set({
          isLogin: false,
        });
      },
    }),
    {
      name: "IS_LOGIN_STATE",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

//실질적으로 사용되는 것
export const useIsLoginState = () => {
  const { isLogin, setIsLogin, clearIsLogin } = isLoginState((state) => state);
  return { isLogin, setIsLogin, clearIsLogin };
};
