import { RenderEditCellProps } from "react-data-grid";
import { useGetDetailCode } from "../../../service/code/codeHooks";
import { OPTION_CODE } from "../../../constants/constant";
import { CellSelect } from "./CellSelect";
import { EntGetType } from "../../../types/EntType";
import { useEffect, useState } from "react";
import { CodeDetailType } from "../../../types/CodeType";
import { getDetailCode } from "../../../service/common/api";
import { errorHandler } from "../../../utils/apiUtil";

export function MediaSelect({
  row,
  onRowChange,
}: RenderEditCellProps<EntGetType>) {
  const [data, setData] = useState<CodeDetailType[]>([]);
  // const { data } = useGetDetailCode(OPTION_CODE.배너광고매체);

  const getCode = async (CD_ID: string) => {
    try {
      const response = await getDetailCode(CD_ID);
      const data: CodeDetailType[] = response.data;
      setData(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    // 국내면 C017  해외 018  검색광고 019
    console.log(row.TYPE);
    
    if (row.TYPE === "domestic") {
      getCode("C017");
    } else if (row.TYPE === "overseas") {
      getCode("C018");
    } else if (row.TYPE === "search") {
      getCode("C019");
    }
  }, [row]);

  return (
    <>
      <CellSelect
        value={row.MEDIA}
        onChange={(event) =>
          onRowChange({ ...row, MEDIA: event.target.value }, true)
        }
        autoFocus
      >
        <option value={""}>{"선택안함"}</option>
        {data &&
          data.map((item, i) => (
            <option key={i} value={item.CD_DTL_NM}>
              {item.CD_DTL_NM}
            </option>
          ))}
      </CellSelect>
    </>
  );
}
