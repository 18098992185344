import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { getDetailCodeResponseDto } from "../../../service/code/types";


interface SelectFieldProps {
  id?: string;
  options: getDetailCodeResponseDto[] | undefined;
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  // variant: string;
  handleChange: (event: SelectChangeEvent) => void | any;
}

const SelectField: React.FC<SelectFieldProps> = ({
  id = "",
  label = "",
  value,
  handleChange,
  options,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
}) => {
  return (
    <FormControl fullWidth={fullWidth} size={size} sx={sx}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        // displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || null}
        variant={variant}
        onChange={handleChange}
      >
       
        {!!options &&
          options.map((item, i) => (
            <MenuItem key={i} value={item.CD_DTL_ID}>
              {item.CD_DTL_NM}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
