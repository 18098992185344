import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createCustomerRequestDto,
  createCustomerResponseDto,
  deleteCustomerDto,
  getCustomerNameFinderDto,
  getManagementCustomerCountListDto,
  getSalesListBySalespersonIdRequestDto,
  getSalesListBySalespersonIdResponseDto,
  updateCustomerAdEndDateDto,
  updateCustomerAdStartDateDto,
  updateCustomerNotesDto,
  updateCustomerRequestDto,
  updateCustomerResponseDto,
  updateCustomerStateDto,
  updateCustomerTransferDateDto,
  updateSalesPersonIdByCustomerIdDto,
} from "./types";
import { CustomerAPI } from "./customerAxios";
import { useCreatedCustomerIdState } from "../../store/customerIdState";
import { QUERY_KEY } from "../../constants/constant";
import { popupAlert } from "../../utils/popupAlert";

export const useCreateCustomer = () => {
  const { setCustomerId } = useCreatedCustomerIdState();
  return useMutation<createCustomerResponseDto, Error, createCustomerRequestDto>({
    mutationFn: (data: createCustomerRequestDto) => CustomerAPI.createCustomer(data),
    onSuccess: (data) => {
      setCustomerId(data.insertId);
    },
    onError: (error: Error) => {
      popupAlert("error", error.message);
    },
  });
};

export const useUpdateCustomer = () => {
  return useMutation<updateCustomerResponseDto, Error, updateCustomerRequestDto>({
    mutationFn: (data: updateCustomerRequestDto) => CustomerAPI.updateCustomer(data),
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (error: Error) => {
      popupAlert("error", error.message);
    },
  });
};

export const useDeleteCustomer = () => {
  return useMutation<deleteCustomerDto, Error, deleteCustomerDto>({
    mutationFn: (data: deleteCustomerDto) => CustomerAPI.deleteCustomer(data),
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (error: Error) => {
      popupAlert("error", error.message);
    },
  });
};

export const useGetSalseListBySalespersonId = (data: getSalesListBySalespersonIdRequestDto) => {
  return useQuery<getSalesListBySalespersonIdResponseDto[], Error>({
    queryKey: [QUERY_KEY.salesList, data.salespersonId, data.customerState, data.type],
    queryFn: () => CustomerAPI.getSalesListByUserId(data),
  });
};

export const useUpdateCustomerState = (success?: (data?: any) => void, failed?: any) => {
  const queryClient = useQueryClient();
  return useMutation<updateCustomerStateDto, Error, updateCustomerStateDto>({
    mutationFn: (data: updateCustomerStateDto) => CustomerAPI.updateCustomerState(data),
    onSuccess: (data) => {
      //라이브요청 리스트 캐싱 삭제
      if (success) {
        success(data);
      } else {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.liveRequestList] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.salesList] });
      }
    },
    onError: (error: Error) => {
      if (failed) failed();
      popupAlert("error", error.message);
    },
  });
};

export const useUpdateAdStartDate = () => {
  return useMutation<updateCustomerAdStartDateDto, Error, updateCustomerAdStartDateDto>({
    mutationFn: (data: updateCustomerAdStartDateDto) => CustomerAPI.updateCustomerAdStartDate(data),
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (error: Error) => {
      popupAlert("error", error.message);
    },
  });
};

export const useUpdateAdEndDate = () => {
  return useMutation<updateCustomerAdEndDateDto, Error, updateCustomerAdEndDateDto>({
    mutationFn: (data: updateCustomerAdEndDateDto) => CustomerAPI.updateCustomerAdEndDate(data),
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (error: Error) => {
      popupAlert("error", "광고 종료일 수정 실패");
      return new Error();
    },
  });
};

export const useUpdateCustomerTransferDate = () => {
  return useMutation<updateCustomerTransferDateDto, Error, updateCustomerTransferDateDto>({
    mutationFn: (data: updateCustomerTransferDateDto) => CustomerAPI.updateCustomerTransferDate(data),
    onSuccess: (data) => {},
    onError: (error: Error) => {
      popupAlert("error", error.message);
      return new Error();
    },
  });
};

export const useGetManagementCustomerCountList = () => {
  return useQuery<getManagementCustomerCountListDto[], Error>({
    queryKey: [QUERY_KEY.managementCustomerCountList],
    queryFn: () => CustomerAPI.getManagementCustomerCountList(),
  });
};

export const useUpdateCustomerNotes = (func: (data: any) => void) => {
  return useMutation<updateCustomerNotesDto, Error, updateCustomerNotesDto>({
    mutationFn: (data: updateCustomerNotesDto) => CustomerAPI.updateCustomerNotes(data),
    onSuccess: (data) => {
      func(data);
    },
    onError: (error: Error) => {
      popupAlert("error", error.message);
    },
  });
};

export const useGetCustomerNameFinder = (isSimilar: (data: boolean) => void, setNameList: (data: string) => void) => {
  return useMutation<getCustomerNameFinderDto[], Error, getCustomerNameFinderDto>({
    mutationFn: (data: getCustomerNameFinderDto) => CustomerAPI.getCustomerNameFinder(data),
    onSuccess: (data) => {
      setNameList(data.map((item) => item.BUSINESS_NAME).join("\n"));
      if (!!data && data.length > 0) {
        isSimilar(true);
      } else {
        isSimilar(false);
      }
    },
  });
};

export const useUpdateSalesPersonIdByCustomerId = (success: () => void) => {
  return useMutation<updateSalesPersonIdByCustomerIdDto, Error, updateSalesPersonIdByCustomerIdDto>({
    mutationFn: (data: updateSalesPersonIdByCustomerIdDto) => CustomerAPI.updateSalesPersonIdByCustomerId(data),
    onSuccess: () => {
      success();
    },
    onError: () => {
      popupAlert("error", "영업담당자 변경 실패");
    },
  });
};
