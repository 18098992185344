import { Chip } from "@mui/material";
import { Dayjs } from "dayjs";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import styled from "styled-components";
import CustomCheckbox from "../../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../../components/common/DataTotalCount";
import { ProductSelect } from "../../../../components/shared/cell-renderers/ProductSelect";
import { getColumnWidth } from "../../../../components/shared/cell-renderers/WidthComponent";
import { customAxios } from "../../../../config";
import { ContentListTableHandle, TabType } from "../../../../types/CommonType";
import { ContentEntStatsGetType } from "../../../../types/ContentType";
import { errorHandler } from "../../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../../utils/commonUtil";
import { getJwtState } from "../../../../utils/jwtState";
import ContentRequestModal from "../ContentRequestModal";
import ContentStatsItem from "./ContentStatsItem";
import { formatDate } from "../../../../utils/dateUtil";

export const ContentDateButton = styled.button`
  flex: 1;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: right;
  cursor: pointer;
`;

export interface ListTableProps {
  type: TabType;
  search: string;
}

const ContentStatsEntDisplay = forwardRef<
  ContentListTableHandle,
  ListTableProps
>(({ type, search }: ListTableProps, ref) => {
  const { deptId } = getJwtState();
  const WIDTH_KEY_NAME = "content_state_ent_display";
  const storedData = localStorage.getItem(WIDTH_KEY_NAME);
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const gridRef = useRef<HTMLDivElement>(null);
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태
  const [rows, setRows] = useState<ContentEntStatsGetType[]>([]);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );
  const [openRequestModal, setOpenRequestModal] =
    useState<ContentEntStatsGetType>();

  const isManager = (dept: string) => {
    if (dept === "C007I" || dept === "C007Z" || dept === "C007Y") return true;

    return false;
  };

  const [show, setShow] = useState(isManager(deptId));

  const getRows = async () => {
    try {
      const response = await customAxios.get("/content/stats/ent", {
        params: {
          type,
          search,
          deptId,
        },
      });
      const data: ContentEntStatsGetType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useImperativeHandle(ref, () => ({
    triggerSearch: () => {
      getRows();
    },
    triggerDisplayStatsDashboard: () => {
      setShow(!show);
    },
    triggerExcelDownload: () => {},
    triggerRequestContent: () => {
      if (deptId !== "C007C") {
        popupCustomAlert("error", "마케팅팀_DA 소속이 아닙니다");
        return;
      }
      if (selectedRows.size !== 0) {
        const selectedIds = Array.from(selectedRows);
        const target = rows.find((v) => v.ENT_ID === selectedIds[0]);

        if (target === undefined) {
          popupCustomAlert("error", "시스템 담당자에게 문의해주세요");
          return;
        }

        setOpenRequestModal(target);
      } else {
        popupCustomAlert("info", "소재 요청할 업체를 선택해주세요");
      }
    },
  }));

  useEffect(() => {
    getRows();
  }, []);

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);
    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];
          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;
          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const summaryRows = useMemo((): any[] => {
    const result: {
      [key in string]: number;
    } = {};

    return [{}];
  }, []);

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<ContentEntStatsGetType, any>[] = [
    {
      key: "CHK",
      name: "선택",
      headerCellClass: "text-center",
      frozen: true,
      width: getColumnWidth(widths, "CHK", 70),
      renderHeaderCell: () => {
        return <>선택</>;
      },
      renderCell: ({ row }: { row: ContentEntStatsGetType }) => {
        const handleRowCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (event.target.checked) {
              updatedSelectedRows.clear();
              updatedSelectedRows.add(row.ENT_ID);
            } else {
              updatedSelectedRows.delete(row.ENT_ID);
            }
            return updatedSelectedRows;
          });
        };

        return (
          <CustomCheckbox
            checked={selectedRows.has(row.ENT_ID)}
            onChange={handleRowCheckboxChange}
          />
        );
      },
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataCount totalCount={selectedRows.size} />
          </div>
        );
      },
    },
    {
      key: "ENT_NM",
      name: "사업자명",
      frozen: true,
      resizable: true,
      width: getColumnWidth(widths, "ENT_NM", 120),
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "B_NAME",
      name: "브랜드명",
      frozen: true,
      resizable: true,
      width: getColumnWidth(widths, "B_NAME", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
    },
    {
      key: "CNT",
      name: "소재 개수",
      frozen: true,
      resizable: true,
      width: getColumnWidth(widths, "B_NAME", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      summaryCellClass: "text-center grid-cell-font",
      renderSummaryCell({ row }: any) {
        return (
          <DataCount
            unitText="개"
            totalCount={rows.reduce((sum, item) => sum + Number(item.CNT), 0)}
          />
        );
      },
    },
    {
      key: "MEDIA",
      name: "매체",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "MEDIA", 100),
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}>
            {row.MEDIA}
          </div>
        );
      },
      editable: false,
    },
    {
      key: "TEAM_ACCOUNT",
      name: "계정명",
      resizable: true,
      width: getColumnWidth(widths, "TEAM_ACCOUNT", 100),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
    },
    {
      key: "P_NAME",
      name: "상품",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      renderEditCell: ProductSelect,
      width: getColumnWidth(widths, "P_NAME", 100),
      editable: false,
    },
    {
      key: "LIVE",
      name: "라이브 상태",
      resizable: true,
      width: getColumnWidth(widths, "LIVE", 100),
      headerCellClass: "text-center grid-header-font",
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ textAlign: "center" }}>
            <Chip
              label={getLiveStateName(row.LIVE)}
              variant="outlined"
              size="small"
              sx={{
                color: getLiveStateColor(row.LIVE),
                borderColor: getLiveStateColor(row.LIVE),
              }}
            />
          </div>
        );
      },
    },
    {
      key: "LIVE_START_AT",
      name: "시작 일자",
      resizable: true,
      width: getColumnWidth(widths, "B_NAME", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ textAlign: "center" }}>
            {row.LIVE_START_AT && formatDate(row.LIVE_START_AT, true)}
          </div>
        );
      },
    },
    {
      key: "LIVE_END_AT",
      name: "종료 일자",
      resizable: true,
      width: getColumnWidth(widths, "B_NAME", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ textAlign: "center" }}>
            {row.LIVE_END_AT && formatDate(row.LIVE_END_AT, true)}
          </div>
        );
      },
    },
    {
      key: "SALESMAN_NM",
      name: "영업 담당자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "SALESMAN_NM", 100),
      renderCell({ row, onRowChange }: any) {
        return row.SALESMAN_EMPLOYMENT_STATUS === "EMPLOYED" ? (
          <div style={{ textAlign: "center" }}>{row.SALESMAN_NM}</div>
        ) : (
          <div style={{ textAlign: "center", color: "#fc5d5d" }}>
            <span title={`${row.SALESMAN_NM} (${row.SALESMAN_ID})`}>퇴사</span>
          </div>
        );
      },
    },
    {
      key: "MARKETER_NM",
      name: "마케터",
      resizable: true,
      width: getColumnWidth(widths, "MARKETER_NM", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
    },
    {
      key: "DESIGNER_NM",
      name: "디자이너",
      resizable: true,
      width: getColumnWidth(widths, "DESIGNER_NM", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
    },
    {
      key: "CREATOR_NM",
      name: "영상",
      resizable: true,
      width: getColumnWidth(widths, "CREATOR_NM", 80),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
    },
  ];

  return (
    <div className="flex flex-box flex-box-column" style={{ gap: 5 }}>
      {openRequestModal !== undefined && (
        <ContentRequestModal
          open={openRequestModal}
          onSubmit={() => {
            setOpenRequestModal(undefined);
            getRows();
          }}
        />
      )}
      <div
        style={{
          display: show ? "flex" : "none",
        }}
      >
        <ContentStatsItem rows={rows} />
      </div>
      {rows && (
        <div ref={gridRef}>
          <DataGrid
            className="rdg-light"
            style={{ height: show ? `52vh` : `84vh` }}
            sortColumns={sortColumns}
            onSortColumnsChange={handleSortColumnsChange}
            columns={columns}
            rows={rows}
            bottomSummaryRows={summaryRows}
            defaultColumnOptions={{
              resizable: true,
              sortable: true,
            }}
            selectedRows={selectedRows}
            onColumnResize={(idx, width) =>
              onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
            }
            onCopy={handleCopy}
          />
        </div>
      )}
    </div>
  );
});

export default ContentStatsEntDisplay;
