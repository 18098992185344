import ClearIcon from "@mui/icons-material/Clear";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useEffect, useState } from "react";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import { customAxios } from "../../../config";
import { getDetailCode } from "../../../service/common/api";
import { CodeDetailType } from "../../../types/CodeType";
import { EntCreateType, EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";

const initEntData: EntCreateType = {
  TYPE: "",
  ENT_NM: "",
  ENT_NUMBER: "",
  ENT_CONTACT_NAME: "",
  ENT_CONTACT_POSITION: "",
  ENT_CONTACT_NUMBER: "",
  ENT_CONTACT_EMAIL: "",
  ENT_NOTES: "",
  B_NAME: "",
  H_NAME: "",
  P_NAME: "",
  P_KIND: "",
  P_URLS: "",
  IS_CONV_TRACK: false,
  IS_TRANSFER_CHECK: false,
  IS_CONTRACT_SIGN: false,
  IS_TAX_INVOICE_ISSUED: false,
  IS_ACCOUNT_PERMISSION_GRANTED: false,
  IS_AD_CHARGE_REPLY_RECEIVED: false,
  MEDIA: "",
  D_ACCOUNT_NUMBER: "",
  D_ACCOUNT_NM: "",
  O_BUSINESS_ACCOUNT_NM: "",
  O_PROGRESS_METHOD: "",
  O_PAYER_NM: "",
  S_SEARCH_AD_ID: "",
  IS_MAIN_ACCOUNT_PERMISSION_CHECKED: false,
  O_AD_ACCOUNT_NM: "",
  TAX_INVOICE_EMAIL: "",
  S_PRODUCT: "",
};

interface Props {
  open: string;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function SalesEntAddPopup({ open, onSubmit, onCancel }: Props) {
  const [title, setTitle] = useState("");
  const [step, setStep] = useState(0);
  const [stepInfo] = useState<string[]>(["업체 정보", "광고 정보"]);
  const [ent, setEnt] = useState<EntCreateType>(initEntData);
  const [customHost, setCustomHost] = useState("");
  const [hostingOptions, setHostingOptions] = useState<CodeDetailType[]>([]);
  const [productOptions, setProductOptions] = useState<CodeDetailType[]>([]);
  const [adProductOptions, setAdProductOptions] = useState<CodeDetailType[]>(
    []
  );
  const [mediaOptions, setMediaOptions] = useState<CodeDetailType[]>([]);
  const [progessMethodOptions, setProgessMethodOptions] = useState<
    CodeDetailType[]
  >([]);
  const [entList, setEntList] = useState<EntGetType[]>([]);

  /**
   * API 호출
   */

  const fetchCodeDetails = async (
    codeId: string,
    setter: (data: CodeDetailType[]) => void
  ) => {
    try {
      const response = await getDetailCode(codeId);
      setter(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getEntList = async () => {
    try {
      const response = await customAxios.get("/ent/list");
      setEntList(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (open) {
      setStep(0);
      let MEDIA_CD_ID = "";

      let title = "업체 추가";
      if (open === "domestic") {
        // D
        title += " (국내매체광고)";
        MEDIA_CD_ID = "C017";
      } else if (open === "overseas") {
        // O
        title += " (해외매체광고)";
        MEDIA_CD_ID = "C018";
        fetchCodeDetails("C020", setProgessMethodOptions);
      } else if (open === "search") {
        // S
        title += " (검색광고)";
        MEDIA_CD_ID = "C019";
        fetchCodeDetails("C023", setAdProductOptions);
      }
      setTitle(title);
      setEnt({
        ...initEntData,
        ["TYPE"]: open,
      });
      setCustomHost("");
      fetchCodeDetails("C015", setHostingOptions);
      fetchCodeDetails("C016", setProductOptions);

      if (MEDIA_CD_ID !== "") {
        fetchCodeDetails(MEDIA_CD_ID, setMediaOptions);
      }

      getEntList();
    }
  }, [open]);

  /**
   * 컴포넌트 제어
   */
  const handleClickNext = async () => {
    if (step === 0) {
      if (
        entList.findIndex(
          (v) => v.B_NAME !== "" && v.B_NAME === ent.B_NAME && v.LIVE !== "end"
        ) > -1
      ) {
        popupCustomAlert("warn", `현재 내부에서 진행 중인 브랜드입니다.`);
        return;
      }

      if (ent.ENT_NM === "") {
        popupCustomAlert("warn", `사업자명을 입력해주세요`);
        return;
      } else if (ent.ENT_NUMBER === "") {
        popupCustomAlert("warn", `사업자등록번호를 입력해주세요`);
        return;
      } else if (ent.H_NAME === "기타 (직접입력)" && customHost === "") {
        popupCustomAlert("warn", `호스팅사 값은 필수입니다`);
        return;
      } else if (ent.B_NAME === "") {
        popupCustomAlert("warn", `브랜드명 값은 필수입니다`);
        return;
      } else if (ent.H_NAME === "") {
        popupCustomAlert("warn", `호스팅사 값은 필수입니다`);
        return;
      } else if (
        (open === "domestic" || open === "overseas") &&
        ent.P_NAME === ""
      ) {
        popupCustomAlert("warn", `상품명 값은 필수입니다`);
        return;
      } else if (
        open === "search" &&
        ent.S_PRODUCT === "" &&
        ent.P_NAME === ""
      ) {
        popupCustomAlert("warn", `광고 상품명 값은 필수입니다`);
        return;
      }

      setStep(1);
    } else if (step === 1) {
      // 유효성 검사

      let FINAL_H_NAME = ent.H_NAME;
      if (ent.H_NAME === "기타 (직접입력)") {
        FINAL_H_NAME = customHost;
      }

      if (ent.MEDIA === "") {
        popupCustomAlert("warn", `매체 값은 필수입니다`);
        return;
      }

      try {
        await customAxios.post("/ent", {
          ...ent,
          H_NAME: FINAL_H_NAME,
        });
        popupCustomAlert("success", `업체 추가 완료했습니다`);
        onSubmit();
        setStep(2);
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setEnt({
      ...ent,
      [id]: value,
    });
  };

  const handleInputCustomHostingChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = e.target;
    setCustomHost(value);
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setEnt({
      ...ent,
      [name]: checked,
    });
  };

  const handleSelectHostingChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setCustomHost(""); // Reset custom input when not "기타(직접입력)"
    setEnt({
      ...ent,
      ["H_NAME"]: value,
    });
  };

  const handleSelectProductChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setEnt({
      ...ent,
      ["P_NAME"]: value,
    });
  };

  const handleSelectADProductChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setEnt({
      ...ent,
      ["S_PRODUCT"]: value,
    });
  };

  const handleSelectMediaChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    setEnt({
      ...ent,
      ["MEDIA"]: value,
      ["O_PROGRESS_METHOD"]: "",
    });
  };

  const handleSelectProgessMethodChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setEnt({
      ...ent,
      ["O_PROGRESS_METHOD"]: value,
    });
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <Dialog
      open={open !== ""}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={step === 0 ? "md" : "sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={3}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Box alignContent={"center"}>
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>
          </Box>
          <Box>
            <Button variant="text" onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#DCDCDC" }} />
            </Button>
          </Box>
        </Stack>
        <Stack>
          <Stepper activeStep={step} alternativeLabel>
            {stepInfo.map((label: string) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        {step === 0 && (
          <Stack gap={3}>
            <Stack flexDirection={"row"} gap={2}>
              <TextField
                fullWidth
                label="사업자명"
                id="ENT_NM"
                placeholder="사업자명을 입력해주세요."
                onChange={handleInputChange}
                value={ent.ENT_NM}
                required
              />
              <TextField
                fullWidth
                label="사업자등록번호"
                id="ENT_NUMBER"
                placeholder="사업자등록번호를 입력해주세요."
                onChange={handleInputChange}
                value={ent.ENT_NUMBER}
                required
              />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              <TextField
                fullWidth
                label="업체담당자 성함"
                id="ENT_CONTACT_NAME"
                placeholder="업체담당자 성함을 입력해주세요."
                onChange={handleInputChange}
                value={ent.ENT_CONTACT_NAME}
              />
              <TextField
                fullWidth
                label="업체담당자 직책"
                id="ENT_CONTACT_POSITION"
                placeholder="업체담당자 직책을 입력해주세요."
                onChange={handleInputChange}
                value={ent.ENT_CONTACT_POSITION}
              />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              <TextField
                fullWidth
                label="업체담당자 연락처"
                id="ENT_CONTACT_NUMBER"
                placeholder="010-1234-1234"
                onChange={handleInputChange}
                value={ent.ENT_CONTACT_NUMBER}
              />
              <TextField
                fullWidth
                label="업체담당자 이메일"
                id="ENT_CONTACT_EMAIL"
                placeholder="example@example.com"
                onChange={handleInputChange}
                value={ent.ENT_CONTACT_EMAIL}
              />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              <TextField
                fullWidth
                label="특이사항"
                multiline
                rows={2}
                id="ENT_NOTES"
                onChange={handleInputChange}
                value={ent.ENT_NOTES}
              />
            </Stack>
            <Stack flexDirection={"row"} gap={2}>
              <TextField
                fullWidth
                label="브랜드명 *"
                id="B_NAME"
                onChange={handleInputChange}
                value={ent.B_NAME}
                helperText={
                  entList.findIndex(
                    (v) =>
                      v.B_NAME !== "" &&
                      v.B_NAME === ent.B_NAME &&
                      v.LIVE !== "end"
                  ) > -1
                    ? "현재 내부에서 진행 중인 브랜드입니다."
                    : null
                }
                error={
                  entList.findIndex(
                    (v) =>
                      v.B_NAME !== "" &&
                      v.B_NAME === ent.B_NAME &&
                      v.LIVE !== "end"
                  ) > -1
                    ? true
                    : false
                }
              />
              <FormControl fullWidth>
                <InputLabel id="H_NAME">호스팅사 *</InputLabel>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="H_NAME"
                  value={ent.H_NAME}
                  label="호스팅사"
                  onChange={handleSelectHostingChange}
                >
                  {!!hostingOptions &&
                    hostingOptions.map((item, i) => (
                      <MenuItem key={i} value={item.CD_DTL_NM}>
                        {item.CD_DTL_NM}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {ent.H_NAME === "기타 (직접입력)" && (
                <TextField
                  fullWidth
                  onChange={handleInputCustomHostingChange}
                  value={customHost}
                />
              )}
            </Stack>

            <Stack
              flexDirection={"row"}
              gap={2}
              sx={{
                display: open === "search" ? "none" : "block",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="P_NAME">상품명 *</InputLabel>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="P_NAME"
                  value={ent.P_NAME}
                  label="상품명"
                  onChange={handleSelectProductChange}
                >
                  {!!productOptions &&
                    productOptions.map((item, i) => (
                      <MenuItem key={i} value={item.CD_DTL_NM}>
                        {item.CD_DTL_NM}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="제품 종류 (사용하지 않음)"
                id="P_KIND"
                onChange={handleInputChange}
                value={ent.P_KIND}
                sx={{
                  display: "none",
                }}
              />
            </Stack>
            <Stack
              flexDirection={"row"}
              gap={2}
              sx={{
                display: open === "search" ? "block" : "none",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="S_PRODUCT">광고 상품명</InputLabel>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="S_PRODUCT"
                  value={ent.S_PRODUCT}
                  label="광고 상품명"
                  onChange={handleSelectADProductChange}
                >
                  {!!adProductOptions &&
                    adProductOptions.map((item, i) => (
                      <MenuItem key={i} value={item.CD_DTL_NM}>
                        {item.CD_DTL_NM}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack flexDirection={"row"}>
              <TextField
                fullWidth
                label="제품 URL"
                id="P_URLS"
                onChange={handleInputChange}
                value={ent.P_URLS}
              />
            </Stack>

            <Stack flexDirection={"row"} gap={2}>
              {open === "domestic" && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ent.IS_CONV_TRACK}
                        onChange={handleCheckedChange}
                        name="IS_CONV_TRACK"
                      />
                    }
                    label="전환 추적"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ent.IS_TRANSFER_CHECK}
                        onChange={handleCheckedChange}
                        name="IS_TRANSFER_CHECK"
                      />
                    }
                    label="이관 체크"
                  />
                </>
              )}

              {open === "overseas" && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="IS_CONTRACT_SIGN"
                        checked={ent.IS_CONTRACT_SIGN}
                        onChange={handleCheckedChange}
                      />
                    }
                    label="계약서 서명 여부"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="IS_TAX_INVOICE_ISSUED"
                        checked={ent.IS_TAX_INVOICE_ISSUED}
                        onChange={handleCheckedChange}
                      />
                    }
                    label="세금계산서 발행"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="IS_ACCOUNT_PERMISSION_GRANTED"
                        checked={ent.IS_ACCOUNT_PERMISSION_GRANTED}
                        onChange={handleCheckedChange}
                      />
                    }
                    label="계정 권한 부여"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="IS_AD_CHARGE_REPLY_RECEIVED"
                        checked={ent.IS_AD_CHARGE_REPLY_RECEIVED}
                        onChange={handleCheckedChange}
                      />
                    }
                    label="광고비 충전 회신"
                  />
                  {ent.IS_TAX_INVOICE_ISSUED === true && (
                    <>
                      <TextField
                        fullWidth
                        label="세금계산서 발행 이메일"
                        id="TAX_INVOICE_EMAIL"
                        onChange={handleInputChange}
                        value={ent.TAX_INVOICE_EMAIL}
                      />
                    </>
                  )}
                </div>
              )}

              {open === "search" && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ent.IS_CONV_TRACK}
                        onChange={handleCheckedChange}
                        name="IS_CONV_TRACK"
                      />
                    }
                    label="전환 추적"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ent.IS_TRANSFER_CHECK}
                        onChange={handleCheckedChange}
                        name="IS_TRANSFER_CHECK"
                      />
                    }
                    label="이관 체크"
                  />
                </>
              )}
            </Stack>
          </Stack>
        )}
        {step === 1 && (
          <Stack gap={3}>
            <FormControl fullWidth>
              <InputLabel id="MEDIA">매체</InputLabel>
              <Select
                inputProps={{ "aria-label": "Without label" }}
                labelId="MEDIA"
                value={ent.MEDIA}
                label="매체"
                onChange={handleSelectMediaChange}
              >
                {!!mediaOptions &&
                  mediaOptions.map((item, i) => (
                    <MenuItem key={i} value={item.CD_DTL_NM}>
                      {item.CD_DTL_NM}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {open === "domestic" && (
              <>
                <TextField
                  fullWidth
                  label="계정번호"
                  id="D_ACCOUNT_NUMBER"
                  onChange={handleInputChange}
                  value={ent.D_ACCOUNT_NUMBER}
                />
                <TextField
                  fullWidth
                  label="계정명"
                  id="D_ACCOUNT_NM"
                  onChange={handleInputChange}
                  value={ent.D_ACCOUNT_NM}
                />
              </>
            )}

            {open === "overseas" && (
              <>
                <TextField
                  fullWidth
                  label={
                    ent.MEDIA === "META"
                      ? "비즈니스 계정명"
                      : ent.MEDIA === "GOOGLE"
                      ? "CID 계정번호"
                      : "계정번호"
                  }
                  id="O_BUSINESS_ACCOUNT_NM"
                  onChange={handleInputChange}
                  value={ent.O_BUSINESS_ACCOUNT_NM}
                />
                <TextField
                  fullWidth
                  label={
                    ent.MEDIA === "META"
                      ? "광고 계정명"
                      : ent.MEDIA === "GOOGLE"
                      ? "광고 계정명"
                      : "계정명"
                  }
                  id="O_AD_ACCOUNT_NM"
                  onChange={handleInputChange}
                  value={ent.O_AD_ACCOUNT_NM}
                />
                <FormControl fullWidth>
                  <InputLabel id="O_PROGRESS_METHOD">진행 방식</InputLabel>
                  <Select
                    inputProps={{ "aria-label": "Without label" }}
                    labelId="O_PROGRESS_METHOD"
                    value={ent.O_PROGRESS_METHOD}
                    label="진행 방식"
                    onChange={handleSelectProgessMethodChange}
                  >
                    {progessMethodOptions.map((item, i) => (
                      <MenuItem key={i} value={item.CD_DTL_NM}>
                        {item.CD_DTL_NM}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="입금자명"
                  id="O_PAYER_NM"
                  onChange={handleInputChange}
                  value={ent.O_PAYER_NM}
                />
              </>
            )}

            {open === "search" && (
              <>
                <TextField
                  fullWidth
                  label="검색광고 아이디"
                  id="S_SEARCH_AD_ID"
                  onChange={handleInputChange}
                  value={ent.S_SEARCH_AD_ID}
                />
              </>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={ent.IS_MAIN_ACCOUNT_PERMISSION_CHECKED}
                  onChange={handleCheckedChange}
                  name="IS_MAIN_ACCOUNT_PERMISSION_CHECKED"
                />
              }
              label="대표 계정 권한 체크"
            />
          </Stack>
        )}

        {step === 2 && (
          <Stack
            justifyContent={"space-between"}
            sx={{ height: 200 }}
            alignItems={"center"}
          >
            <Stack
              alignItems="center"
              gap={4}
              sx={{ height: "100%" }}
              justifyContent={"center"}
            >
              <TaskAltIcon sx={{ fontSize: "40px" }} color="primary" />
              <Typography variant="h5" fontWeight="bold">
                등록 완료
              </Typography>
            </Stack>
          </Stack>
        )}

        <DialogActions sx={{ display: "flex" }}>
          {step === 0 && (
            <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
              닫기
            </Button>
          )}
          {step === 1 && (
            <Button
              variant="outlined"
              onClick={() => setStep(0)}
              startIcon={<NavigateBeforeIcon />}
              sx={{ flex: 1 }}
            >
              이전
            </Button>
          )}
          {step < 2 && (
            <Button
              variant="contained"
              onClick={handleClickNext}
              autoFocus
              sx={{ flex: 1 }}
              endIcon={step === 0 ? <NavigateNextIcon /> : <></>}
            >
              {step === 0 ? "다음" : "등록"}
            </Button>
          )}
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
