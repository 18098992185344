import { RenderEditCellProps } from "react-data-grid";
import { useGetDetailCode } from "../../../service/code/codeHooks";
import { OPTION_CODE } from "../../../constants/constant";
import { CellSelect } from "./CellSelect";

export function ADProductSelect({ row, onRowChange }: RenderEditCellProps<any>) {
  const { data } = useGetDetailCode("C023");

  return (
    <>
      <CellSelect
        value={row.S_PRODUCT}
        onChange={(event) => onRowChange({ ...row, S_PRODUCT: event.target.value }, true)}
        autoFocus
      >
        <option value={''}>
              {'선택안함'}
            </option>
        {data &&
          data.map((item, i) => (
            <option key={i} value={item.CD_DTL_NM}>
              {item.CD_DTL_NM}
            </option>
          ))}
      </CellSelect>
    </>
  );
}
