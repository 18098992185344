import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UserAPI } from "./userAxios";
import {
  createUserDto,
  deleteUserRequestDto,
  getUserInfoRequestDto,
  getUserInfoResponseDto,
  getUserListRequestDto,
  getUserListResponseDto,
  getUserRequestDto,
  getUserResponseDto,
  updateUserInfoDto,
  updateUserPasswordDto,
} from "./types"; 
import { QUERY_KEY } from "../../constants/constant";
import { popupCustomAlert } from "../../components/common/CustomToast";

export const useGetUser = (data: getUserRequestDto | undefined) => {
  return useQuery<getUserResponseDto[], Error, getUserResponseDto[]>({
    queryKey: [QUERY_KEY.code],
    queryFn: () => UserAPI.getRole(data),
  });
};

export const useGetUserInfo = (data: getUserInfoRequestDto) => {
  return useQuery<getUserInfoResponseDto, Error>({
    queryKey: [QUERY_KEY.userInfo, data.userId],
    queryFn: () => UserAPI.getUserInfo(data),
  });
};

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient();
  return useMutation<updateUserInfoDto, Error, updateUserInfoDto>({
    mutationFn: (data: updateUserInfoDto) => UserAPI.updateUserInfo(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.userList] }); // 캐시된 데이터 무효화
      popupCustomAlert("success", `${data.userName}님의 정보가 수정되었습니다.`);
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useUpdateUserPassword = () => {
  return useMutation<updateUserPasswordDto, Error, updateUserPasswordDto>({
    mutationFn: (data: updateUserPasswordDto) => UserAPI.updateUserPassword(data),
    onSuccess: (data) => {
      console.log(data);
      popupCustomAlert("success", `비밀번호가 수정되었습니다.`);
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<createUserDto, Error, createUserDto>({
    mutationFn: (data: createUserDto) => UserAPI.createUser(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.userList] }); // 캐시된 데이터 무효화
      popupCustomAlert("success", `${data.userName}님의 정보가 생성되었습니다.`);
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useGetUserList = (data: getUserListRequestDto) => {
  return useQuery<getUserListResponseDto[], Error>({
    queryKey: [QUERY_KEY.userList, data],
    queryFn: () => UserAPI.getUserList(data),
  });
};

export const useDeleteUser = (chashing: () => void) => {
  return useMutation<deleteUserRequestDto, Error, deleteUserRequestDto>({
    mutationFn: (data: deleteUserRequestDto) => UserAPI.deleteUser(data),
    onSuccess: () => {
      chashing();
    },
  });
};

// export const useCreateRole = (
//   onSubmit: () => void,
//   onError: (message: string) => void
// ) => {
//   return useMutation<createRoleResponseDto, Error, createRoleRequestDto>({
//     mutationFn: (data: createRoleRequestDto) => RoleAPI.createRole(data),
//     onSuccess: (data) => {
//       onSubmit();
//     },
// onError: (error: Error) => {
//   if (isAxiosError<HttpErrorResponseType>(error)) {
//     onError(error.response?.data.error.message || '');
//   } else {
//     onError("알 수 없는 오류");
//   }
// },
//   });
// };

// export const useUpdateRole = (
//   onSubmit: () => void,
//   onError: (message: string) => void
// ) => {
//   return useMutation<updateRoleResponseDto, Error, updateRoleRequestDto>({
//     mutationFn: (data: updateRoleRequestDto) => RoleAPI.updateRole(data),
//     onSuccess: (data) => {
//       onSubmit();
//     },
//   });
// };

// export const useDeleteRole = (onSucess: () => void) => {
//   return useMutation<deleteRoleResponseDto, Error, deleteRoleRequestDto>({
//     mutationFn: (data: deleteRoleRequestDto) => RoleAPI.deleteRole(data),
//     onSuccess: (data) => {
//       onSucess();
//     },
//   });
// };
