import axios, { AxiosError } from "axios";
import { popupCustomAlert } from "../components/common/CustomToast";

export const isAxiosError = <HttpErrorResponseType>(
  error: unknown
): error is AxiosError<HttpErrorResponseType> => {
  return axios.isAxiosError(error);
};

export interface HttpErrorResponseType {
  error: {
    message: string;
  }
}

export const errorHandler = (error: unknown) => {
  if (isAxiosError<HttpErrorResponseType>(error)) {
    const status = error.response?.status || 0;
    const message = error.response?.data.error.message || "" 
    
    if (status === 403) {
      popupCustomAlert("warn", "로그인 유효시간이 만료되었습니다", 'expiredSession');
    } else {
      popupCustomAlert("warn", message !== undefined ? `${message}` : '', `${message}`);
    }
  } else {
    popupCustomAlert("error", "예상치 못한 오류 발생");
  }
};
