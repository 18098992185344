import { Button, MenuItem } from "@mui/material";
import React from "react";
import UpdateUserPwdModal from "./UpdateUserPwdModal";
import { useModals } from "../../../../../service/modal/useModal";
import { updateUserPasswordDto } from "../../../../../service/user/types";
import { useUpdateUserPassword } from "../../../../../service/user/userHooks";
interface UpdateUserPwdModalButtonProps {
  userId: string;
  userName: string;
}

export const UpdateUserPwdModalButton: React.FC<
  UpdateUserPwdModalButtonProps
> = ({ userId, userName }) => {
  const { openModal, closeModal } = useModals();
  const { mutate: updateUserPassword } = useUpdateUserPassword();
  const handleClose = () => {
    closeModal(UpdateUserPwdModal);
  };

  const handleSubmit = (data: updateUserPasswordDto) => {
    updateUserPassword(data);
    handleClose();
  };

  const handleModalOpen = () => {
    openModal(UpdateUserPwdModal, {
      handleClose,
      handleSubmit,
      userId,
      userName,
    });
  };

  return (
    <React.Fragment>
        <MenuItem onClick={handleModalOpen}>비밀번호 변경</MenuItem>
      {/* <Button variant="contained" onClick={handleModalOpen}>
        비밀번호 변경
      </Button> */}
    </React.Fragment>
  );
};
