import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchInputFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  showIcon?: boolean;
  width?: string | number;
}

const SearchInputField: React.FC<SearchInputFieldProps> = ({
  value,
  onChange,
  onKeyDown = undefined,
  placeholder = "검색어를 입력하세요.",
  showIcon = true,
  width = "300px",
}) => {
  return (
    <TextField
      size="small"
      InputProps={{
        startAdornment: showIcon ? <SearchIcon sx={{ mr: 2 }} /> : <></>,
      }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      sx={{ width, backgroundColor: "white", fontFamily: "malgun" }}
      onKeyDown={onKeyDown}
    />
  );
};

export default SearchInputField;
