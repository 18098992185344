export const autoRowHeight = (row: any, defaultRowHeight: number = 40) => {
  const add_height = 20;

  if (row.length === 0 || !row) return defaultRowHeight;

  const rowCount = Math.max(
    Math.max(
      ...Object.values(row)
        .filter((item) => typeof item === "string")
        .map((item) => (item as string).split("\n").length)
    ) - 1,
    0
  );

  return rowCount * add_height + defaultRowHeight;
};
