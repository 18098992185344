import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCreateMenu } from "../../../service/menu/menuHooks";
import { isEmpty } from "../../../utils/isEmpty";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const MenuAddModal = ({ isOpen, onSubmit, onClose }: Props) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");

  const onError = (message: string) => {
    setAlertMsg(message);
    setIsAlert(true);
  };

  const { mutate: createMenu } = useCreateMenu(onSubmit, onError);

  useEffect(() => {
    if (isOpen) {
      setId("");
      setName("");
      setTitle("");
      setIsAlert(false);
    }
  }, [isOpen]);

  const onClickSave = () => {
    if (isEmpty(id)) {
      setAlertMsg("메뉴 ID는 필수 값입니다.");
      setIsAlert(true);
      return;
    }

    if (isEmpty(name)) {
      setAlertMsg("메뉴 이름은 필수 값입니다.");
      setIsAlert(true);
      return;
    }
    createMenu({
      MENU_ID: id.trim(),
      MENU_NM: name.trim(),
      MENU_TITLE: title.trim()
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlert}
        onClose={() => setIsAlert(false)}
        key={"validationAlert"}
        autoHideDuration={5000}
      >
        <Alert onClose={() => setIsAlert(false)} severity="error" variant="filled" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <Stack>
        <DialogTitle>
          <Typography variant="h4">메뉴 생성</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack gap={3} mt={2}>
            <Stack display={"flex"} flexDirection={"row"}>
              <TextField
                label={"메뉴 ID"}
                value={id}
                onChange={(event) => setId(event.target.value)}
                placeholder="메뉴 ID를 입력해주세요"
                size="medium"
              />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <TextField
                label={"이름"}
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="메뉴 이름을 입력해주세요"
                size="medium"
              />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <TextField
                label={"제목"}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                placeholder="제목을 입력해주세요"
                size="medium"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onClickSave}>
            저장
          </Button>
          <Button variant="contained" onClick={onClose}>
            취소
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
export default MenuAddModal;
