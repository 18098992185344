import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { getLiveStateColor } from "../../utils/commonUtil";

type SelectFormData = {
  value: string;
  label: string;
};

export const liveStateOptions = [
  {
    label: "전체",
    value: "전체",
  },
  {
    value: "ready",
    label: "대기",
  },
  {
    value: "request",
    label: "요청",
  },
  {
    value: "divide",
    label: "분배",
  },
  {
    value: "start",
    label: "시작",
  },
  {
    value: "end",
    label: "종료",
  },
];

interface SelectFormProps {
  id?: string;
  options?: SelectFormData[];
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  handleChange: (event: SelectChangeEvent) => void;
}

const SelectLiveForm: React.FC<SelectFormProps> = ({
  id = "",
  options = liveStateOptions,
  label,
  value,
  handleChange,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      sx={{ ...sx, backgroundColor: "white" }}
    >
      <InputLabel id="demo-simple-select-label" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || ""}
        variant={variant}
        onChange={handleChange}
        renderValue={(selected) => (
          <span
            style={{
              color: getLiveStateColor(selected),
              fontFamily: "malgun",
              fontSize: "15px",
            }}
          >
            {options.find((option) => option.value === selected)?.label || ""}
          </span>
        )}
      >
        {options.map((item, i) => (
          <MenuItem
            key={i}
            value={item.value}
            sx={{
              color:
                item.value === "" ? "black" : getLiveStateColor(item.value),
              fontFamily: "malgun",
              fontSize: "15px",
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLiveForm;
