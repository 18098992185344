import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from "react";


interface SelectFieldProps {
  id?: string;
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  handleChange: (event: SelectChangeEvent) => void | any;
}

const SelectYearField: React.FC<SelectFieldProps> = ({
  id = "",
  label = "",
  value,
  handleChange,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
}) => {


  const options = useMemo(()=>{
    return getYesrsList().map((year) => ({
      CD_DTL_ID: year,
      CD_DTL_NM: year,
    }));
    },[])
  return (
    <FormControl fullWidth={fullWidth} size={size} sx={sx}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || null}
        variant={variant}
        onChange={handleChange}
      >
       
        {!!options &&
          options.map((item, i) => (
            <MenuItem key={i} value={item.CD_DTL_ID}>
              {item.CD_DTL_NM}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectYearField;




const getYesrsList = () => {
  const years = [];
  const today = new Date();
  const currentYear = today.getFullYear();
  for (let year = currentYear-10; year <= currentYear+10; year++) {
    years.push(year.toString());
  }

  return years;
};
