import styled from "styled-components";
export const TotalCount = styled.div`
  font-size: 14px;
  color: #333;
  font-family: malgun;
`;

export const CountText = styled.a`
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  font-family: malgun;
  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  totalCount: number;
  text? : string;
  unitText? : string;
}

export default function DataTotalCount({ totalCount }: Props) {
  return (
    <TotalCount>
      전체 <CountText>{totalCount}</CountText> 건
    </TotalCount>
  );
}

export function DataCount({ totalCount, text , unitText = '건' }: Props) {
  return (
    <TotalCount>
      {text} <CountText>{totalCount}</CountText> {unitText}
    </TotalCount>
  );
}
