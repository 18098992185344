import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Button, SelectChangeEvent, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import SelectLiveForm, {
  liveStateOptions,
} from "../../../components/common/SelectLiveForm";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import { SelectFormData } from "../../../components/shared/input-field/SelectForm";
import { ContentListTableHandle, TabType } from "../../../types/CommonType";
import ContentStatsEntDisplay from "./stats/ContentStatsEntDisplay";

export default function ContentStatsPage() {
  const [selected, setSelected] = useState<TabType>("");
  const [search, setSearch] = useState("");
  const tableRef = useRef<ContentListTableHandle>(null);
  const [live, setLive] = useState<string>("start");
  const [stateOptions, setStateOptions] = useState<SelectFormData[]>([]);

  useEffect(() => {
    getLiveStateCode();
  }, []);

  useEffect(() => {
    onSearch();
  }, [selected]);

  const getLiveStateCode = () => {
    const instance: SelectFormData[] = [];
    for (let i = 0; i < liveStateOptions.length; i++) {
      const v = liveStateOptions[i].value;
      if (v === "ready" || v === "request" || v === "divide") continue;
      instance.push(liveStateOptions[i]);
    }

    setStateOptions(instance);
  };

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const onClickRequestContent = () => {
    tableRef.current?.triggerRequestContent();
  };

  const onClickStatsDashboard = () => {
    tableRef.current?.triggerDisplayStatsDashboard();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleLiveChange = (e: SelectChangeEvent) => {
    setLive(e.target.value);
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        gap={2}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={"전체"} value={""} />
                <Tab label={"국내매체"} value={"domestic"} />
                <Tab label={"해외매체"} value={"overseas"} />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                <SelectLiveForm
                  label="상태"
                  options={stateOptions}
                  size="small"
                  sx={{ width: "120px", display: "none" }}
                  value={live}
                  handleChange={handleLiveChange}
                />
                <SearchInputField
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  showIcon={false}
                  width={200}
                />
                <CommonButton
                  onClick={onSearch}
                  label="검색"
                  icon={<SearchIcon fontSize="small" />}
                />
                <Button
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                  color={"secondary"}
                  onClick={onClickStatsDashboard}
                  variant={"contained"}
                  startIcon={<MenuOutlinedIcon />}
                >
                  통계
                </Button>
                <Button
                  variant="contained"
                  startIcon={<AddOutlinedIcon />}
                  onClick={onClickRequestContent}
                  color="primary"
                  sx={{ fontFamily: "malgun", height: 40, color: "white" }}
                >
                  소재 요청
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <ContentStatsEntDisplay
          type={selected}
          search={search}
          ref={tableRef}
        />
      </Stack>
    </Stack>
  );
}
