import { Button } from "@mui/material";
import React from "react";
import { useModals } from "../../../../../service/modal/useModal";
import { createUserDto } from "../../../../../service/user/types";
import CreateUserModal from "./CreateUserModal";

interface CreateUserModalProps {
  onSubmit: () => void;
}

export const CreateUserModalButton: React.FC<CreateUserModalProps> = ({
  onSubmit,
}) => {
  const { openModal, closeModal } = useModals();
  const handleClose = () => {
    closeModal(CreateUserModal);
  };

  const handleSubmit = (data: createUserDto) => {
    onSubmit();
    handleClose();
  };

  const handleModalOpen = () => {
    openModal(CreateUserModal, { handleClose, handleSubmit });
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleModalOpen} size="medium">
        계정 생성
      </Button>
    </React.Fragment>
  );
};
