import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { SelectChangeEvent, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { ColumnOrColumnGroup } from "react-data-grid";
import CommonButton from "../../components/common/CommonButton";
import CustomCheckbox from "../../components/common/CustomCheckbox";
import DataTotalCount from "../../components/common/DataTotalCount";
import { CreateUserModalButton } from "../../components/common/organization/modal/create-user-modal/CreateUserModalButton";
import UserMenu from "../../components/common/organization/UserMenu";
import { getColumnWidth } from "../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../components/shared/input-field/SearchInputField";
import { MultiRowCell } from "../../components/shared/lib/multiRowCell";
import { Container } from "../../components/shared/styled-css/common-css";
import { customAxios } from "../../config";
import { getDetailCode } from "../../service/common/api";
import { CodeDetailType } from "../../types/CodeType";
import { UserType } from "../../types/UserType";
import { errorHandler } from "../../utils/apiUtil";
import { onColumnResize } from "../../utils/commonUtil";
import { formatDate, formatDateYYYYMMDD } from "../../utils/dateUtil";
import { getJwtState } from "../../utils/jwtState";
import CustomSelectForm from "../../components/common/CustomSelectForm";
import { SelectFormData } from "../../components/shared/input-field/SelectForm";

export default function Organization() {
  const gridRef = useRef<any>(null);
  const [rows, setRows] = useState<UserType[]>([]);
  const { deptId } = getJwtState();
  const [selected, setSelected] = useState(deptId);
  const [department, setDepartment] = useState<CodeDetailType[]>([]);
  const [search, setSearch] = useState("");
  const isAdmin =
    deptId === "C007Z" || deptId === "C007Y" || deptId === "C007A";
  const storedData = localStorage.getItem("organization");
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [status, setStatus] = useState<string>("EMPLOYED");
  const [items, setItems] = useState<SelectFormData[]>([]);

  const getEmploymentStatusCode = async () => {
    try {
      const response = await getDetailCode("C027");
      const data: CodeDetailType[] = response.data;

      const instance: SelectFormData[] = [];
      instance.push({
        label: "전체",
        value: "전체",
      });

      for (let i = 0; i < data.length; i++) {
        const id = data[i].CD_DTL_ID;
        const name = data[i].CD_DTL_NM;
        if (id === "ON_LEAVE") continue;

        instance.push({
          label: name,
          value: id,
        });
      }

      setItems(instance);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleStatusChange = async (e: SelectChangeEvent) => {
    setStatus(e.target.value);
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/user/list", {
        params: {
          search,
          DEPT_ID: selected,
          status: status === "전체" ? "" : status,
        },
      });
      const data: UserType[] = response.data;
      setRows(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, [selected, status]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getDepartmentCode = async () => {
    try {
      const response = await getDetailCode("C007");
      const data: CodeDetailType[] = response.data;

      setDepartment(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getDepartmentCode();
    getEmploymentStatusCode();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const columns: ColumnOrColumnGroup<UserType, any>[] = [
    {
      key: "USER_ID",
      name: "아이디",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "USER_ID", 120),
      editable: false,
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "USER_NAME",
      name: "이름",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "USER_NAME", 130),
      editable: false,
    },
    {
      key: "USER_CODE",
      name: "별칭",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "USER_CODE", 130),
      editable: false,
    },
    {
      key: "PHONE_NUMBER",
      name: "연락처",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "PHONE_NUMBER", 150),
      editable: false,
    },
    {
      key: "EMAIL",
      name: "네이버 이메일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "EMAIL", 250),
      ...MultiRowCell(),
      editable: false,
    },
    {
      key: "GOOGLE_EMAIL",
      name: "구글 이메일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "GOOGLE_EMAIL", 250),
      ...MultiRowCell(),
      editable: false,
    },
    {
      key: "EMPLOYMENT_STATUS",
      name: "재직여부",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      width: getColumnWidth(widths, "EMPLOYMENT_STATUS", 250),
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={row.EMPLOYMENT_STATUS === "EMPLOYED"}
            onChange={() => {}}
            checkedBgColor={"#3187f0"}
          />
        );
      },
      editable: false,
    },
    ...(isAdmin === true
      ? [
          {
            key: "EMPLOYMENT_DATE",
            name: "재직 변동일자",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center",
            width: getColumnWidth(widths, "EMPLOYMENT_DATE", 250),
            renderCell({ row }: { row: UserType }) {
              return (
                <div>
                  {row.EMPLOYMENT_STATUS === "EMPLOYED"
                    ? ""
                    : formatDateYYYYMMDD(row.EMPLOYMENT_DATE)}
                </div>
              );
            },
            editable: false,
          },
          {
            key: "CREATED_AT",
            name: "생성시간",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            width: getColumnWidth(widths, "CREATED_AT", 150),
            editable: false,
            renderCell({ row, onRowChange }: any) {
              return <span>{formatDate(row.CREATED_AT)}</span>;
            },
          },
          {
            key: "action",
            name: "",
            width: getColumnWidth(widths, "action", 100),
            editable: false,
            renderCell({ row, onRowChange }: any) {
              return (
                <UserMenu
                  userId={row.USER_ID}
                  userName={row.USER_NAME}
                  onSuccess={() => getRows()}
                />
              );
            },
          },
        ]
      : []),
  ];

  return (
    <Container style={{ flexDirection: "column", gap: 5 }}>
      <Stack display={"flex"} flexDirection={"row"} gap={1}>
        <SearchInputField
          value={search}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder=""
          showIcon={false}
        />
        <CommonButton
          onClick={getRows}
          label="검색"
          icon={<SearchIcon fontSize="small" />}
        />
        <CustomSelectForm
          key={"selectMedia"}
          options={items}
          label="재직 상태"
          fullWidth={false}
          size="small"
          value={status}
          colors={["#000000", "#0064FF", "#ff0000"]}
          handleChange={handleStatusChange}
          sx={{
            width: 100,
          }}
        />
        {isAdmin && <CreateUserModalButton onSubmit={getRows} />}
      </Stack>

      <TabContext value={selected}>
        <Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {!!department &&
              deptId === "C007Z" &&
              department.map((item, index) => (
                <Tab
                  label={item.CD_DTL_NM}
                  value={item.CD_DTL_ID}
                  key={index}
                />
              ))}
            {!!department &&
              deptId === "C007Y" &&
              department
                .filter((v) => v.CD_DTL_ID !== "C007Z")
                .map((item, index) => (
                  <Tab
                    label={item.CD_DTL_NM}
                    value={item.CD_DTL_ID}
                    key={index}
                  />
                ))}
            {!!department &&
              deptId !== "C007Z" &&
              deptId !== "C007Y" &&
              department
                .filter(
                  (v) => v.CD_DTL_ID !== "C007Z" && v.CD_DTL_ID !== "C007Y"
                )
                .map((item, index) => (
                  <Tab
                    label={item.CD_DTL_NM}
                    value={item.CD_DTL_ID}
                    key={index}
                  />
                ))}
          </TabList>
        </Box>
      </TabContext>
      <Stack flex={1}>
        {rows.length >= 0 && (
          <DataGrid
            className="rdg-light"
            style={{ height: `79vh` }}
            ref={gridRef}
            columns={columns}
            rows={rows}
            bottomSummaryRows={summaryRows} // 하단 요약 행 추가
            rowClass={(row: any) => {
              return row.COLOR === "" || row.COLOR === "white"
                ? undefined
                : `${row.COLOR}-background`;
            }}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onColumnResize={(idx, width) =>
              onColumnResize("organization", columns, idx, width)
            }
          />
        )}
      </Stack>
    </Container>
  );
}
