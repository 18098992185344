import { Button, Stack, TextField } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  ColumnOrColumnGroup,
  SortColumn,
  textEditor,
} from "react-data-grid";
import MenuAddModal from "../../components/admin/menu/MenuAddModal";
import MenuToRole from "../../components/admin/menu/MenuToRole";
import DataTotalCount from "../../components/common/DataTotalCount";
import { getColumnWidth } from "../../components/shared/cell-renderers/WidthComponent";
import { customAxios } from "../../config";
import { getMenuResponseDto } from "../../service/menu/types";
import { errorHandler } from "../../utils/apiUtil";
import { handleCopy, onColumnResize } from "../../utils/commonUtil";
import { formatDate } from "../../utils/dateUtil";
import { getJwtState } from "../../utils/jwtState";
import CommonButton from "../../components/common/CommonButton";

const Menu = () => {
  const WIDTH_KEY_NAME = "menu";
  const storedData = localStorage.getItem(WIDTH_KEY_NAME);
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const { deptId } = getJwtState();
  const selectedRowId = useRef<number>();
  const selectedColumnKey = useRef<string>();
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [rows, setRows] = useState<getMenuResponseDto[]>([]);
  const [search, setSearch] = useState("");

  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnKey.current = cell.column.key;
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/menu", {
        params: {
          search,
          // color,
        },
      });
      const data: getMenuResponseDto[] = response.data;
      setRows(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleUpdateRow = async (newData: any, oldData: any) => {
    if (deptId !== "C007Z") {
      return;
    }

    const rowId = selectedRowId.current || 0;
    let data: getMenuResponseDto = newData[rowId];
    if (data === undefined || selectedColumnKey.current === undefined) {
      return;
    }
    const updateColumnKey = selectedColumnKey.current;
    console.log(updateColumnKey, data);

    try {
      await customAxios.put("/menu", data);
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, []);

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<getMenuResponseDto, any>[] = [
    {
      key: "MENU_ID",
      name: "메뉴 ID",
      headerCellClass: "grid-header-font text-center",
      cellClass: "grid-cell-font text-center",
      width: getColumnWidth(widths, "MENU_ID", 100),
      editable: false,
      renderEditCell: textEditor,
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataTotalCount totalCount={rows.length} />
          </div>
        );
      },
    },
    {
      key: "MENU_NM",
      name: "이름",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "MENU_NM", 100),
      renderEditCell: textEditor,
    },
    {
      key: "MENU_TITLE",
      name: "제목",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "MENU_TITLE", 100),
      renderEditCell: textEditor,
    },
    {
      key: "SORT_SN",
      name: "정렬",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "SORT_SN", 100),
      renderEditCell: textEditor,
    },
    {
      key: "CREATED_AT",
      name: "생성 일시",
      headerCellClass: "grid-header-font text-center",
      cellClass: "grid-cell-font text-center",
      width: getColumnWidth(widths, "CREATED_AT", 100),
      editable: false,
      renderCell: ({ row }: { row: getMenuResponseDto }) => {
        return <div>{formatDate(row.CREATED_AT)}</div>;
      },
    },
    {
      key: "UPDATED_AT",
      name: "수정 일시",
      headerCellClass: "grid-header-font text-center",
      cellClass: "grid-cell-font text-center",
      width: getColumnWidth(widths, "UPDATED_AT", 100),
      editable: false,
      renderCell: ({ row }: { row: getMenuResponseDto }) => {
        return (
          <div>{row.UPDATED_AT !== null && formatDate(row.UPDATED_AT)}</div>
        );
      },
    },
  ];

  return (
    <Stack width={"100%"}>
      <MenuAddModal
        isOpen={isOpenAdd}
        onSubmit={() => {
          setIsOpenAdd(false);
          getRows();
        }}
        onClose={() => setIsOpenAdd(false)}
      />
      <Stack gap={2} flex={1}>
        <Stack
          flexDirection={"row"}
          gap={1}
          display={deptId === "C007Z" ? "flex" : "none"}
        >
          <TextField
            type="search"
            size="small"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <CommonButton
            onClick={() => {
              setPaginationModel({ ...paginationModel, page: 0 });
              getRows();
            }}
            label="검색"
            icon={<SearchIcon fontSize="small" />}
          />
          <Button variant="contained" onClick={() => setIsOpenAdd(true)}>
            메뉴 생성
          </Button>
        </Stack>
        <Stack flex={1} display={deptId === "C007Z" ? "flex" : "none"}>
          <DataGrid
            className="rdg-light"
            style={{ height: `40vh` }}
            onSelectedCellChange={handleSelectedRow}
            onRowsChange={handleUpdateRow}
            sortColumns={sortColumns}
            onSortColumnsChange={handleSortColumnsChange}
            columns={columns}
            rows={rows}
            bottomSummaryRows={summaryRows}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onColumnResize={(idx, width) =>
              onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
            }
            onCopy={handleCopy}
          />
        </Stack>
        <span
          style={{
            fontWeight: "bold",
            marginRight: 10,
          }}
        >
          부서별 메뉴 설정
        </span>
        {rows && <MenuToRole />}
      </Stack>
    </Stack>
  );
};

export default Menu;
