import { updateContentDto } from "../service/ad-content/types";
import { updateBannerRequestDto } from "../service/banner-ads/types";
import { updateCallDto } from "../service/call/types";
import { updateCustomerRequestDto } from "../service/customer/types";
import { updateSearchRequestDto } from "../service/search-ads/types";
import { updateTrialDto } from "../service/trial/types";

//최소 너비
export const MIN_WIDTH = "600px";

//상단바 높이
export const TOPNAV_HEIGHT = "58px";

//사이드바 크기
export const SIDENAV_WIDTH = "260px";

//cell 높이
export const CELL_HEIGHT = 38;

//공통코드
export const OPTION_CODE = {
  호스팅사: "C002",

  업체상태: "C003",
  계정: "C005",
  체험단: "C006",
  부서: "C007",
  직급: "C008",
  검색광고매체: "C009",
  검색광고상품: "C010",
  배너광고매체: "C011",
  배너광고상품: "C012",
  소재: "C013",
};

export const SERVER_URL = "https://wonjdcrm.com/api";
// export const SERVER_URL = "http://127.0.0.1:8080";

export const QUERY_KEY = {
  code: "code",
  menu: "menu",
  role: "role",
  userInfo: "userInfo",
  userList: "userList",
  salesList: "salesList",
  liveRequestList: "liveRequestList",
  trialList: "trialList",
  managementCustomerCountList: "managementCustomerCountList",
  bannerExpenditureList: "bannerExpenditureList",
  searchExpenditureList: "searchExpenditureList",
  contentList: "contentList",
  totalContentList: "totalContentList",
  bannerSalesReport: "bannerSalesReport",
  searchSalesReport: "searchSalesReport",
  salesReport: "salesReport",
  salesReportDetail: "salesReportDetail",
  call: "call",
};

export const CREATE_CUSTOMER_STEP = ["업체정보", "광고정보", "완료"];

export const CUSTOMER_COLUMN: (keyof updateCustomerRequestDto)[] = [
  "CUSTOMER_ID",
  "SALESPERSON_ID",
  "AD_STRATEGY_ID", // 상품
  "BRAND_NAME",
  "BUSINESS_NUMBER",
  "BUSINESS_NAME",
  "HOSTING_ID",
  "PRODUCT_TYPE",
  "PRODUCT_URL",
  "CONTACT_PERSON_POSITION",
  "CONTACT_PERSON_NAME",
  "CONTACT_PERSON_PHONE",
  "CONTACT_PERSON_EMAIL",
  "CUSTOMER_STATE",
  "NOTES",
  "AD_START_DATE",
  "AD_END_DATE",
  "IS_CONVERSION_TRACKED",
  "IS_TRANSFERRED_CHECKED",
];

// banner를 업데이트 하기위해 필요한 컬럼
export const BANNER_COLUMN: (keyof updateBannerRequestDto)[] = [
  "BANNER_ADS_ID",
  "CUSTOMER_ID",
  "AD_CHANNEL_ID", // 매체
  "ACCOUNT_NUMBER",
  "ACCOUNT_NAME",
  "PERMISSION_CHECK",
  "MARKETER_ID",
  "DESIGNER_ID",
  "TEAM_ACCOUNT",
];

// banner를 업데이트 하기위해 필요한 컬럼
export const SEARCH_COLUMN: (keyof updateSearchRequestDto)[] = [
  "SEARCH_ADS_ID",
  "CUSTOMER_ID",
  "AD_CHANNEL_ID",
  "SEM_ID",
  "MARKETER_ID",
  "PERMISSION_CHECK",
  "TEAM_ACCOUNT",
];

export const CONTENT_COLUMN: (keyof updateContentDto)[] = [
  "BANNER_ID",
  "BANNER_SN",
  "NAS_PATH",
  "STATUS",
  "GENERATED_ID",
  "GENERATED_DATE",
];

export const TRIAL_COLUMN: (keyof updateTrialDto)[] = [
  "TRIAL_OPTIN_ID",
  "CUSTOMER_ID",
  "IS_REGIST_PLUS",
  "IS_COMPLETE_CAMPAIGN",
  "TRIAL_STATUS",
  "NOTES",
];

export const CALL_COLUMN: (keyof updateCallDto)[] = [
  "CALL_ID",
  "SALESPERSON_ID",
  "CREATE_DATE",
  "KEYWORD",
  "CONTACT_SOURCE",
  "BUSINESS_NAME",
  "ENT_CONTACT_NAME",
  "PRODUCT_URL",
  "PHONE_NUMBER",
  "NOTES",
  "EMAIL",
  "IS_KAKAO_CONTACT",
  "IS_MAIL_CONTACT",
  "COLOR",
];

export const colorOptions = [
  {
    label: "전체",
    value: "전체",
  },
  {
    value: "white",
    label: "없음",
  },
  {
    value: "red",
    label: "red",
  },
  {
    value: "orange",
    label: "orange",
  },
  {
    value: "yellow",
    label: "yellow",
  },
  {
    value: "green",
    label: "green",
  },
  {
    value: "blue",
    label: "blue",
  },
];