import EditSharpIcon from "@mui/icons-material/EditSharp";
import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  ColumnOrColumnGroup,
  SortColumn,
  textEditor,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount from "../../../components/common/DataTotalCount";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { customAxios } from "../../../config";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { SettleGetType, SettleWithEntGetType } from "../../../types/SettleType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import {
  formatDate,
  formatDateYYYYMMDD,
  getCurrentKorMonth,
} from "../../../utils/dateUtil";
import { generateMonths } from "../../../utils/generateDates";
import { getJwtState } from "../../../utils/jwtState";

export interface ListTableProps {
  type: TabType;
  search: string;
  year: string;
  live: string;
  media: string;
}

const SettleTable = forwardRef<ListTableHandle, ListTableProps>(
  ({ type, search, year, live, media }: ListTableProps, ref) => {
    const { userId, deptId } = getJwtState();
    const storedData = localStorage.getItem("settle_list");
    const widths = storedData ? JSON.parse(storedData) : undefined;
    const targetMonth = getCurrentKorMonth(); // YYYYMMDD 형식
    const [rows, setRows] = useState<SettleWithEntGetType[]>([]);
    const gridRef = useRef<HTMLDivElement>(null);
    const selectedRowId = useRef<number>();
    const selectedColumnKey = useRef<string>();
    const monthList = useMemo(() => generateMonths(year), [year]);

    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

    const getRows = async () => {
      try {
        const response = await customAxios.get("/settle", {
          params: {
            type,
            search,
            year,
            live: live === "전체" ? "" : live,
            media: media === "전체" ? "" : media,
            deptId,
          },
        });
        const data: SettleWithEntGetType[] = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    const scrollToDateColumn = () => {
      const columnIndex = monthList.findIndex((month) => month === targetMonth);
      if (gridRef.current && columnIndex !== -1) {
        const grid = gridRef.current.querySelector(".rdg");
        if (grid) {
          const columnWidth = 90;
          grid.scrollLeft = columnIndex * columnWidth;
        }
      }
    };

    useEffect(() => {
      if (rows.length > 0) {
        scrollToDateColumn();
      }
    }, [monthList, rows]);

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {
        if (rows.length === 0) {
          popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
          return;
        }

        popupCustomAlert("info", "개발 중입니다");
        // const excelData = rows.map((item) => {
        //   const dates = dayList.reduce((acc, date) => {
        //     const index = item.TSV_LIST.findIndex((v) => v.TSV_DATE === date);
        //     acc[formatDateYYYYMMDD(date)] =
        //       index === -1
        //         ? 0
        //         : item.TSV_LIST[index].TSV_AMOUNT.toLocaleString();
        //     return acc;
        //   }, {} as Record<string, string | number>);

        //   const total_sum = item.TSV_LIST.reduce(
        //     (sum, entry) => sum + entry.TSV_AMOUNT,
        //     0
        //   );

        //   return {
        //     사업자명: item.ENT_NM,
        //     매체: item.MEDIA,
        //     브랜드명: item.B_NAME,
        //     계정명: item.TEAM_ACCOUNT,
        //     ...(item.TYPE === "overseas"
        //       ? { 진행방식: item.O_PROGRESS_METHOD }
        //       : {}),
        //     라이브상태: getLiveStateName(item.LIVE),
        //     영업담당자: item.SALESMAN_NM,
        //     마케터: item.MARKETER_NM,
        //     합계: total_sum,
        //     ["합계(VAT제외)"]: (total_sum / 1.1).toFixed(0),
        //     ...dates,
        //   };
        // });

        // excelDownLoader(excelData, "취급고.xlsx");
      },
    }));

    useEffect(() => {
      getRows();
    }, [year, live, media, type]);

    //몇번째 줄이 수정중인지 파악함
    const handleSelectedRow = (cell: any) => {
      selectedRowId.current = cell.rowIdx;
      selectedColumnKey.current = cell.column.key;
    };

    const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
      setSortColumns(newSortColumns);

      if (newSortColumns.length === 0) {
        setRows([...rows]); // 원래 데이터 유지
      } else {
        const sortedRows = [...rows].sort((a, b) => {
          for (const { columnKey, direction } of newSortColumns) {
            const sortOrder = direction === "ASC" ? 1 : -1;
            const aValue = (a as Record<string, any>)[columnKey];
            const bValue = (b as Record<string, any>)[columnKey];

            // aValue와 bValue가 undefined거나 null인 경우를 처리
            if (aValue === undefined || aValue === null) return 1 * sortOrder;
            if (bValue === undefined || bValue === null) return -1 * sortOrder;

            if (aValue < bValue) return -1 * sortOrder;
            if (aValue > bValue) return 1 * sortOrder;
          }
          return 0; // 모든 조건이 같을 때
        });
        setRows(sortedRows);
      }
    };

    //수정된 값을 서버로 보내어 업데이트함
    const handleUpdateTable = async (newData: any, oldData: any) => {
      // if (selectedColumnId.current === undefined) {
      //   return;
      // }

      if (deptId !== "C007I" && deptId !== "C007Z") {
        popupCustomAlert(
          "warn",
          `마케팅_팀장만 수정할 수 있습니다`,
          "ONLY_UPDATE_MARKETING_LEADER"
        );
        return;
      }

      const updateData = newData[selectedRowId.current || 0];
      const updateColumnKey = selectedColumnKey.current;

      console.log(updateColumnKey);
      if (updateColumnKey === "LIMIT_SETTING_AMOUNT") {
        const LIMIT_SETTING_AMOUNT = updateData.LIMIT_SETTING_AMOUNT;
        console.log(LIMIT_SETTING_AMOUNT);

        if (isNaN(LIMIT_SETTING_AMOUNT)) {
          popupCustomAlert(
            "warn",
            `숫자를 입력해주세요 (값 : ${LIMIT_SETTING_AMOUNT})`,
            "LIMIT_SETTING_AMOUNT"
          );
          return;
        }

        try {
          const payload = {
            ENT_ID: updateData.ENT_ID,
            LIMIT_SETTING_AMOUNT: updateData.LIMIT_SETTING_AMOUNT,
          };
          await customAxios.put("/ent", payload);
          getRows();
        } catch (e) {
          errorHandler(e);
        }
      }
    };

    // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
    const summaryRows = useMemo((): any[] => {
      const result: {
        [key in string]: number;
      } = {};

      for (let i = 0; i < monthList.length; i++) {
        result[monthList[i]] = 0;
      }

      let totalSum = 0;
      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].SETTLE_LIST.length; j++) {
          result[rows[i].SETTLE_LIST[j].SETTLE_MONTH] +=
            rows[i].SETTLE_LIST[j].TOTAL_MONTH_TSV_AMOUNT;
          totalSum += rows[i].SETTLE_LIST[j].TOTAL_MONTH_TSV_AMOUNT;
        }
      }

      return [
        {
          sum: result,
          totalSum,
        },
      ];
    }, [year, live, media, rows]);

    //컬럼 테이블 생성
    const columns: ColumnOrColumnGroup<SettleWithEntGetType, any>[] = [
      {
        key: "ENT_NM",
        name: "사업자명",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "ENT_NM", 120),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderSummaryCell({ row }: any) {
          return <DataTotalCount totalCount={rows.length} />;
        },
      },
      {
        key: "MEDIA",
        name: "매체",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        frozen: true,
        width: getColumnWidth(widths, "MEDIA", 100),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}
            >
              {row.MEDIA}
            </div>
          );
        },
        editable: false,
      },
      {
        key: "B_NAME",
        name: "브랜드명",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
      },
      {
        key: "TEAM_ACCOUNT",
        name: "계정명",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "TEAM_ACCOUNT", 100),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
      },
      ...(type === "overseas"
        ? [
            {
              key: "O_PROGRESS_METHOD",
              name: "진행방식",
              frozen: true,
              resizable: true,
              width: getColumnWidth(widths, "TEAM_ACCOUNT", 100),
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
            },
            {
              key: "O_PAYER_NM",
              name: "입금자명",
              frozen: true,
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "O_PAYER_NM", 100),
            },
            {
              key: "TAX_INVOICE_EMAIL",
              name: "세금계산서 발행 이메일",
              frozen: true,
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "TAX_INVOICE_EMAIL", 100),
            },
          ]
        : []),
      {
        key: "LIVE",
        name: "라이브 상태",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "LIVE", 100),
        headerCellClass: "text-center grid-header-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              <Chip
                label={getLiveStateName(row.LIVE)}
                variant="outlined"
                size="small"
                sx={{
                  color: getLiveStateColor(row.LIVE),
                  borderColor: getLiveStateColor(row.LIVE),
                }}
              />
            </div>
          );
        },
      },
      {
        key: "LIVE_START_AT",
        name: "시작 일자",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              {row.LIVE_START_AT && formatDate(row.LIVE_START_AT, true)}
            </div>
          );
        },
      },
      {
        key: "LIVE_END_AT",
        name: "종료 일자",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              {row.LIVE_END_AT && formatDate(row.LIVE_END_AT, true)}
            </div>
          );
        },
      },
      {
        key: "SALESMAN_NM",
        name: "영업 담당자",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        frozen: true,
        editable: false,
        width: getColumnWidth(widths, "SALESMAN_NM", 100),
        renderCell({ row }: { row: SettleWithEntGetType }) {
          return row.SALESMAN_EMPLOYMENT_STATUS === "EMPLOYED" ? (
            <div style={{ textAlign: "center" }}>{row.SALESMAN_NM}</div>
          ) : (
            <div style={{ textAlign: "center", color: "#fc5d5d" }}>
              <span
                title={`${row.SALESMAN_NM} (${
                  row.SALESMAN_ID
                }, ${formatDateYYYYMMDD(row.SALESMAN_EMPLOYMENT_DATE)}) `}
              >
                퇴사
              </span>
            </div>
          );
        },
      },
      {
        key: "MARKETER_NM",
        name: "마케터",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "MARKETER_NM", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
      },
      {
        key: "LIMIT_SETTING_AMOUNT",
        name: "한도 설정 금액",
        frozen: true,
        headerCellClass: "text-center grid-header-font",
        renderHeaderCell() {
          return (
            <div
              className="flex-box flex-box-row"
              style={{ justifyContent: "space-between" }}
            >
              <span className="flex">한도 설정 금액</span>
              <div className="flex-box" style={{ alignItems: "center" }}>
                <EditSharpIcon style={{ fontSize: 15, color: "#9b9898" }} />
              </div>
            </div>
          );
        },
        cellClass: "text-center grid-cell-font",
        renderEditCell: textEditor,
        editable: true,
        width: getColumnWidth(widths, "LIMIT_SETTING_AMOUNT", 130),
        renderCell: ({ row }: any) => {
          return (
            <div style={{ textAlign: "right" }}>
              {row.LIMIT_SETTING_AMOUNT.toLocaleString()}
            </div>
          );
        },
      },
      {
        key: "TOTAL_SETTLE_AMOUNT",
        name: "합계",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "TOTAL_SETTLE_AMOUNT", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          const rowTotalSum: number = row.TOTAL_SETTLE_AMOUNT;
          return (
            <div style={{ textAlign: "right" }}>
              {rowTotalSum === undefined ? 0 : rowTotalSum.toLocaleString()}
            </div>
          );
        },
        renderSummaryCell({ row }: any) {
          return (
            <div style={{ textAlign: "right" }}>
              {row.totalSum === undefined ? 0 : row.totalSum.toLocaleString()}
            </div>
          );
        },
      },
      ...(type === "" || type === "search"
        ? [
            {
              key: "TOTAL_SETTLE_AMOUNT_INCLUDE_VAT",
              name: "합계 (VAT 포함)",
              frozen: true,
              resizable: true,
              width: getColumnWidth(
                widths,
                "TOTAL_SETTLE_AMOUNT_INCLUDE_VAT",
                120
              ),
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              renderCell({ row, onRowChange }: any) {
                const rowTotalSum: number = row.TOTAL_SETTLE_AMOUNT;
                return (
                  <div style={{ textAlign: "right" }}>
                    {rowTotalSum === undefined
                      ? 0
                      : Number((rowTotalSum * 1.1).toFixed(0)).toLocaleString()}
                  </div>
                );
              },
              renderSummaryCell({ row }: any) {
                return (
                  <div style={{ textAlign: "right" }}>
                    {row.totalSum === undefined
                      ? 0
                      : Number(
                          (row.totalSum * 1.1).toFixed(0)
                        ).toLocaleString()}
                  </div>
                );
              },
            },
          ]
        : []),
      ...generateMonths(year).map((month) => {
        return {
          key: month,
          name: month,
          width: getColumnWidth(widths, month, 90),
          cellClass:
            month === targetMonth
              ? "today-focus-cell grid-cell-font"
              : "grid-cell-font",
          renderHeaderCell() {
            return (
              <div className="text-center grid-header-font">
                {month.slice(0, 4) + "-" + month.slice(4, 6)}
              </div>
            );
          },
          editable: type === "" ? false : true,
          renderEditCell: textEditor,
          renderCell: ({ row, rowIdx, column }: any) => {
            const instance: SettleGetType[] = row.SETTLE_LIST;
            const key: string = column.key;

            const index = instance.findIndex((v) => v.SETTLE_MONTH === key);
            const amount =
              index === -1 ? 0 : Number(instance[index].TOTAL_MONTH_TSV_AMOUNT);

            return (
              <div style={{ textAlign: "right" }}>
                {amount.toLocaleString()}
              </div>
            );
          },
          renderSummaryCell({ row }: any) {
            return (
              <div style={{ textAlign: "right" }}>
                {row.sum[month] === undefined
                  ? 0
                  : row.sum[month].toLocaleString()}
              </div>
            );
          },
        };
      }),
    ];

    return (
      <Stack flex={1}>
        {rows && (
          <div
            ref={gridRef}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DataGrid
              className="rdg-light"
              style={{ height: `84vh` }}
              onSelectedCellChange={handleSelectedRow}
              sortColumns={sortColumns}
              onSortColumnsChange={handleSortColumnsChange}
              onRowsChange={handleUpdateTable}
              columns={columns}
              rows={rows}
              bottomSummaryRows={summaryRows} // 하단 요약 행 추가
              defaultColumnOptions={{
                resizable: true,
                sortable: true,
              }}
              onColumnResize={(idx, width) =>
                onColumnResize("tsv_list", columns, idx, width)
              }
              onCopy={handleCopy}
            />
          </div>
        )}
      </Stack>
    );
  }
);

export default SettleTable;
