import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import error_image from "../../assets/image/error_img.png";
import { useNavigate } from "react-router-dom";
const ErrorBoundary: React.FC = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "80vh",
        paddingTop: "100px",
        backgroundColor: "background.paper",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ margin: "0 auto", width: "1000px", pl: "50px" }}
      >
        <Box
          sx={{
            position: "relative",
            animation: "moveUpDown 3s infinite",
            "@keyframes moveUpDown": {
              "0%": {
                transform: "translateY(0)",
              },
              "50%": {
                transform: "translateY(10px)", // 변경할 수 있는 값
              },
              "100%": {
                transform: "translateY(0)",
              },
            },
          }}
        >
          <img src={error_image} width="500px" alt="" />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="h1" fontSize={120}>
            404
          </Typography>
          <Typography fontSize={"bold"} variant="h3" sx={{ mb: 2 }}>
            Oops! page not Found
          </Typography>
          <Typography variant="body1">
            찾으시는 페이지의 주소가 잘못 입력되었거나, <br />
            주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다. <br />
            입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요
          </Typography>
          <Button
            variant="text"
            sx={{
              cursor: "pointer",
              width: "150px",
              height: "40px",
              mt: 3,
              mb: 5,
              color: "#333",
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "20px",
            }}
            onClick={handleRedirect}
          >
            페이지 이동하기
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ErrorBoundary;
