import { customAxios } from "../../config";
import { getCallRequestDto, getCallResponseDto, createCallRequestDto, updateCallDto, deleteCallDto } from "./types";

export const callApi = {
  getCall: async (params: getCallRequestDto): Promise<getCallResponseDto[]> => {
    return await customAxios.get(`/call`, { params }).then((res) => res.data);
  },
  createCall: async (body: createCallRequestDto) => {
    return await customAxios.post(`/call`, body).then((res) => res.data);
  },
  updateCall: async (body: updateCallDto): Promise<updateCallDto> => {
    return await customAxios.put(`/call`, body).then(() => body);
  },
  deleteCall: async (data: deleteCallDto): Promise<deleteCallDto> => {
    return await customAxios.delete(`/call`, { data }).then(() => data);
  },
};

