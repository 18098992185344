import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

export type CustomSelectFormData = {
  value: string;
  label: string;
};

interface SelectFormProps {
  id?: string;
  options: CustomSelectFormData[];
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  // variant: string;
  handleChange: (event: SelectChangeEvent) => void;
  colors?: string[];
}

const CustomSelectForm: React.FC<SelectFormProps> = ({
  id = "",
  label,
  value,
  handleChange,
  options,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
  colors = [],
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      size={size}
      sx={{ ...sx, backgroundColor: "white" }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || ""}
        variant={variant}
        onChange={handleChange}
        renderValue={(selected) => (
          <span
            style={{
              color: colors[options.findIndex((v) => v.value === selected)],
              fontFamily: "malgun",
              fontSize:"15px"
            }}
          >
            {options.find((option) => option.value === selected)?.label || ""}
          </span>
        )}
      >
        {options.map((item, i) => (
          <MenuItem
            key={i}
            value={item.value}
            sx={{
              color: colors[i],
              fontFamily: "malgun",
              fontSize:"15px"
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectForm;
