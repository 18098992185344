import { Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CustomCheckbox from "../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../components/common/DataTotalCount";
import { getColumnWidth } from "../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../components/shared/lib/multiRowCell";
import { customAxios } from "../../config";
import { AccountGetType } from "../../types/AccountType";
import { AccountListTableHandle, AccountTabType } from "../../types/CommonType";
import { errorHandler } from "../../utils/apiUtil";
import { handleCopy, onColumnResize } from "../../utils/commonUtil";
import { getJwtState } from "../../utils/jwtState";

export interface SalesListTableProps {
  type: AccountTabType;
  search: string;
}

const AccountInfoTable = forwardRef<
  AccountListTableHandle,
  SalesListTableProps
>(({ type, search }: SalesListTableProps, ref) => {
  const { deptId } = getJwtState();
  const storedData = localStorage.getItem("account_info_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [rows, setRows] = useState<AccountGetType[]>([]);
  const selectedRowId = useRef<number>();
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태
  const [editable, setEdiable] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );

  const getRows = async (ACCOUNT_DIV_CD: string) => {
    try {
      const response = await customAxios.get("/account", {
        params: {
          ACCOUNT_DIV_CD,
          search,
          deptId,
        },
      });
      const data: AccountGetType[] = response.data;

      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  useImperativeHandle(ref, () => ({
    triggerSearch: () => {
      getRows(type);
    },
    triggerExcelDownload: () => {},
    handleCreateRow: async () => {
      try {
        await customAxios.post("/account", {
          ACCOUNT_DIV_CD: type,
        });
        getRows(type);
      } catch (e) {
        errorHandler(e);
      }
    },
    handleDeleteRow: async () => {
      const selectedIds = Array.from(selectedRows);
      const size = selectedIds.length;

      if (size === 0) {
        popupCustomAlert("warn", "선택된 데이터가 없습니다");
        return;
      }

      if (confirm(`선택된 ${size}개의 데이터를 삭제하시겠습니까?`)) {
        try {
          await customAxios.delete("/account/selected", {
            data: {
              ACCOUNTS_ID: selectedIds,
            },
          });
          setSelectedRows(new Set());
          getRows(type);
        } catch (e) {
          errorHandler(e);
        }
      }
    },
  }));

  useEffect(() => {
    getRows(type);

    if (type === "wonjd") {
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        setEdiable(true);
      } else {
        setEdiable(false);
      }
    } else if (type === "agency") {
      if (
        deptId === "C007Z" ||
        deptId === "C007Y" ||
        deptId === "C007I" ||
        deptId === "C007C"
      ) {
        setEdiable(true);
      } else {
        setEdiable(false);
      }
    } else if (type === "important") {
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        setEdiable(true);
      } else {
        setEdiable(false);
      }
    } else if (type === "management") {
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007A") {
        setEdiable(true);
      } else {
        setEdiable(false);
      }
    }
  }, [type]);

  // 정렬 처리 핸들러
  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleUpdateRow = async (newData: any, oldData: any) => {
    const rowId = selectedRowId.current || 0;
    let row: AccountGetType = newData[rowId];

    try {
      await customAxios.put("/account", row);
      getRows(type);
    } catch (e) {
      errorHandler(e);
    }
  };

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const columns: ColumnOrColumnGroup<AccountGetType, any>[] = [
    ...(type !== "agency"
      ? [
          {
            key: "CHK",
            name: "선택",
            headerCellClass: "text-center",
            width: getColumnWidth(widths, "CHK", 70),
            renderHeaderCell: () => {
              const isAllSelected =
                rows.length > 0 &&
                rows.every((row) => selectedRows.has(row.ACCOUNT_ID));

              const handleSelectAllChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                if (event.target.checked) {
                  const allRowIds = new Set(rows.map((row) => row.ACCOUNT_ID));
                  setSelectedRows(allRowIds);
                } else {
                  setSelectedRows(new Set());
                }
              };

              return (
                <CustomCheckbox
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
              );
            },
            renderCell: ({ row }: any) => {
              const handleRowCheckboxChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                setSelectedRows((prevSelectedRows) => {
                  const updatedSelectedRows = new Set(prevSelectedRows);
                  if (event.target.checked) {
                    updatedSelectedRows.add(row.ACCOUNT_ID);
                  } else {
                    updatedSelectedRows.delete(row.ACCOUNT_ID);
                  }
                  return updatedSelectedRows;
                });
              };

              return (
                <CustomCheckbox
                  checked={selectedRows.has(row.ACCOUNT_ID)}
                  onChange={handleRowCheckboxChange}
                />
              );
            },
            renderSummaryCell({ row }: any) {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DataCount totalCount={selectedRows.size} />
                </div>
              );
            },
          },
        ]
      : []),
    ...(type === "agency"
      ? [
          {
            key: "B_NAME",
            name: "브랜드명",
            resizable: true,
            width: getColumnWidth(widths, "B_NAME", 100),
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            editable: false,
            renderSummaryCell({ row }: any) {
              return <DataTotalCount totalCount={rows.length} />;
            },
          },
          {
            key: "H_NAME",
            name: "호스트사",
            resizable: true,
            editable: false,
            width: getColumnWidth(widths, "B_NAME", 100),
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
          },
        ]
      : [
          {
            key: "PLATFORM",
            name: "플랫폼",
            resizable: true,
            width: getColumnWidth(widths, "PLATFORM", 100),
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderSummaryCell({ row }: any) {
              return <DataTotalCount totalCount={rows.length} />;
            },
            editable,
            ...MultiRowCell(),
          },
        ]),
    {
      key: "ID",
      name: "아이디",
      resizable: true,
      width: getColumnWidth(widths, "ID", 130),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable,
      ...MultiRowCell(),
    },
    ...(type === "agency"
      ? [
          {
            key: "SUB_ID",
            name: "부운영자 아이디",
            resizable: true,
            width: getColumnWidth(widths, "ID", 130),
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            editable,
            ...MultiRowCell(),
          },
        ]
      : []),
    {
      key: "PASSWORD",
      name: "비밀번호",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "PASSWORD", 100),
      editable,
      ...MultiRowCell(),
    },
    {
      key: "MEMO",
      name: "메모",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      resizable: true,
      width: getColumnWidth(widths, "MEMO", 160),
      editable,
      ...MultiRowCell(),
    },
  ];

  return (
    <Stack flex={1}>
      {rows && (
        <DataGrid
          className="rdg-light"
          style={{ height: `84vh` }}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          columns={columns}
          rowHeight={autoRowHeight}
          onRowsChange={handleUpdateRow}
          rows={rows}
          onSelectedCellChange={handleSelectedRow}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            resizable: true,
            sortable: true,
          }}
          onColumnResize={(idx, width) =>
            onColumnResize("account_info_list", columns, idx, width)
          }
          onCopy={handleCopy}
        />
      )}
    </Stack>
  );
});

export default AccountInfoTable;
