import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, Stack, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import SalesCustomerAddPopup from "./SalesEntAddPopup";
import SalesListTable from "./SalesListTable";
import CommonButton from "../../../components/common/CommonButton";

export default function SalesListPage() {
  const [selected, setSelected] = useState<TabType>("domestic");
  const [search, setSearch] = useState("");
  const [openModal, setOpenModel] = useState("");
  const tableRef = useRef<ListTableHandle>(null);
  const [live] = useState<string>("ready");

  useEffect(() => {
    onSearch();
  }, [selected]);

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const onClickOpenModel = () => {
    setOpenModel(selected);
  };

  const handleModalSubmit = () => {
    setOpenModel("");
    onSearch();
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <SalesCustomerAddPopup
        open={openModal}
        onSubmit={handleModalSubmit}
        onCancel={() => setOpenModel("")}
      />
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                <Tab label={"국내매체광고"} value={"domestic"} />
                <Tab label={"해외매체광고"} value={"overseas"} />
                <Tab label={"검색광고"} value={"search"} />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                <Stack display={"flex"} flexDirection={"row"} gap={1}>
                  <SearchInputField
                    value={search}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    showIcon={false}
                    width={200}
                  />
                  <CommonButton
                    onClick={onSearch}
                    label="검색"
                    icon={<SearchIcon fontSize="small" />}
                  />
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={onClickOpenModel}
                  >
                    업체 추가
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <Stack mt={2} flex={1}>
          <SalesListTable
            type={selected}
            search={search}
            ref={tableRef}
            live={live}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
