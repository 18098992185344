import axios from "axios";
import { SERVER_URL } from "../constants/constant";
import { clearJwtState, getJwtState } from "../utils/jwtState";
import { LoginAPI } from "../service/login/loginAxios";

// 원격 서버
export const URL = SERVER_URL;

export const customAxios = axios.create({
  baseURL: URL,
  // withCredentials: true, // withCredentials를 true로 설정하여 CORS 요청 시 쿠키를 포함합니다
});

customAxios.interceptors.request.use(
  //axios 요청 직전에 발생하는 함수
  function (config) {
    //토큰 자동추가 함수
    const accessToken = getJwtState().accessToken;
    if (accessToken) {
      config.headers.Authorization = `${accessToken}`;
    }
    return config;
  },
  //axios 요청 에러나면 발생하는 함수
  function (error) {
    return Promise.reject(error);
  }
);
customAxios.interceptors.response.use(
  //axios 올바른 response가오면 발동하는 함수
  function (response) {
    return response;
  },

  //에러 메시지가 response되면 발동되는 함수
  async function (error) {
    console.log(error?.response?.data?.error?.message);
    if (
      error?.response?.data?.error?.message.includes(
        "(인증 토큰 값 유효하지 않음)"
      ) ||
      error?.response?.data?.error?.message.includes("권한 없음")
    ) {
      console.log("토큰 만료라 리프레쉬 토큰 함수 동작함");
      LoginAPI.refreshToken();
      // 예: 인증 오류 발생 시 로그아웃 처리
      clearJwtState();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
