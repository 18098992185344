import React from "react";
import "./CustomCheckbox.css";

interface CustomCheckboxProps {
  id?:string | undefined;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedBgColor?: string; // backgroundColor를 prop으로 추가
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  id = undefined,
  checked,
  onChange,
  checkedBgColor = "#005194",
}) => {
  return (
    <div className="checkbox-cell">
      <input
        id={id}
        type="checkbox"
        className="custom-checkbox"
        checked={checked}
        onChange={onChange}
        style={
          {
            "--checkbox-background-color": checkedBgColor,
          } as React.CSSProperties
        } // 스타일로 전달
      />
    </div>
  );
};

export default CustomCheckbox;
