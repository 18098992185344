import { RenderEditCellProps } from "react-data-grid";
import { CellSelect } from "./CellSelect";
import { useMemo } from "react";
import { getContentStateColor } from "../../../utils/commonUtil";

export const CONTENT_LIST: { [key: string]: string } = {
  C026A: "제작요청",
  C026B: "준비",
  C026C: "전달",
  C026D: "세팅",
};

export function ContentStateSelect({
  row,
  onRowChange,
}: RenderEditCellProps<any>) {
  const data = useMemo(() => {
    const result = [];

    for (const key in CONTENT_LIST) {
      result.push({ CD_DTL_ID: key, CD_DTL_NM: CONTENT_LIST[key] });
    }
    return result;
  }, []);

  return (
    <CellSelect
      value={row.CONTENT_STATE_CD}
      onChange={(event) =>
        onRowChange({ ...row, CONTENT_STATE_CD: event.target.value }, true)
      }
      autoFocus
    >
      {data &&
        data.map((item, i) => (
          <option
            key={i}
            value={item.CD_DTL_ID}
            style={{
              color: getContentStateColor(item.CD_DTL_ID),
              fontFamily: "malgun",
            }}
          >
            {item.CD_DTL_NM}
          </option>
        ))}
    </CellSelect>
  );
}
