import { customAxios } from "../../config";
import {
  createCodeRequestDto,
  createCodeResponseDto,
  createDetailCodeRequestDto,
  createDetailCodeResponseDto,
  deleteCodeRequestDto,
  deleteCodeResponseDto,
  deleteDetailCodeRequestDto,
  deleteDetailCodeResponseDto,
  getCodeRequestDto,
  getCodeResponseDto,
  getDetailCodeRequestDto,
  getDetailCodeResponseDto,
  updateCodeRequestDto,
  updateCodeResponseDto,
  updateDetailCodeRequestDto,
  updateDetailCodeResponseDto,
} from "./types";

// 서버 : snake_case
// 프론트 : camelCase

export const CodeAPI = {
  getCode: async (
    data: getCodeRequestDto | undefined
  ): Promise<getCodeResponseDto[]> => {
    return await customAxios
      .get(`/code?condition=${data}`)
      .then((res) => res.data);
  },
  createCode: async (
    data: createCodeRequestDto
  ): Promise<createCodeResponseDto> => {
    const body = data;
    return await customAxios
      .post("/code", body)
      .then((res) => res.data);
  },
  updateCode: async (
    data: updateCodeRequestDto
  ): Promise<updateCodeResponseDto> => {
    const body = data;
    return await customAxios
      .put("/code", body)
      .then((res) => res.data);
  },
  deleteCode: async (
    data: deleteCodeRequestDto
  ): Promise<deleteCodeResponseDto> => {
    return await customAxios
      .delete(`/code`, {
        data
      })
      .then((res) => res.data);
  },
  getDetailCode: async (
    data: getDetailCodeRequestDto | undefined
  ): Promise<getDetailCodeResponseDto[]> => {
    return await customAxios
      .get(`/code/detail?CD_ID=${data}`)
      .then((res) => res.data);
  },
  createDetailCode: async (
    data: createDetailCodeRequestDto
  ): Promise<createDetailCodeResponseDto> => {
    const body = data;
    return await customAxios
      .post("/code/detail/", body)
      .then((res) => res.data);
  },
  updateDetailCode: async (
    data: updateDetailCodeRequestDto
  ): Promise<updateDetailCodeResponseDto> => {
    const body = data;
    return await customAxios
      .put("/code/detail/", body)
      .then((res) => res.data);
  },
  deleteDetailCode: async (
    data: deleteDetailCodeRequestDto
  ): Promise<deleteDetailCodeResponseDto> => {
    return await customAxios
      .delete(`/code/detail`, {
        data
      })
      .then((res) => res.data);
  },
};
