import { create } from "zustand";

type customerIdType = {
  customerId: number | null;
};

type customerIdActions = {
  setCustomerId: (i: number) => void;
  clearCustomerId: () => void;
};

export const useCreatedCustomerIdState = create<customerIdActions & customerIdType>((set, get) => ({
  customerId: null,
  setCustomerId: (i: number) => {
    set({
      customerId: i,
    });
  },
  clearCustomerId: () => {
    set({
      customerId: null,
    });
  },
}));
