import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { popupCustomAlert } from "../../components/common/CustomToast";
import { useIsLoginState } from "../../store/isLoginState";
import { clearJwtState, getJwtState, setJwtState } from "../../utils/jwtState";
import { LoginAPI } from "./loginAxios";
import { loginUserRequestDto, loginUserResponseDto } from "./types";

/**
 * 로그인 함수
 */
export const useLoginUser = () => {
  const navigate = useNavigate();
  const { setIsLogin } = useIsLoginState();
  return useMutation<loginUserResponseDto, Error, loginUserRequestDto>({
    mutationFn: (data: loginUserRequestDto) => LoginAPI.loginUser(data),
    onSuccess: (data) => {
      /***
       * 1. setJwtState() => 전역변수 + 로컬스토리지에 저장
       * 2. 알림창 띄우기
       * 3. navigate('/organization') 페이지이동
       */
      setJwtState({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
        userId: data.userId,
        deptId: data.deptId
      });
      setIsLogin();
      navigate("/organization");
    },
    onError: (error: any) => {
      /**
       * 1. 에러 메세지 띄우기
       */
      popupCustomAlert("error", error!.response.data.error.message || "알 수 없는 오류");
    },
  });
};

/***
 * 자동 로그인 함수
 * 1. localStorage에 값이 있는지 확인
 * 2. isLogin === true인지 확인
 * 3. true => navigate('/organization')
 * 4. false => logout()
 */
export const useAutologin = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const location = useLocation();
  const { isLogin, setIsLogin } = useIsLoginState();
  const { accessToken, refreshToken, userId } = getJwtState();
  const autoLogin = () => {
    if (accessToken && refreshToken && userId ) {
      setIsLogin();
      if (location.pathname === "/login" || location.pathname === "/") navigate("/organization");
    } else {
      navigate("/login");
      logout();
    }
  };

  return autoLogin;
};

/***
 * 로그아웃 함수
 * 1. clearJwtState() + clearIsLogin() + queryClient.clear()
 * 2. navigate('/login')
 */
export const useLogout = () => {
  const { clearIsLogin } = useIsLoginState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = () => {
    clearJwtState();
    clearIsLogin();
    queryClient.clear();
    navigate("/login");
  };

  return logout;
};
