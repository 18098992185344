import { useEffect, useState } from "react";
import { customAxios } from "../../../config";
import { UserType } from "../../../types/UserType";
import { errorHandler } from "../../../utils/apiUtil";
import { MenuGetType, MenuYNType } from "../../../types/MenuType";
import styled from "styled-components";
import { Chip, Divider } from "@mui/material";

interface Props {
  data: UserType;
}

const MenuItem = styled.button`
  border: 1px solid #dcdcdc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  background-color: white;
  height: 30px;
`;

const MenuButton = styled.button`
  border: 1px solid #dcdcdc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
  /* height: 40px; */
  min-width: 100px;
  cursor: pointer;
  &:hover {
    background-color: #ecf1ff;
  }
`;

const excludeMenus: string[] = ["M013"];

const RoleToUser = ({ data }: Props) => {
  const [deptMenus, setDeptMenus] = useState<MenuGetType[]>([]);
  const [rows, setRows] = useState<MenuYNType[]>([]);

  const getRows = async () => {
    try {
      const response = await customAxios.get("/role/user", {
        params: {
          USER_ID: data.USER_ID,
          DEPT_ID: data.DEPT_ID,
        },
      });
      const menus: MenuYNType[] = response.data;
      setRows(menus);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getDeptMenu = async () => {
    try {
      const response = await customAxios.get("/menu/dept/spec", {
        params: {
          DEPT_ID: data.DEPT_ID,
        },
      });
      const menus: MenuGetType[] = response.data;

      setDeptMenus(
        menus.filter(
          (v) => excludeMenus.findIndex((e) => e === v.MENU_ID) === -1
        )
      );
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const onClickListItem = async (item: MenuYNType) => {
    try {
      await customAxios.put("/role/user", {
        USER_ID: data.USER_ID,
        MENU_ID: item.MENU_ID,
      });
      getRows();
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getDeptMenu();
  }, [data]);

  useEffect(() => {
    if (deptMenus.length > 0) {
      getRows();
    }
  }, [deptMenus]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 12, flex: 1 }}>
      <div
        style={{ display: "flex", flexDirection: "column", gap: 7, width: 250 }}
      >
        <Chip
          size={"medium"}
          variant="outlined"
          color="primary"
          label={`${data.DEPT_NM} 부서 할당 메뉴`}
          sx={{
            borderRadius: 0,
            padding: 4,
            fontWeight: "600",
            letterSpacing: 1.1,
          }}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
          {deptMenus.map((menu) => (
            <MenuItem key={menu.MENU_ID}>{menu.MENU_TITLE}</MenuItem>
          ))}
        </div>
      </div>
      <Divider orientation="vertical" variant="fullWidth" />
      <div
        style={{ display: "flex", flexDirection: "column", gap: 15, flex: 1 }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", gap: 7, flex: 1 }}
        >
          <div>
            <Chip
              size={"medium"}
              variant="outlined"
              label={`미할당 메뉴`}
              sx={{
                borderRadius: 0,
                padding: 4,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            {rows
              .filter((v) => v.MENU_YN === 0)
              .map((v) => (
                <MenuButton key={v.MENU_ID} onClick={() => onClickListItem(v)}>
                  {v.MENU_TITLE}
                </MenuButton>
              ))}
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", gap: 7, flex: 1 }}
        >
          <div>
            <Chip
              size={"medium"}
              variant="outlined"
              color="success"
              label={`부서 외 할당 메뉴`}
              sx={{
                borderRadius: 0,
                padding: 4,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            {rows
              .filter((v) => v.MENU_YN === 1)
              .map((v) => (
                <MenuButton key={v.MENU_ID} onClick={() => onClickListItem(v)}>
                  {v.MENU_TITLE}
                </MenuButton>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleToUser;
