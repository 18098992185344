import { HttpError, HttpErrorResponseType, isAxiosError } from "./../../types/AxiosType";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RoleAPI } from "./roleAxios";
import {
  createRoleRequestDto,
  createRoleResponseDto,
  deleteRoleRequestDto,
  deleteRoleResponseDto,
  getRoleRequestDto,
  getRoleResponseDto,
  getRoleToUserRequestDto,
  getRoleToUserResponseDto,
  updateRoleRequestDto,
  updateRoleResponseDto,
  updateRoleToUserRequestDto,
  updateRoleToUserResponseDto,
} from "./types";

const QUERY_KEY = {
  code: "Role",
  userList: "userList",
};

export const useGetRole = (data: getRoleRequestDto | undefined) => {
  return useQuery<getRoleResponseDto[], Error, getRoleResponseDto[]>({
    queryKey: [QUERY_KEY.code],
    queryFn: () => RoleAPI.getRole(data),
  });
};

export const useCreateRole = (onSubmit: () => void, onError: (message: string) => void) => {
  return useMutation<createRoleResponseDto, Error, createRoleRequestDto>({
    mutationFn: (data: createRoleRequestDto) => RoleAPI.createRole(data),
    onSuccess: (data) => {
      onSubmit();
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        onError(error.response?.data.error.message || "");
      } else {
        onError("알 수 없는 오류");
      }
    },
  });
};

export const useUpdateRole = (onSubmit: () => void, onError: (message: string) => void) => {
  return useMutation<updateRoleResponseDto, Error, updateRoleRequestDto>({
    mutationFn: (data: updateRoleRequestDto) => RoleAPI.updateRole(data),
    onSuccess: (data) => {
      onSubmit();
    },
  });
};

export const useDeleteRole = (onSucess: () => void) => {
  return useMutation<deleteRoleResponseDto, Error, deleteRoleRequestDto>({
    mutationFn: (data: deleteRoleRequestDto) => RoleAPI.deleteRole(data),
    onSuccess: (data) => {
      onSucess();
    },
    onError: (error: Error) => {
      if (isAxiosError<HttpErrorResponseType>(error)) {
        //console.log(error.response?.data.error.message);
      } else {
        // onError("알 수 없는 오류");
      }
    },
  });
};

export const useGetRoleToUser = (onSubmit: (data: getRoleToUserResponseDto[]) => void) => {
  return useMutation<getRoleToUserResponseDto[], Error, getRoleToUserRequestDto>({
    mutationFn: (data: getRoleToUserRequestDto) => RoleAPI.getRoleToUser(data),
    onSuccess: (data) => {
      onSubmit(data);
    },
  });
};

export const useUpdateRoleToUser = (onSubmit: (data: updateRoleToUserResponseDto) => void) => {
  const queryClient = useQueryClient();
  return useMutation<updateRoleToUserResponseDto, Error, updateRoleToUserRequestDto>({
    mutationFn: (data: updateRoleToUserRequestDto) => RoleAPI.updateRoleToUser(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.userList] }); // 캐시된 데이터 무효화
      onSubmit(data);
    },
  });
};
