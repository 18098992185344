import { customAxios } from "../../config";
import { camelToSnake } from "../lib/camelToSnake";
import {
  createCustomerRequestDto,
  createCustomerResponseDto,
  deleteCustomerDto,
  getCustomerNameFinderDto,
  getManagementCustomerCountListDto,
  getSalesListBySalespersonIdRequestDto,
  getSalesListBySalespersonIdResponseDto,
  updateCustomerAdEndDateDto,
  updateCustomerAdStartDateDto,
  updateCustomerNotesDto,
  updateCustomerRequestDto,
  updateCustomerResponseDto,
  updateCustomerStateDto,
  updateCustomerTransferDateDto,
  updateSalesPersonIdByCustomerIdDto,
} from "./types";

export const CustomerAPI = {
  createCustomer: async (data: createCustomerRequestDto): Promise<createCustomerResponseDto> => {
    const body = camelToSnake(data);
    return await customAxios.post("/customer", body).then((res) => res.data);
  },
  deleteCustomer: async (data: deleteCustomerDto): Promise<deleteCustomerDto> => {
    const body = camelToSnake(data);
    return await customAxios.delete("/customer", { data: body }).then(() => data);
  },
  updateCustomer: async (data: updateCustomerRequestDto): Promise<updateCustomerResponseDto> => {
    // const body = camelToSnake(data);
    const body = data;
    return await customAxios.put("/customer", body).then((res) => res.data);
  },
  updateCustomerState: async (data: updateCustomerStateDto): Promise<updateCustomerStateDto> => {
    return await customAxios.put("/customer/state", data).then(() => data);
  },

  getSalesListByUserId: async (
    data: getSalesListBySalespersonIdRequestDto
  ): Promise<getSalesListBySalespersonIdResponseDto[]> => {
    const body = camelToSnake(data);
    return await customAxios.post(`/${data.type}/customer`, body).then((res) => res.data);
  },

  updateCustomerAdStartDate: async (data: updateCustomerAdStartDateDto) => {
    return await customAxios.put("/customer/ad/start", data).then(() => data);
  },
  updateCustomerAdEndDate: async (data: updateCustomerAdEndDateDto) => {
    return await customAxios.put("/customer/ad/end", data).then(() => data);
  },
  getManagementCustomerCountList: async (): Promise<getManagementCustomerCountListDto[]> => {
    return await customAxios.get("/customer/manage/count").then((res) => res.data);
  },

  updateCustomerTransferDate: async (date: updateCustomerTransferDateDto): Promise<updateCustomerTransferDateDto> => {
    return await customAxios.put("/customer/transfer/date", date).then(() => date);
  },

  updateCustomerNotes: async (body: updateCustomerNotesDto) => {
    return await customAxios.put("/customer/notes", body).then(() => body);
  },
  getCustomerNameFinder: async (body: getCustomerNameFinderDto): Promise<getCustomerNameFinderDto[]> => {
    return await customAxios.post("/customer/find/name", body).then((res) => res.data);
  },
  updateSalesPersonIdByCustomerId: async (
    body: updateSalesPersonIdByCustomerIdDto
  ): Promise<updateSalesPersonIdByCustomerIdDto> => {
    return await customAxios.put("/customer/sales/id", body).then((res) => res.data);
  },
};
