import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { OPTION_CODE } from "../../../../../constants/constant";
import { useGetDetailCode } from "../../../../../service/code/codeHooks";
import { createUserDto } from "../../../../../service/user/types";
import {
  useCreateUser,
  useGetUserList,
} from "../../../../../service/user/userHooks";
import { isEmpty } from "../../../../../utils/isEmpty";
import SelectField from "../../../../shared/input-field/SelectField";
import { popupCustomAlert } from "../../../CustomToast";

interface CreateUserModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (data: any) => void; // 아무의미없음
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  handleClose,
  handleSubmit,
}) => {
  const [step, setStep] = useState(0);
  const { data: deptOption } = useGetDetailCode(OPTION_CODE.부서);
  const { mutateAsync: createUser } = useCreateUser();
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);
  const [userInfo, setUserInfo] = useState<createUserDto>({
    userName: "",
    userId: "",
    userPassword: "",
    userCode: "",
    deptId: "",
    position: "",
    phoneNumber: "",
    email: "",
    googleEmail: "",
  });

  const { data: userList } = useGetUserList({ userId: userInfo.userId });

  //다음으로 이동
  const handleNextStep = () => {
    setStep(step + 1);
  };

  //이전으로 이동
  const handlePrevStep = () => {
    setStep(step - 1);
  };

  //유저정보 입력
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setUserInfo({
      ...userInfo,
      [id]: value.trim(),
    });
  };

  //권한 선택
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const isFullUserInfo = () => {
    let flag = true;

    if (userInfo.userName === "") flag = false;
    if (userInfo.userId === "") flag = false;
    if (userInfo.userCode === "") flag = false;
    if (userInfo.userPassword === "") flag = false;
    if (userInfo.phoneNumber === "") flag = false;
    if (userInfo.email === "") flag = false;
    if (confirmPassword === "") flag = false;

    return flag;
  };

  const handleUserInfoConfirm = () => {
    if (userList?.length === 1) {
      popupCustomAlert("warn", "이미 존재하는 아이디입니다.");
    } else if (userInfo.userPassword !== confirmPassword) {
      popupCustomAlert("warn", "비밀번호가 일치하지 않습니다.");
    } else if (!isFullUserInfo()) {
      popupCustomAlert("warn", "모든정보를 입력해 주세요.");
    } else {
      handleNextStep();
    }
  };

  const handleOnSubmit = async () => {
    if (isEmpty(userInfo.deptId)) {
      popupCustomAlert("warn", "부서를 선택해 주세요.");
      return;
    }

    await createUser(userInfo);
    handleNextStep();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // sx={{ "& .MuiDialog-paper": { width: "400px", height: "800px" } }}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" fontWeight="bold">
          계정 생성
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", marginBottom: "30px" }}>
          <Stepper activeStep={step} alternativeLabel>
            {["유저정보입력", "부서 선택", "완료"].map((label: string) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {step === 0 && (
          <Stack justifyContent={"space-between"} sx={{ height: "85%" }}>
            <Stack gap={3}>
              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  error={userList?.length === 1}
                  label="아이디"
                  id="userId"
                  placeholder="아이디를 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.userId}
                  helperText={
                    userList?.length === 1
                      ? "동일한 ID가 존재합니다 (조직도에 없는 경우, 시스템 담당자 문의)"
                      : ""
                  }
                  fullWidth
                />
                <TextField
                  label="이름"
                  id="userName"
                  placeholder="이름을 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.userName}
                  fullWidth
                />
              </Stack>
              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  label="비밀번호"
                  id="userPassword"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                  placeholder="비밀번호를 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.userPassword}
                  fullWidth
                />
                <TextField
                  error={
                    confirmPassword !== "" &&
                    userInfo.userPassword !== confirmPassword
                  }
                  label="비밀번호 확인"
                  id="userPassword_check"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                  placeholder="비밀번호를 입력해주세요."
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  fullWidth
                />
              </Stack>

              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  label="별칭"
                  id="userCode"
                  placeholder="별칭을 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.userCode}
                  fullWidth
                />
                <TextField
                  label="연락처"
                  id="phoneNumber"
                  placeholder="ex) 010-1234-5678"
                  onChange={handleInputChange}
                  value={userInfo.phoneNumber}
                  fullWidth
                />
              </Stack>

              <Stack flexDirection={"row"} gap={2}>
                <TextField
                  label="네이버 이메일"
                  id="email"
                  placeholder="네이버 이메일을 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.email}
                  fullWidth
                />
                <TextField
                  label="구글 이메일"
                  id="googleEmail"
                  placeholder="구글 이메일을 입력해주세요."
                  onChange={handleInputChange}
                  value={userInfo.googleEmail}
                  fullWidth
                />
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={1} mt={3}>
              <Button onClick={handleClose} variant="outlined" fullWidth>
                닫기
              </Button>
              <Button
                onClick={handleUserInfoConfirm}
                autoFocus
                variant="contained"
                fullWidth
              >
                다음
              </Button>
            </Stack>
          </Stack>
        )}
        {step === 1 && (
          <>
            <Stack justifyContent={"space-between"} sx={{ height: "85%" }}>
              <Stack gap={3}>
                <SelectField
                  options={deptOption?.filter(
                    (v) => v.CD_DTL_ID !== "C007Z" && v.CD_DTL_ID !== "C007Y"
                  )}
                  label="부서"
                  id="deptId"
                  value={userInfo.deptId}
                  handleChange={handleSelectChange}
                />
              </Stack>
              <Stack direction={"row"} gap={1} mt={3}>
                <Button
                  onClick={() => {
                    // 유저정보 삭제
                    handlePrevStep();
                  }}
                  variant="outlined"
                  fullWidth
                >
                  뒤로
                </Button>
                <Button
                  onClick={handleOnSubmit}
                  autoFocus
                  variant="contained"
                  fullWidth
                >
                  등록
                </Button>
              </Stack>
            </Stack>
          </>
        )}
        {step === 2 && (
          <Stack
            justifyContent={"space-between"}
            sx={{ height: "85%" }}
            alignItems={"center"}
          >
            <Stack
              alignItems="center"
              gap={4}
              sx={{ height: "100%" }}
              justifyContent={"center"}
            >
              <TaskAltIcon sx={{ fontSize: "40px" }} color="primary" />
              <Typography variant="h5" fontWeight="bold">
                등록 완료
              </Typography>
            </Stack>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              확인
            </Button>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
