import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo } from "react";

interface SelectFieldProps {
  id?: string;
  value: string;
  label?: string;
  size?: "small" | "medium" | undefined;
  variant?: "filled" | "outlined" | "standard" | undefined;
  sx?: any;
  fullWidth?: boolean;
  handleChange: (event: SelectChangeEvent) => void | any;
}

const SelectMonthField: React.FC<SelectFieldProps> = ({
  id = "",
  label = "",
  value,
  handleChange,
  variant = "outlined",
  size = "medium",
  fullWidth = true,
  sx = {},
}) => {
  const options = useMemo(() => {
    return getMonthsList().map((month) => ({
      CD_DTL_ID: month,
      CD_DTL_NM: month,
    }));
  }, []);

  return (
    <FormControl fullWidth={fullWidth} size={size} sx={sx}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        labelId="demo-simple-select-label"
        id={id}
        name={id}
        value={value}
        label={label || null}
        variant={variant}
        onChange={handleChange}
      >
        {!!options &&
          options.map((item, i) => (
            <MenuItem key={i} value={item.CD_DTL_ID}>
              {item.CD_DTL_NM}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectMonthField;

const getMonthsList = () => {
  const months = [];
  for (let month = 1; month <= 12; month++) {
    months.push(month.toString().padStart(2, "0"));
  }
  return months;
};
