import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectField from "../../components/shared/input-field/SelectField";
import { customAxios } from "../../config";
import { OPTION_CODE } from "../../constants/constant";
import { useGetDetailCode } from "../../service/code/codeHooks";
import { updateUserInfoDto } from "../../service/user/types";
import { errorHandler } from "../../utils/apiUtil";
import { getJwtState } from "../../utils/jwtState";
import CustomCheckbox from "../../components/common/CustomCheckbox";

interface UpdateUserModalProps {
  open: string;
  onSubmit: () => void;
  onCancel: () => void;
}

interface UserSpecType {
  DEPT_ID: string;
  USER_ID: string;
  USER_NAME: string;
  USER_CODE: string;
  PHONE_NUMBER: string;
  GOOGLE_EMAIL: string;
  EMAIL: string;
  POSITION: string;
  EMPLOYMENT_STATUS: "EMPLOYED" | "RETIRED";
  EMPLOYMENT_DATE: string;
}

const UserUpdatePopup: React.FC<UpdateUserModalProps> = ({
  open,
  onSubmit,
  onCancel,
}) => {
  const { data: deptOption } = useGetDetailCode(OPTION_CODE.부서);
  const [name, setName] = useState("");
  const { deptId } = getJwtState();
  const [userInfo, setUserInfo] = useState<updateUserInfoDto>({
    deptId: "",
    userName: "",
    userId: "",
    userCode: "",
    phoneNumber: "",
    email: "",
    position: "",
    employmentStatus: "",
    employmentDate: "",
    googleEmail:""
  });

  const getUserInfo = async (USER_ID: string) => {
    try {
      const response = await customAxios.get("/user/spec", {
        params: {
          USER_ID,
        },
      });
      const instance: UserSpecType = response.data;
      setName(instance.USER_NAME);
      setUserInfo({
        deptId: instance.DEPT_ID || "",
        userName: instance.USER_NAME || "",
        userId: instance.USER_ID || "",
        userCode: instance.USER_CODE || "",
        phoneNumber: instance.PHONE_NUMBER || "",
        email: instance.EMAIL || "",
        position: instance.POSITION || "",
        employmentStatus: instance.EMPLOYMENT_STATUS || "EMPLOYED",
        employmentDate: instance.EMPLOYMENT_DATE || "",
        googleEmail: instance.GOOGLE_EMAIL || "",
      });
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getUserInfo(open);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUserInfo({
      ...userInfo,
      [id]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setUserInfo({
      ...userInfo,
      employmentStatus: checked ? "EMPLOYED" : "RETIRED",
    });
  };

  const handleUpdateUserInfo = async () => {
    try {
      const payload = {
        USER_ID: userInfo.userId,
        USER_NAME: userInfo.userName,
        USER_CODE: userInfo.userCode,
        PHONE_NUMBER: userInfo.phoneNumber,
        EMAIL: userInfo.email,
        DEPT_ID: userInfo.deptId,
        POSITION: userInfo.position,
        EMPLOYMENT_STATUS: userInfo.employmentStatus,
        EMPLOYMENT_DATE: userInfo.employmentDate,
        GOOGLE_EMAIL: userInfo.googleEmail
      };
      await customAxios.put("/user", payload);

      onSubmit();
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  return (
    <Dialog
      open={open !== ""}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" fontWeight="bold">
          [{name}] 정보 수정
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ width: "500px", mb: 4 }}
        >
          <div
            style={{
              fontWeight: "600",
              fontSize: 14,
              color: "#353535",
            }}
          >
            아래 정보를 모두 입력해 주시기 바랍니다.
          </div>
        </DialogContentText>
        <Stack gap={3} sx={{ my: 7 }}>
          <SelectField
            options={
              deptId === "C007Y"
                ? deptOption?.filter((v) => v.CD_DTL_ID !== "C007Z")
                : deptId !== "C007Y" && deptId !== "C007Z"
                ? deptOption?.filter(
                    (v) => v.CD_DTL_ID !== "C007Z" && v.CD_DTL_ID !== "C007Y"
                  )
                : deptOption
            }
            label="부서"
            id="deptId"
            value={userInfo.deptId}
            handleChange={handleSelectChange}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <TextField
              label="아이디"
              id="userId"
              placeholder="아이디를 입력해주세요."
              // onChange={handleInputChange}
              value={userInfo.userId}
              // slotProps={{
              //   input: {
              //     readOnly: true,
              //   },
              // }}
              fullWidth
            />
            <TextField
              label="이름"
              id="userName"
              placeholder="이름을 입력해주세요."
              onChange={handleInputChange}
              value={userInfo.userName}
              fullWidth
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <TextField
              label="별칭"
              id="userCode"
              placeholder="별칭을 입력해주세요."
              onChange={handleInputChange}
              value={userInfo.userCode}
              fullWidth
            />
            <TextField
              label="연락처"
              id="phoneNumber"
              placeholder="ex) 010-1234-5678"
              onChange={handleInputChange}
              value={userInfo.phoneNumber}
              fullWidth
            />
          </div>
          <TextField
            label="네이버 이메일"
            id="email"
            placeholder="네이버 이메일을 입력해주세요."
            onChange={handleInputChange}
            value={userInfo.email}
          />
          <TextField
            label="구글 이메일"
            id="googleEmail"
            placeholder="구글 이메일을 입력해주세요."
            onChange={handleInputChange}
            value={userInfo.googleEmail}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                cursor: "pointer",
              }}
            >
              <CustomCheckbox
                checked={userInfo.employmentStatus === "EMPLOYED"}
                onChange={handleCheckedChange}
                checkedBgColor={"#3187f0"}
              />
              <span
                style={{
                  fontFamily: "malgun",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                재직 여부
              </span>
            </label>
          </div>
        </Stack>
        <Stack direction={"row"} gap={1}>
          <Button onClick={onCancel} variant="outlined" fullWidth>
            취소
          </Button>
          <Button
            onClick={handleUpdateUserInfo}
            autoFocus
            variant="contained"
            fullWidth
          >
            등록
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UserUpdatePopup;
