import { create } from "zustand";
import { ComponentProps, FunctionComponent } from "react";

/**
 * 모달의 상태를 관리하는 전역 상태 관리자
 *
 * 1. 모달 컴포넌트 자체를 저장
 * 2. 모달의 열고 닫는게 아니라, 모달을 추가하고 삭제함
 * 3. 모달을 추가하면, 모달 컴포넌트를 렌더링하고, 삭제하면 모달 컴포넌트를 제거함
 * 4. 모달의 생김새 와 비즈니스 모델은 Button에서 관리함
 *
 * 장점 : 불필요하게 많이 랜더링 될 수 있는 모달을 한곳에서 관리할 수 있음
 */

type State = {
  modals: Array<{
    Component: FunctionComponent<any>;
    props: ComponentProps<FunctionComponent<any>>;
  }>;
};

type Actions = {
  setModals: (
    Component: FunctionComponent<any>,
    props: ComponentProps<FunctionComponent<any>>
  ) => void;
  closeModals: (Component: FunctionComponent<any>) => void;
};

export const useModalsStore = create<State & Actions>((set) => ({
  modals: [],
  setModals: (
    Component: FunctionComponent<any>,
    props: ComponentProps<FunctionComponent<any>>
  ) => {
    set((state) => ({
      modals: [...state.modals, { Component, props }],
    }));
  },
  closeModals: (Component: FunctionComponent<any>) => {
    set((state) => ({
      modals: state.modals.filter((modal) => modal.Component !== Component),
    }));
  }
}));