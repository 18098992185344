import { customAxios } from "../../config";
import {
  createRoleRequestDto,
  createRoleResponseDto,
  deleteRoleRequestDto,
  deleteRoleResponseDto,
  getRoleRequestDto,
  getRoleResponseDto,
  getRoleToUserRequestDto,
  getRoleToUserResponseDto,
  updateRoleRequestDto,
  updateRoleResponseDto,
  updateRoleToUserRequestDto,
  updateRoleToUserResponseDto,
} from "./types";

export const RoleAPI = {
  getRole: async (
    data: getRoleRequestDto | undefined
  ): Promise<getRoleResponseDto[]> => {    
    return await customAxios
      .get(`/role?ROLE_ID=${data}`)
      .then((res) => res.data);
  },
  getRoleToUser: async (
    data: getRoleToUserRequestDto | undefined
  ): Promise<getRoleToUserResponseDto[]> => {
    return await customAxios
      .get(`/role/user?USER_ID=${data}`)
      .then((res) => res.data);
  },
  createRole: async (
    data: createRoleRequestDto
  ): Promise<createRoleResponseDto> => {
    const body = data;
    return await customAxios.post("/role", body).then((res) => res.data);
  },
  updateRole: async (
    data: updateRoleRequestDto
  ): Promise<updateRoleResponseDto> => {
    const body = data;
    return await customAxios.put("/role", body).then((res) => res.data);
  },
  updateRoleToUser: async (
    data: updateRoleToUserRequestDto
  ): Promise<updateRoleToUserResponseDto> => {
    const body = data;
    return await customAxios.put("/role/user", body).then((res) => res.data);
  },
  deleteRole: async (
    data: deleteRoleRequestDto
  ): Promise<deleteRoleResponseDto> => {
    return await customAxios
      .delete(`/role`, {
        data,
      })
      .then((res) => res.data);
  },
};
