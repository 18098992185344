import {
  Button,
  Dialog,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import {
  TableCol1,
  TableCol2,
  TableContainer,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import {
  ContentEntStatsGetType,
  ContentRequestType,
} from "../../../types/ContentType";
import { errorHandler } from "../../../utils/apiUtil";
import { customAxios } from "../../../config";

interface ContentModalProps {
  open: ContentEntStatsGetType;
  onSubmit: () => void;
}

const ContentRequestModal: React.FC<ContentModalProps> = ({
  open,
  onSubmit,
}) => {
  const [rDate, setRDate] = useState<Dayjs | null>(null);
  const [data, setData] = useState<ContentRequestType>({
    CONTENT_STATE_CD: "C026A",
    CONTENT_COUNT: 1,
    AD_SPACE: "",
    USP: "",
    AD_COPY: "",
    MEMO: "",
  });
  const [selectedValue, setSelectedValue] = useState<string>("C025A");

  const handleSubmit = async () => {
    if (rDate === null) {
      popupCustomAlert("warn", "소재 요청 일자를 선택해주세요");
      return;
    }
    if (rDate.format("YYYYMMDD") === "Invalid Date") {
      popupCustomAlert("warn", "올바른 요청 일자를 선택해주세요");
      return;
    }

    const CONTENT_DATE = rDate.format("YYYYMMDD");

    try {
      await customAxios.post("/content/request", {
        CONTENT_DIV_CD: selectedValue,
        CONTENT_DATE,
        ENT_ID: open.ENT_ID,
        ...data,
      });

      popupCustomAlert("success", "소재 요청을 완료했습니다");
      onSubmit();
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "CONTENT_COUNT") {
      setData({
        ...data,
        [id]: Number(e.target.value.replace(/[^0-9]/g, "")),
      });
    } else {
      setData({
        ...data,
        [id]: value,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleClose = () => {
    onSubmit();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
    >
      <div
        id="modal-container"
        className="flex"
        style={{
          padding: "10px 12px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              fontFamily: "malgun",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontFamily: "malgun",
              }}
            >
              소재 요청하기
            </span>
          </div>
        </div>
        <TableContainer className="flex flex-box flex-box-column">
          <div className="flex-box">
            <TableRow className="flex">
              <TableCol1>사업자명</TableCol1>
              <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
                <OutlinedInput
                  id="P_NAME"
                  size="small"
                  value={open.ENT_NM}
                  fullWidth
                  readOnly
                />
              </TableCol2>
            </TableRow>
            <TableRow className="flex">
              <TableCol1 className="border-left">브랜드명</TableCol1>
              <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
                <OutlinedInput
                  id="P_NAME"
                  size="small"
                  value={open.B_NAME}
                  fullWidth
                  readOnly
                />
              </TableCol2>
            </TableRow>
          </div>
          <div className="flex-box">
            <TableRow className="flex">
              <TableCol1>상품</TableCol1>
              <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
                <OutlinedInput
                  id="P_NAME"
                  size="small"
                  value={open.P_NAME}
                  fullWidth
                  readOnly
                />
              </TableCol2>
            </TableRow>
            <TableRow className="flex">
              <TableCol1 className="border-left">소재 구분</TableCol1>
              <TableCol2
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedValue} // 선택 상태를 상태 변수로 설정합니다.
                  onChange={handleChange} // 상태를 업데이트할 함수입니다.
                  row
                >
                  <FormControlLabel
                    value="C025A"
                    control={<Radio />}
                    label="이미지"
                  />
                  <FormControlLabel
                    value="C025B"
                    disabled={open.P_NAME === "이미지"}
                    control={<Radio />}
                    label="영상"
                  />
                </RadioGroup>
              </TableCol2>
            </TableRow>
          </div>

          <div className="flex-box">
            <TableRow className="flex">
              <TableCol1>소재 요청 일자</TableCol1>
              <TableCol2>
                <DatePicker
                  format="YYYY-MM-DD"
                  views={["month", "year", "day"]}
                  value={rDate}
                  onChange={(newValue) => {
                    setRDate(newValue);
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        fontFamily: "malgun",
                      },
                      fullWidth: true,
                    },
                  }}
                />
              </TableCol2>
            </TableRow>
            <TableRow className="flex">
              <TableCol1 className="border-left">소재 요청 개수</TableCol1>
              <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  id="CONTENT_COUNT"
                  size="small"
                  value={data.CONTENT_COUNT}
                  onChange={handleInputChange}
                  multiline
                  fullWidth
                />
              </TableCol2>
            </TableRow>
          </div>

          <TableRow>
            <TableCol1>지면</TableCol1>
            <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
              <OutlinedInput
                id="AD_SPACE"
                size="small"
                value={data.AD_SPACE}
                onChange={handleInputChange}
                multiline
                fullWidth
              />
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableCol1>소구점</TableCol1>
            <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
              <OutlinedInput
                id="USP"
                size="small"
                value={data.USP}
                onChange={handleInputChange}
                multiline
                fullWidth
              />
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableCol1>카피</TableCol1>
            <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
              <OutlinedInput
                id="AD_COPY"
                size="small"
                value={data.AD_COPY}
                onChange={handleInputChange}
                multiline
                fullWidth
              />
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableCol1>특이사항</TableCol1>
            <TableCol2 style={{ display: "flex", flexDirection: "row" }}>
              <OutlinedInput
                id="MEMO"
                size="small"
                value={data.MEMO}
                onChange={handleInputChange}
                multiline
                fullWidth
                minRows={2}
              />
            </TableCol2>
          </TableRow>
        </TableContainer>
        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ flex: 1 }}>
            요청
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ContentRequestModal;
