import EditSharpIcon from "@mui/icons-material/EditSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import UpdateIcon from "@mui/icons-material/Update";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  ColumnOrColumnGroup,
  RenderHeaderCellProps,
  RenderSummaryCellProps,
  SortColumn,
} from "react-data-grid";
import CommonButton from "../../components/common/CommonButton";
import { popupCustomAlert } from "../../components/common/CustomToast";
import DataTotalCount from "../../components/common/DataTotalCount";
import { getColumnWidth } from "../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../components/shared/input-field/SearchInputField";
import SelectMonthField from "../../components/shared/input-field/SelectMonthField";
import SelectYearField from "../../components/shared/input-field/SelectYearField";
import {
  TableCol2,
  TableRow,
} from "../../components/shared/styled-css/common-css";
import { customAxios } from "../../config";
import { ReportGetType, ReportInfoGetType } from "../../types/ReportType";
import { errorHandler } from "../../utils/apiUtil";
import {
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../utils/commonUtil";
import { excelDownLoader } from "../../utils/excelDownLoader";
import { formatDateYYYYMMDD } from "../../utils/dateUtil";

export default function SalesReportPage() {
  const WIDTH_KEY_NAME = "sales-report-list";
  const storedData = localStorage.getItem(WIDTH_KEY_NAME);
  const widths = storedData ? JSON.parse(storedData) : undefined;

  const [search, setSearch] = useState("");
  const today = new Date();
  const [year, setYear] = useState<string>(today.getFullYear().toString()); // 오늘 년도
  const [month, setMonth] = useState<string>(
    (today.getMonth() + 1).toString().padStart(2, "0")
  );

  const [rows, setRows] = useState<ReportGetType[]>([]);
  const [info, setInfo] = useState<ReportInfoGetType>();
  const [editInfo, setEditInfo] = useState<ReportInfoGetType>();

  const gridRef = useRef<HTMLDivElement>(null);
  const selectedRowId = useRef<number>();
  const selectedColumnId = useRef<number>();

  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

  const onSearch = () => {
    getInfo();
    getRows();
  };

  const getInfo = async () => {
    try {
      const response = await customAxios.get("/report/info", {
        params: {
          REPORT_DIV_CD: "sales",
          search,
          year,
          month,
        },
      });
      const data: ReportInfoGetType = response.data;
      setInfo(data);
      setEditInfo(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/report", {
        params: {
          REPORT_DIV_CD: "sales",
          search,
          year,
          month,
        },
      });
      const data: ReportGetType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleExcelExport = () => {
    if (rows.length === 0) {
      popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
      return;
    }

    const excelData = rows.map((item) => ({
      영업사원ID: item.USER_ID,
      영업사원명: item.USER_NAME,
      "합계 (총누계)": item.TOTAL_COUNT,
      GFA: item.TOTAL_GFA,
      KAKAO: item.TOTAL_KAKAO,
      당근: item.TOTAL_CARROT,
      토스: item.TOTAL_TOSS,
      GOOGLE: item.TOTAL_GOOGLE,
      META: item.TOTAL_META,
      TikTok: item.TOTAL_TIKTOK,
      검색광고: item.TOTAL_SEARCH,
      [`합계 (${year}년 ${month}월)`]: item.COUNT,
      "GFA ": item.GFA,
      "KAKAO ": item.KAKAO,
      "당근 ": item.CARROT,
      "토스 ": item.TOSS,
      "GOOGLE ": item.GOOGLE,
      "META ": item.META,
      "TikTok ": item.TIKTOK,
      "검색광고 ": item.SEARCH,
    }));
    excelDownLoader(excelData, `영업팀보고서_${year}${month}.xlsx`);
  };

  useEffect(() => {
    onSearch();
  }, [year, month]);

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnId.current = cell.column.key;
  };

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (editInfo !== undefined) {
      setEditInfo({
        ...editInfo,
        [id]: value,
      });
    }
  };

  const onClickUpdate = async () => {
    if (editInfo !== undefined) {
      if (confirm(`${year}년 ${month}월의 정보를 변경하시겠습니까?`)) {
        try {
          await customAxios.put("/report", {
            REPORT_ID: editInfo.REPORT_ID,
            TRANSFER_COUNT:
              typeof editInfo.TRANSFER_COUNT === "string" &&
              editInfo.TRANSFER_COUNT === ""
                ? 0
                : editInfo.TRANSFER_COUNT,
            AVG_TRANSFER_COUNT:
              typeof editInfo.AVG_TRANSFER_COUNT === "string" &&
              editInfo.AVG_TRANSFER_COUNT === ""
                ? 0
                : editInfo.AVG_TRANSFER_COUNT,
            OBJECT_TRANSFER_PERCENT:
              typeof editInfo.OBJECT_TRANSFER_PERCENT === "string" &&
              editInfo.OBJECT_TRANSFER_PERCENT === ""
                ? 0
                : editInfo.OBJECT_TRANSFER_PERCENT,
            BLOG_NET_SALES:
              typeof editInfo.BLOG_NET_SALES === "string" &&
              editInfo.BLOG_NET_SALES === ""
                ? 0
                : editInfo.BLOG_NET_SALES,
            RM: editInfo.RM,
          });

          getInfo();
          popupCustomAlert("success", "정보를 변경했습니다");
        } catch (e) {
          errorHandler(e);
        }
      }
    }
  };

  const isEdit = () => {
    let ret = false;
    if (info !== undefined && editInfo !== undefined) {
      if (info.TRANSFER_COUNT != editInfo.TRANSFER_COUNT) {
        ret = true;
      } else if (info.AVG_TRANSFER_COUNT != editInfo.AVG_TRANSFER_COUNT) {
        ret = true;
      } else if (
        info.OBJECT_TRANSFER_PERCENT != editInfo.OBJECT_TRANSFER_PERCENT
      ) {
        ret = true;
      } else if (info.BLOG_NET_SALES != editInfo.BLOG_NET_SALES) {
        ret = true;
      } else if (info.RM !== editInfo.RM) {
        ret = true;
      }
    }
    return ret;
  };

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  //컬럼 테이블 생성
  const columns: readonly ColumnOrColumnGroup<ReportGetType, number>[] = [
    {
      key: "USER_NAME",
      name: "영업사원",
      frozen: true,
      resizable: true,
      width: getColumnWidth(widths, "ENT_NM", 120),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      renderCell({ row }: { row: ReportGetType }) {
        return row.EMPLOYMENT_STATUS === "EMPLOYED" ? (
          <div style={{ textAlign: "center" }}>{row.USER_NAME}</div>
        ) : (
          <div style={{ textAlign: "center", color: "#fc5d5d" }}>
            <span
              title={`${row.USER_NAME} (${
                row.USER_ID
              }, 퇴직일자: ${formatDateYYYYMMDD(row.EMPLOYMENT_DATE)}) `}
            >
              {row.USER_NAME}
            </span>
          </div>
        );
      },
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      name: (
        <div className="grid-cell-font">
          총 누계
          <Tooltip
            title={
              "검색 조건과 관계 없이 영업사원이 오늘까지 진행한 라이브 요청 누계입니다"
            }
            placement="top"
          >
            <InfoOutlinedIcon
              sx={{
                fontSize: "11px",
                color: "#1976d2",
                ml: 1,
              }}
            />
          </Tooltip>
        </div>
      ),
      renderGroupCell(props) {
        return <div>asADd</div>;
      },
      children: [
        {
          key: "TOTAL_COUNT",
          name: "합계",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_COUNT", 80),
          headerCellClass: "text-center grid-header-font",
          cellClass: "text-center grid-cell-font focus-cell font-weight-bold",
          summaryCellClass: "focus-cell font-weight-bold",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_COUNT || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_GFA",
          name: "GFA",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_GFA", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_GFA || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_KAKAO",
          name: "KAKAO",
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          editable: false,
          width: getColumnWidth(widths, "TOTAL_KAKAO", 80),
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_KAKAO || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_CARROT",
          name: "당근",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_CARROT", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_CARROT || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_TOSS",
          name: "토스",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_TOSS", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_TOSS || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_GOOGLE",
          name: "GOOGLE",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_GOOGLE", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_GOOGLE || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_META",
          name: "META",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_META", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_META || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_TIKTOK",
          name: "TikTok",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_TIKTOK", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_TIKTOK || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOTAL_SEARCH",
          name: "검색광고",
          resizable: true,
          width: getColumnWidth(widths, "TOTAL_SEARCH", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce(
              (acc, row) => acc + (row.TOTAL_SEARCH || 0),
              0
            );
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
      ],
    },
    {
      name: `${year}년 ${month}월`,
      children: [
        {
          key: "COUNT",
          name: "합계",
          resizable: true,
          width: getColumnWidth(widths, "COUNT", 80),
          headerCellClass: "text-center grid-header-font",
          cellClass: "text-center grid-cell-font focus-cell font-weight-bold",
          summaryCellClass: "focus-cell font-weight-bold",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.COUNT || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "GFA",
          name: "GFA",
          resizable: true,
          width: getColumnWidth(widths, "GFA", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.GFA || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "KAKAO",
          name: "KAKAO",
          resizable: true,
          width: getColumnWidth(widths, "KAKAO", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.KAKAO || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "CARROT",
          name: "당근",
          resizable: true,
          width: getColumnWidth(widths, "CARROT", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.CARROT || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TOSS",
          name: "토스",
          resizable: true,
          width: getColumnWidth(widths, "TOSS", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.TOSS || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "GOOGLE",
          name: "GOOGLE",
          resizable: true,
          width: getColumnWidth(widths, "GOOGLE", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.GOOGLE || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "META",
          name: "META",
          resizable: true,
          width: getColumnWidth(widths, "META", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.META || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "TIKTOK",
          name: "TikTok",
          resizable: true,
          width: getColumnWidth(widths, "TIKTOK", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.TIKTOK || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
        {
          key: "SEARCH",
          name: "검색광고",
          resizable: true,
          width: getColumnWidth(widths, "SEARCH", 80),
          headerCellClass: "text-center grid-header-font",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ReportGetType, any>) => {
            const name = column.name.toString();
            return (
              <div style={{ color: getMediaColor(name) }}>{column.name}</div>
            );
          },
          cellClass: "text-center grid-cell-font",
          renderSummaryCell: ({
            column,
          }: RenderSummaryCellProps<any, ReportGetType>) => {
            const sum = rows.reduce((acc, row) => acc + (row.SEARCH || 0), 0);
            return <div className="text-center grid-cell-font">{sum}</div>;
          },
        },
      ],
    },
  ];

  useEffect(() => {
    getRows();
  }, []);

  /**
   * 컴포넌트
   */

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            // alignItems={"center"}
          >
            <SelectYearField
              sx={{ backgroundColor: "white" }}
              label="년도"
              fullWidth={false}
              size="small"
              value={year}
              handleChange={(e) => {
                setYear(e.target.value);
              }}
            />
            <SelectMonthField
              label="월"
              sx={{ backgroundColor: "white" }}
              fullWidth={false}
              size="small"
              value={month}
              handleChange={(e) => {
                setMonth(e.target.value);
              }}
            />
            <SearchInputField
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              showIcon={false}
              width={200}
            />
            <CommonButton
              onClick={onSearch}
              label="검색"
              icon={<SearchIcon fontSize="small" />}
            />
            <Button
              variant="contained"
              startIcon={<UpdateIcon />}
              onClick={onClickUpdate}
              sx={{
                fontFamily: "malgun",
                height: 40,
                letterSpacing: "1px",
                display: isEdit() ? "flex" : "none",
              }}
            >
              변경
            </Button>
          </Stack>
          <Stack>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={handleExcelExport}
              sx={{ fontFamily: "malgun", height: 40 }}
            >
              엑셀 다운로드
            </Button>
          </Stack>
        </Stack>

        <Stack flex={1} mt={2}>
          {editInfo !== undefined && (
            <div
              className="flex-box flex-box-row"
              style={{ marginBottom: "5px", gap: 5, height: "200px" }}
            >
              <div className="flex flex-box flex-box-column border">
                <TableRow className="flex border-bottom">
                  <TableCol2
                    className="flex grid-cell-font"
                    style={{
                      alignItems: "center",
                      flex: 14,
                    }}
                  >
                    <div
                      className="flex-box flex-box-row"
                      style={{ justifyContent: "space-between", gap: 5 }}
                    >
                      <span className="flex">영업팀 총 이관 건 수</span>
                      <div
                        className="flex-box"
                        style={{
                          alignItems: "center",
                          display:
                            info?.TRANSFER_COUNT != editInfo.TRANSFER_COUNT
                              ? "flex"
                              : "none",
                        }}
                      >
                        <EditSharpIcon
                          style={{ fontSize: 15, color: "#246beb" }}
                        />
                      </div>
                    </div>
                  </TableCol2>
                  <TableCol2 className="flex grid-cell-font">
                    <OutlinedInput
                      id="TRANSFER_COUNT"
                      size="small"
                      type="number"
                      value={editInfo.TRANSFER_COUNT}
                      fullWidth
                      onChange={handleInputChange}
                      sx={{
                        fontFamily: "malgun",
                        fontSize: "14px",
                      }}
                      endAdornment={
                        <InputAdornment position="end">건</InputAdornment>
                      }
                    />
                  </TableCol2>
                </TableRow>
                <TableRow className="flex border-bottom">
                  <TableCol2
                    className="flex grid-cell-font"
                    style={{
                      alignItems: "center",
                      flex: 14,
                    }}
                  >
                    <div
                      className="flex-box flex-box-row"
                      style={{ justifyContent: "space-between", gap: 5 }}
                    >
                      <span className="flex">
                        영업팀 평균 이관 건 수 (7인 기준)
                      </span>
                      <div
                        className="flex-box"
                        style={{
                          alignItems: "center",
                          display:
                            info?.AVG_TRANSFER_COUNT !=
                            editInfo.AVG_TRANSFER_COUNT
                              ? "flex"
                              : "none",
                        }}
                      >
                        <EditSharpIcon
                          style={{ fontSize: 15, color: "#246beb" }}
                        />
                      </div>
                    </div>
                  </TableCol2>
                  <TableCol2 className="flex grid-cell-font">
                    <OutlinedInput
                      id="AVG_TRANSFER_COUNT"
                      size="small"
                      type="number"
                      value={editInfo.AVG_TRANSFER_COUNT}
                      fullWidth
                      onChange={handleInputChange}
                      sx={{
                        fontFamily: "malgun",
                        fontSize: "14px",
                      }}
                      endAdornment={
                        <InputAdornment position="end">건</InputAdornment>
                      }
                    />
                  </TableCol2>
                </TableRow>
                <TableRow className="flex border-bottom">
                  <TableCol2
                    className="flex grid-cell-font"
                    style={{
                      alignItems: "center",
                      flex: 14,
                    }}
                  >
                    <div
                      className="flex-box flex-box-row"
                      style={{ justifyContent: "space-between", gap: 5 }}
                    >
                      <span className="flex">
                        이관 목표 도달 상황 (1인 15건)
                      </span>
                      <div
                        className="flex-box"
                        style={{
                          alignItems: "center",
                          display:
                            info?.OBJECT_TRANSFER_PERCENT !=
                            editInfo.OBJECT_TRANSFER_PERCENT
                              ? "flex"
                              : "none",
                        }}
                      >
                        <EditSharpIcon
                          style={{ fontSize: 15, color: "#246beb" }}
                        />
                      </div>
                    </div>
                  </TableCol2>
                  <TableCol2 className="flex grid-cell-font">
                    <OutlinedInput
                      id="OBJECT_TRANSFER_PERCENT"
                      size="small"
                      type="number"
                      value={editInfo.OBJECT_TRANSFER_PERCENT}
                      fullWidth
                      onChange={handleInputChange}
                      sx={{
                        fontFamily: "malgun",
                        fontSize: "14px",
                      }}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />
                  </TableCol2>
                </TableRow>
                <TableRow className="flex" style={{ borderBottomWidth: 0 }}>
                  <TableCol2
                    className="flex grid-cell-font"
                    style={{
                      alignItems: "center",
                      flex: 14,
                    }}
                  >
                    <div
                      className="flex-box flex-box-row"
                      style={{ justifyContent: "space-between", gap: 5 }}
                    >
                      <span className="flex">불로그 순매출</span>
                      <div
                        className="flex-box"
                        style={{
                          alignItems: "center",
                          display:
                            info?.BLOG_NET_SALES != editInfo.BLOG_NET_SALES
                              ? "flex"
                              : "none",
                        }}
                      >
                        <EditSharpIcon
                          style={{ fontSize: 15, color: "#246beb" }}
                        />
                      </div>
                    </div>
                  </TableCol2>
                  <TableCol2 className="flex flex-box grid-cell-font">
                    <OutlinedInput
                      id="BLOG_NET_SALES"
                      size="small"
                      type="number"
                      value={editInfo.BLOG_NET_SALES}
                      fullWidth
                      onChange={handleInputChange}
                      sx={{
                        fontFamily: "malgun",
                        fontSize: "14px",
                      }}
                      endAdornment={
                        <InputAdornment position="end">원</InputAdornment>
                      }
                    />
                  </TableCol2>
                </TableRow>
              </div>

              <div className="flex flex-box">
                <div className="flex flex-box flex-box-column border">
                  <TableRow className="border-bottom">
                    <TableCol2
                      className="flex  text-center justify-content-center grid-cell-font"
                      style={{
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <div
                        className="flex-box flex-box-row"
                        style={{ justifyContent: "space-between", gap: 5 }}
                      >
                        <span
                          className="flex grid-cell-font"
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          특이사항
                        </span>
                        <div
                          className="flex-box"
                          style={{
                            alignItems: "center",
                            display: info?.RM !== editInfo.RM ? "flex" : "none",
                          }}
                        >
                          <EditSharpIcon
                            style={{ fontSize: 15, color: "#246beb" }}
                          />
                        </div>
                      </div>
                    </TableCol2>
                  </TableRow>
                  <div className="flex flex-box hide-scroll">
                    <OutlinedInput
                      id="RM"
                      size="small"
                      value={editInfo.RM}
                      onChange={handleInputChange}
                      multiline
                      fullWidth
                      rows={8}
                      sx={{
                        flex: 1,
                        fontFamily: "malgun",
                        fontSize: "14px",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {rows && (
            <div
              ref={gridRef}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DataGrid
                className="rdg-light"
                style={{ height: `62vh` }}
                onSelectedCellChange={handleSelectedRow}
                sortColumns={sortColumns}
                onSortColumnsChange={handleSortColumnsChange}
                columns={columns}
                rows={rows}
                bottomSummaryRows={summaryRows}
                defaultColumnOptions={{
                  resizable: true,
                  sortable: true,
                }}
                onColumnResize={(idx, width) =>
                  onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
                }
                onCopy={handleCopy}
              />
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
