import { Box, Chip, Divider, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { customAxios } from "../../../config";
import { getDetailCode } from "../../../service/common/api";
import { getMenuToRoleResponseDto } from "../../../service/menu/types";
import { useGetRole } from "../../../service/role/roleHooks";
import { CodeDetailType } from "../../../types/CodeType";
import { errorHandler } from "../../../utils/apiUtil";
import styled from "styled-components";
import { getJwtState } from "../../../utils/jwtState";

interface MenuType {
  MENU_ID: string;
  MENU_NM: string;
  MENU_TITLE: string;
  MENU_YN: number;
}

const MenuButton = styled.button`
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
`;

const MenuToRole = () => {
  const theme = useTheme();
  const { deptId } = getJwtState();
  const { data: RoleData, isLoading } = useGetRole("");
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const [depts, setDepts] = useState<CodeDetailType[]>([]);
  const [list, setList] = useState<MenuType[]>();

  const getDepartmentCode = async () => {
    try {
      const response = await getDetailCode("C007");
      const instance: CodeDetailType[] = response.data;
      let data = instance;

      if (deptId !== "C007Z") {
        data = data.filter((v) => v.CD_DTL_ID !== "C007Z");
      }

      setSelectedRoleId(data[0].CD_DTL_ID);
      setDepts(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getMenu = async () => {
    try {
      const response = await customAxios.get("/menu/dept", {
        params: {
          DEPT_ID: selectedRoleId,
        },
      });
      const data: MenuType[] = response.data;

      setList(data.filter((v) => v.MENU_ID !== "M013"));
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getDepartmentCode();
  }, []);

  useEffect(() => {
    getMenu();
  }, [selectedRoleId]);

  const onClickListItem = async (item: getMenuToRoleResponseDto) => {
    try {
      const response = await customAxios.put("/menu/dept", {
        DEPT_ID: selectedRoleId,
        MENU_ID: item.MENU_ID,
      });
      getMenu();
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div style={{ flex: 1 }}>
      <Stack
        flex={4}
        flexDirection={"row"}
        gap={2}
        sx={{
          height: "350px",
        }}
      >
        <Stack flex={1} gap={1} height={"100%"}>
          <div className="flex-box">
            <Chip
              size={"medium"}
              variant="outlined"
              color="primary"
              label={"부서"}
              sx={{
                letterSpacing: 1,
                borderRadius: 0,
                flex: 1,
              }}
            />
          </div>
          <div
            className="hide-scroll"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {depts?.map((item, index) => (
              <MenuButton
                key={index}
                style={{
                  padding: 7,
                  color:
                    selectedRoleId === item.CD_DTL_ID
                      ? theme.palette.primary.contrastText
                      : "black",
                  backgroundColor:
                    selectedRoleId === item.CD_DTL_ID ? "#474c4e" : "white",
                }}
                onClick={() => {
                  if (selectedRoleId === item.CD_DTL_ID) {
                    setSelectedRoleId("");
                  } else {
                    setSelectedRoleId(item.CD_DTL_ID);
                  }
                }}
              >
                <span>{item.CD_DTL_NM}</span>
              </MenuButton>
            ))}
          </div>
        </Stack>
        <Stack flex={2} gap={1} height={"100%"}>
          <div className="flex-box">
            <Chip
              size={"medium"}
              variant="outlined"
              label={"미권한 메뉴"}
              sx={{
                letterSpacing: 1,
                borderRadius: 0,
                flex: 1,
              }}
            />
          </div>
          <div
            className="hide-scroll"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {selectedRoleId !== "" &&
              list !== undefined &&
              list
                .filter((v) => v.MENU_YN === 0)
                .map((item, index) => (
                  <MenuButton onClick={() => onClickListItem(item)}>
                    <span>{item.MENU_TITLE}</span>
                  </MenuButton>
                ))}
          </div>
        </Stack>
        <Stack
          width={40}
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <KeyboardArrowLeftIcon />
          <KeyboardArrowRightIcon />
        </Stack>
        <Stack flex={2} gap={1} height={"100%"}>
          <div className="flex-box">
            <Chip
              size={"medium"}
              variant="outlined"
              color="success"
              label={"권한 메뉴"}
              sx={{
                letterSpacing: 1,
                borderRadius: 0,
                flex: 1,
              }}
            />
          </div>
          <div
            className="hide-scroll"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {selectedRoleId !== "" &&
              list !== undefined &&
              list
                .filter((v) => v.MENU_YN === 1)
                .map((item, index) => (
                  <MenuButton onClick={() => onClickListItem(item)}>
                    <span>{item.MENU_TITLE}</span>
                  </MenuButton>
                ))}
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default MenuToRole;
