import { Button, Stack, TextField } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useEffect, useReducer, useState } from "react";
import AddCodeModal from "../../components/admin/code/AddCodeModal";
import DetailCodeTable from "../../components/admin/code/DetailCodeTable";
import EditCodeModal from "../../components/admin/code/EditCodeModal";
import TableNoRowsOverlay from "../../components/shared/TableNoRowsOverlay";
import { customAxios } from "../../config";
import {
  updateCodeRequestDto
} from "../../service/code/types";
import { CodeType } from "../../types/CodeType";
import { errorHandler } from "../../utils/apiUtil";
import { formatDate } from "../../utils/dateUtil";
import { isEmpty } from "../../utils/isEmpty";

const Code = () => {
  const [selectedCodeId, setSelectedCodeId] = useState<string>("");
  const [updateRequestDto, setUpdateRequestDto] =
    useState<updateCodeRequestDto>();
  const [isOpenAdd, toggleIsOpenAdd] = useReducer((state) => !state, false);
  const [isOpenEdit, toggleIsOpenEdit] = useReducer((state) => !state, false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 5,
  });
  const [rows, setRows] = useState<CodeType[]>([]);
  const [search, setSearch] = useState("");

  const getRows = async () => {
    try {
      const response = await customAxios.get("/code", {
        params: {
          search,
          // color,
        },
      });
      const data: CodeType[] = response.data;
      setRows(data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const onClickGetCode = () => {
    setPaginationModel({ ...paginationModel, page: 0 });
    getRows();
  };

  const handleEdit = (updateRequestDto: updateCodeRequestDto) => {
    setUpdateRequestDto(updateRequestDto);
    toggleIsOpenEdit();
  };

  const handleRowSelection = (ids: GridRowSelectionModel) => {
    const selectedID = ids[0]; // 단일 선택인 경우 첫 번째 ID만 사용

    const selectedRowData = rows.find((row) => row.CD_ID === selectedID);
    if (selectedRowData) {
      if (selectedCodeId === selectedID) {
        setSelectedCodeId("");
      } else {
        setSelectedCodeId(selectedRowData.CD_ID);
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "CD_ID",
      headerName: "코드 ID",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "CD_NM",
      headerName: "코드 이름",
      width: 230,
    },
    {
      field: "DESCRIPTION",
      headerName: "설명",
    },
    {
      field: "CREATED_AT",
      headerName: "생성 일시",
      width: 200,
      valueGetter: (value: string) => {
        if (isEmpty(value)) {
          return "";
        }
        return formatDate(value);
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "UPDATED_AT",
      headerName: "수정 일시",
      width: 200,
      valueGetter: (value: string) => {
        if (isEmpty(value)) {
          return "";
        }
        return formatDate(value);
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleEdit(params.row)}
          style={{ marginRight: 8 }}
        >
          수정
        </Button>
      ),
    },
  ];

  return (
    <Stack width={"100%"} flex={1} gap={2}>
      <AddCodeModal
        isOpen={isOpenAdd}
        onSubmit={() => {
          onClickGetCode();
          toggleIsOpenAdd();
        }}
        onClose={toggleIsOpenAdd}
      />
      {updateRequestDto !== undefined && (
        <EditCodeModal
          isOpen={isOpenEdit}
          onSubmit={() => {
            onClickGetCode();
            toggleIsOpenEdit();
          }}
          onClose={toggleIsOpenEdit}
          updateCodeRequestDto={updateRequestDto}
        />
      )}
      <Stack display={"flex"} flexDirection={"row"} gap={1}>
        <TextField
          type="search"
          size="small"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button variant="outlined" onClick={getRows} size="medium">
          조회
        </Button>
        <Button variant="contained" onClick={toggleIsOpenAdd}>
          코드 생성
        </Button>
      </Stack>
      <Stack gap={5}>
        <Stack flex={1}>
          <DataGrid
            rows={rows}
            getRowId={(row: CodeType) => row.CD_ID}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection={false}
            onRowSelectionModelChange={handleRowSelection}
            hideFooterSelectedRowCount
            slots={{
              noRowsOverlay: TableNoRowsOverlay,
            }}
          />
        </Stack>
        <Stack flex={1} display={"block"}>
          {selectedCodeId !== undefined && selectedCodeId !== "" && (
            <DetailCodeTable CD_ID={selectedCodeId} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Code;
