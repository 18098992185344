import { ComponentProps, FunctionComponent, useCallback } from "react";
import { useModalsStore } from "../../store/modalState";

export const useModals = () => {
  const { modals, setModals, closeModals } = useModalsStore();

  const openModal = useCallback(
    <T extends FunctionComponent<any>>(Component: T, props: Omit<ComponentProps<T>, "open">) => {
      setModals(Component, { ...props, open: true });
    },
    [setModals]
  );

  const closeModal = useCallback(
    <T extends FunctionComponent<any>>(Component: T) => {
      closeModals(Component);
    },
    [closeModals]
  );

  return {
    modals,
    openModal,
    closeModal,
  };
};
