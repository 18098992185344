import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useCreateCode } from "../../../service/code/codeHooks";
import { isEmpty } from "../../../utils/isEmpty";
import Snackbar from "@mui/material/Snackbar";

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const AddCodeModal = ({ isOpen, onSubmit, onClose }: Props) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");

  const onError = (message: string) => {
    setAlertMsg(message);
    setIsAlert(true);
  };

  const { mutate: createCode } = useCreateCode(onSubmit, onError);

  useEffect(() => {
    if (isOpen === true) {
      setId("");
      setName("");
      setDesc("");
      setIsAlert(false);
    }
  }, [isOpen]);

  const onClickSave = () => {
    if (isEmpty(id)) {
      setAlertMsg("코드 ID는 필수 값입니다.");
      setIsAlert(true);
      return;
    }

    if (isEmpty(name)) {
      setAlertMsg("코드 이름은 필수 값입니다.");
      setIsAlert(true);
      return;
    }
    createCode({
      CD_ID: id.trim(),
      CD_NM: name.trim(),
      DESCRIPTION: desc.trim(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlert}
        onClose={() => setIsAlert(false)}
        key={"validationAlert"}
        autoHideDuration={5000}
      >
        <Alert onClose={() => setIsAlert(false)} severity="error" variant="filled" sx={{ width: "100%" }}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <Stack>
        <DialogTitle>
          <Typography variant="h4">코드 생성</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>코드 ID</Typography>
              </Box>
              <TextField required={true} value={id} onChange={(event) => setId(event.target.value)} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>코드 이름</Typography>
              </Box>
              <TextField required={true} value={name} onChange={(event) => setName(event.target.value)} />
            </Stack>
            <Stack display={"flex"} flexDirection={"row"}>
              <Box width={100} alignContent={"center"}>
                <Typography textAlign={"center"}>설명</Typography>
              </Box>
              <TextField required={true} value={desc} onChange={(event) => setDesc(event.target.value)} />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={onClickSave}>
            저장
          </Button>
          <Button variant="contained" onClick={onClose} autoFocus>
            취소
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
export default AddCodeModal;
