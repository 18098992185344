import { Alert, SelectChangeEvent } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import {
  CallButton,
  TableCol1,
  TableCol2,
  TableRow,
} from "../../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../../config";
import { ContentEntStatsGetType } from "../../../../types/ContentType";
import { errorHandler } from "../../../../utils/apiUtil";
import { getCurrentKorDate } from "../../../../utils/dateUtil";
import { isEmpty } from "../../../../utils/isEmpty";
import ContentSpecGubunModal, {
  ContentGubunSpecOpenType,
} from "./ContentSpecGubunModal";
import ContentSpecModal, {
  ContentSpecOpenType,
  ContentSpecType,
} from "./ContentSpecModal";
import CustomSelectForm from "../../../../components/common/CustomSelectForm";
import { SelectFormData } from "../../../../components/shared/input-field/SelectForm";
import { CodeDetailType } from "../../../../types/CodeType";
import { getDetailCode } from "../../../../service/common/api";
import { getJwtState } from "../../../../utils/jwtState";

interface ContentStatsItemProps {
  rows: ContentEntStatsGetType[];
  // sDate: Dayjs | null;
  // eDate: Dayjs | null;
}

interface StatsType {
  USER_ID: string;
  USER_NAME: string;
  CNT: number;
}

interface StatsGuBunType {
  CONTENT_DIV_CD: string;
  CONTENT_DIV_NM: string;
  CNT: number;
}

interface StatsGetType {
  GUBUN: StatsGuBunType[];
  MARKETER: StatsType[];
  DESIGNER: StatsType[];
  CREATOR: StatsType[];
}

const ContentStatsItem = ({ rows }: ContentStatsItemProps) => {
  const { userId } = getJwtState();
  const [data, setData] = useState<StatsGetType>();
  const [sDate, setSDate] = useState<Dayjs | null>(null);
  const [eDate, setEDate] = useState<Dayjs | null>(null);

  const [open, setOpen] = useState<ContentSpecOpenType>();
  const [openGubun, setOpenGubun] = useState<ContentGubunSpecOpenType>();

  const [cState, setCState] = useState<string>("전체");
  const [cStates, setCStates] = useState<SelectFormData[]>([]);

  const getContentStateCode = async () => {
    try {
      const response = await getDetailCode("C026");
      const data: CodeDetailType[] = response.data;

      const instance: SelectFormData[] = [];
      instance.push({
        label: "전체",
        value: "전체",
      });

      for (let i = 0; i < data.length; i++) {
        const id = data[i].CD_DTL_ID;
        const name = data[i].CD_DTL_NM;

        instance.push({
          label: name,
          value: id,
        });
      }

      setCStates(instance);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    const today = dayjs(getCurrentKorDate());
    const startOfLastMonth = today.subtract(0, "month").startOf("month");
    const endOfLastMonth = today.subtract(0, "month").endOf("month");

    setSDate(startOfLastMonth);
    setEDate(endOfLastMonth);
    getContentStateCode();
  }, []);

  const getStats = async () => {
    try {
      const selectedIds = Array.from(rows.map((v) => v.ENT_ID));

      const response = await customAxios.get("/content/stats/item", {
        params: {
          // data: selectedIds,
          CONTENT_STATE_CD: cState === "전체" ? "" : cState,
          sDate: sDate?.format("YYYYMMDD"),
          eDate: eDate?.format("YYYYMMDD"),
        },
      });
      const data: StatsGetType = response.data;
      setData(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getStats();
  }, [rows, sDate, eDate, cState]);

  const onClickGuBunButton = (row: StatsGuBunType) => {
    if (sDate !== null && eDate !== null) {
      setOpenGubun({
        CONTENT_DIV_CD: row.CONTENT_DIV_CD,
        sDate,
        eDate,
        cState,
      });
    }
  };

  const onClickButton = (type: ContentSpecType, row: StatsType) => {
    if (sDate !== null && eDate !== null) {
      setOpen({
        type,
        USER_ID: row.USER_ID,
        USER_NAME: row.USER_NAME,
        sDate,
        eDate,
        cState,
      });
    }
  };

  const handleContentStateChange = async (e: SelectChangeEvent) => {
    setCState(e.target.value);
  };

  return data !== undefined ? (
    <div
      className="flex flex-box flex-box-column"
      style={{
        maxHeight: "300px",
        gap: 5,
      }}
    >
      {open !== undefined && (
        <ContentSpecModal open={open} onSubmit={() => setOpen(undefined)} />
      )}
      {openGubun !== undefined && (
        <ContentSpecGubunModal
          open={openGubun}
          onSubmit={() => setOpenGubun(undefined)}
        />
      )}

      <div
        className="flex-box flex-box-row"
        style={{
          gap: 5,
          justifyContent: "space-between",
          // backgroundColor:"red"
        }}
      >
        <div
          style={{
            alignContent: "center",
          }}
        >
          <Alert
            variant="outlined"
            severity="info"
            icon={false}
            sx={{
              // height:40,
              padding: "0px 10px",
              borderColor: "#DCDCDC",
              borderRadius: 0,
            }}
          >
            <span
              style={{
                fontFamily: "malgun",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              소재 통계
            </span>
          </Alert>
        </div>
        <div
          className="flex-box flex-box-row"
          style={{
            gap: 5,
          }}
        >
          <CustomSelectForm
            key={"selectContentState"}
            options={cStates}
            label="소재 상태"
            fullWidth={false}
            size="small"
            value={cState}
            handleChange={handleContentStateChange}
            colors={["#000000", "#4CAF50", "#2196F3", "#fd7f18", "#a50a98"]}
            sx={{
              width: 120,
            }}
          />
          <DatePicker
            format="YYYY-MM-DD"
            label="소재 시작 일자"
            views={["month", "year", "day"]}
            value={sDate}
            onChange={(newValue) => {
              setSDate(newValue);
            }}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  fontFamily: "malgun",
                  width: 150,
                },
              },
            }}
          />
          <DatePicker
            format="YYYY-MM-DD"
            label="종료 일자"
            value={eDate}
            onChange={(newValue) => {
              setEDate(newValue);
            }}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  fontFamily: "malgun",
                  width: 150,
                },
              },
            }}
          />
        </div>
      </div>

      <div
        className="flex flex-box flex-box-row"
        style={{
          gap: 5,
        }}
      >
        <div className="flex border">
          <TableRow className="border-bottom">
            <TableCol1 className="flex border-right">상품</TableCol1>
            <TableCol1
              className="flex"
              style={{
                borderRightWidth: 0,
              }}
            >
              소재 개수
            </TableCol1>
          </TableRow>
          <div className="hide-scroll" style={{ height: "200px" }}>
            {data?.GUBUN?.map(
              (v) =>
                isEmpty(v.CONTENT_DIV_CD) === false && (
                  <TableRow
                    key={v.CONTENT_DIV_CD}
                    className="flex border-bottom"
                  >
                    <TableCol2 className="flex border-right text-center justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickGuBunButton(v)}
                      >
                        {v.CONTENT_DIV_NM}
                      </CallButton>
                    </TableCol2>
                    <TableCol2 className="flex justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickGuBunButton(v)}
                      >
                        {v.CNT}
                      </CallButton>
                    </TableCol2>
                  </TableRow>
                )
            )}
          </div>
        </div>

        <div className="flex border">
          <TableRow className="border-bottom">
            <TableCol1 className="flex border-right">마케터</TableCol1>
            <TableCol1
              className="flex"
              style={{
                borderRightWidth: 0,
              }}
            >
              소재 요청 개수
            </TableCol1>
          </TableRow>
          <div className="hide-scroll" style={{ height: "200px" }}>
            {data?.MARKETER?.map(
              (v) =>
                isEmpty(v.USER_ID) === false && (
                  <TableRow
                    key={v.USER_ID}
                    className="flex border-bottom"
                    style={{
                      backgroundColor: userId === v.USER_ID ? "#e9f4fc" : "",
                    }}
                  >
                    <TableCol2 className="flex border-right text-center justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("marketer", v)}
                      >
                        {v.USER_NAME}
                      </CallButton>
                    </TableCol2>
                    <TableCol2 className="flex justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("marketer", v)}
                      >
                        {v.CNT}
                      </CallButton>
                    </TableCol2>
                  </TableRow>
                )
            )}
          </div>
        </div>

        <div className="flex border">
          <TableRow className="border-bottom">
            <TableCol1 className="flex border-right">디자이너</TableCol1>
            <TableCol1
              className="flex"
              style={{
                borderRightWidth: 0,
              }}
            >
              소재 개수
            </TableCol1>
          </TableRow>
          <div className="hide-scroll" style={{ height: "200px" }}>
            {data?.DESIGNER?.map(
              (v) =>
                isEmpty(v.USER_ID) === false && (
                  <TableRow
                    key={v.USER_ID}
                    className="flex border-bottom"
                    style={{
                      backgroundColor: userId === v.USER_ID ? "#e9f4fc" : "",
                    }}
                  >
                    <TableCol2 className="flex border-right text-center justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("designer", v)}
                      >
                        {v.USER_NAME}
                      </CallButton>
                    </TableCol2>
                    <TableCol2 className="flex justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("designer", v)}
                      >
                        {v.CNT}
                      </CallButton>
                    </TableCol2>
                  </TableRow>
                )
            )}
          </div>
        </div>

        <div className="flex border">
          <TableRow className="border-bottom">
            <TableCol1 className="flex border-right">영상</TableCol1>
            <TableCol1
              className="flex"
              style={{
                borderRightWidth: 0,
              }}
            >
              소재 개수
            </TableCol1>
          </TableRow>
          <div className="hide-scroll" style={{ height: "200px" }}>
            {data?.CREATOR?.map(
              (v) =>
                isEmpty(v.USER_ID) === false && (
                  <TableRow
                    key={v.USER_ID}
                    className="flex border-bottom"
                    style={{
                      backgroundColor: userId === v.USER_ID ? "#e9f4fc" : "",
                    }}
                  >
                    <TableCol2 className="flex border-right text-center justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("creator", v)}
                      >
                        {v.USER_NAME}
                      </CallButton>
                    </TableCol2>
                    <TableCol2 className="flex justify-content-center grid-cell-font">
                      <CallButton
                        id="user-name-button"
                        onClick={() => onClickButton("creator", v)}
                      >
                        {v.CNT}
                      </CallButton>
                    </TableCol2>
                  </TableRow>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ContentStatsItem;
