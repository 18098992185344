import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, Stack, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../components/common/CommonButton";
import SearchInputField from "../../components/shared/input-field/SearchInputField";
import { AccountListTableHandle, AccountTabType } from "../../types/CommonType";
import { getJwtState } from "../../utils/jwtState";
import AccountInfoTable from "./AccountInfoTable";

const getInitTab = (deptId: string): AccountTabType => {
  if (
    deptId === "C007C" ||
    deptId === "C007D" ||
    deptId === "C007E" ||
    deptId === "C007F"
  ) {
    return "wonjd";
  } else if (deptId === "C007A") {
    return "management";
  } else {
    return "wonjd";
  }
};

export default function AccountInfoPage() {
  const { deptId } = getJwtState();
  const [selected, setSelected] = useState<AccountTabType>(getInitTab(deptId));
  const [search, setSearch] = useState("");
  const [openModal, setOpenModel] = useState("");
  const tableRef = useRef<AccountListTableHandle>(null);

  useEffect(() => {
    onSearch();
  }, [selected]);

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const onClickAdd = () => {
    tableRef.current?.handleCreateRow();
  };

  const onClickDelete = () => {
    tableRef.current?.handleDeleteRow();
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AccountTabType
  ) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const isAuth = (tab: AccountTabType) => {
    if (tab === "wonjd") {
      // 원정대
      if (
        deptId === "C007C" ||
        deptId === "C007D" ||
        deptId === "C007E" ||
        deptId === "C007F"
      ) {
        return true;
      }
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        return true;
      }
    } else if (tab === "agency") {
      if (
        deptId === "C007Z" ||
        deptId === "C007Y" ||
        deptId === "C007I" ||
        deptId === "C007C"
      ) {
        return true;
      }
    } else if (tab === "important") {
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        return true;
      }
    } else if (tab === "management") {
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007A") {
        return true;
      }
    }

    return false;
  };

  const isPossibleButton = () => {
    if (selected === "agency") {
      return false;
    }

    if (selected === "wonjd") {
      // 원정대 탭
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        return true;
      }
    } else if (selected === "important") {
      // 중요 탭
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007I") {
        return true;
      }
    } else if (selected === "management") {
      // 경영/관리 탭
      if (deptId === "C007Z" || deptId === "C007Y" || deptId === "C007A") {
        return true;
      }
    }

    return false;
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label={"원정대"}
                  value={"wonjd"}
                  disabled={!isAuth("wonjd")}
                />
                <Tab
                  label={"대행업체"}
                  value={"agency"}
                  disabled={!isAuth("agency")}
                />
                <Tab
                  label={"중요"}
                  value={"important"}
                  disabled={!isAuth("important")}
                />
                <Tab
                  label={"경영/관리"}
                  value={"management"}
                  disabled={!isAuth("management")}
                />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                <Stack display={"flex"} flexDirection={"row"} gap={1}>
                  <SearchInputField
                    value={search}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    placeholder=""
                    showIcon={false}
                    width={180}
                  />
                  <CommonButton
                    onClick={onSearch}
                    label="검색"
                    icon={<SearchIcon fontSize="small" />}
                  />
                  {isPossibleButton() && (
                    <>
                      <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={onClickAdd}
                        sx={{
                          fontFamily: "malgun",
                          height: 40,
                        }}
                      >
                        행 추가
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<DeleteForeverOutlinedIcon />}
                        onClick={onClickDelete}
                        sx={{
                          fontFamily: "malgun",
                          height: 40,
                        }}
                        color="warning"
                      >
                        선택 삭제
                      </Button>
                    </>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <Stack mt={2} flex={1}>
          <AccountInfoTable type={selected} search={search} ref={tableRef} />
        </Stack>
      </Stack>
    </Stack>
  );
}
